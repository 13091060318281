import React, { Component } from 'react';

const LinkCellRender = (props) => {
    const { context } = props;
    const getData=(f)=>{
        var rowNode=props.api.getRowNode(props.node.id);
        //var data= props.api.getDisplayedRowAtIndex(props.rowIndex).data;
        var data =rowNode.data;
        return data[f];
    }
    const redirectPage=()=>{
        window.localStorage.setItem('pageHeader',getData(props.fieldName));
        window.localStorage.setItem('clientOrgId',getData(props.keyId));
        window.location.href=`/pages?orgId=${getData(props.keyId)}&pagename=company&name=${getData(props.fieldName)}`;
    }
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002",       
    };
    return (
        <div>
            <div className='link-grid' onClick={()=>{redirectPage()}}>{getData(props.fieldName)}</div>
           
        </div>
    );
}
export default LinkCellRender;