import axios from 'axios'

var axiosInstance = axios.create();
// Add a request interceptor
axios.interceptors.request.use(
    config => {
        var tokenString = "";
        if (config.url.includes("geolocation-db")) {
            return config;
        }
        const token = localStorage.getItem('auth.msal');
        // var tokenString = token;
        console.log('token string' + token)

        if (token)
            config.headers['Authorisation'] = 'Bearer ' + token

        const authKey = localStorage.getItem('AuthCacheKey');
        if (authKey)
            config.headers['AuthKey'] = authKey;

        console.table(authKey)
        return config
    },
    error => {
        Promise.reject(error)
    }
)

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error?.status?.code === 401 || error?.response?.status == 401) {
        if (error?.response.config.url.toLowerCase().includes('auth/authenticate'))
            window.location.href = '/Login';

        localStorage.clear();
        window.location.href = '/Login';
        //Unauthorized
        //redirect to Login
    }
    if (error?.status?.code === 500) {
        if (error?.config.url.toLowerCase().includes('das/authenticate'))
            window.location.href = '/Login';
    } else {
        //dispatch your error in a more user friendly manner       
        //easier debugging
        console.group("Error");
        console.log(error);
        console.groupEnd();
    }
});
export default axiosInstance;