import React, { useState } from 'react';
import { AuthenticationState } from 'react-aad-msal';
import loginIcon from "../images/loginIcon.PNG";
import { loginAuthProvider } from '../util/AuthProvider';
import { Client, AuthProvider, AuthProviderCallback, Options } from "@microsoft/microsoft-graph-client";
import authService from './Shared/Services/AuthService';
import { SubscriberAgreement } from './SubscriberAgreement';
import { SecurityAndPrivacy } from './SecurityAndPrivacy';
import ccbanner from "../images/img/cc-services.png";
import microsoftSignInDarkBig from "../images/microsoftSignInDarkBig.png";
import { Button, Spinner } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import { HelpDocuments } from './HelpDocuments';

const LoginSSO = () => {
    const [isAdmin, setIsAdmin] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isTriggered, setIsTriggered] = useState(false)
    const [inputStyle, setInputStyle] = useState({
        marginTop: '10px'
    })
    const currentYear = new Date().getFullYear(); 
    const error = (err) => {
        console.log(err)
    }

    const generateAuthCacheKey = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const loginContinue = async () => {
        localStorage.removeItem('isloginclicked');
        if (isTriggered)
            return;
        setIsTriggered(true)
        setIsError(false);
        console.log('firs')
        const accessTokenRequest = {
            scopes: [
                'User.Read',
                'User.Read.All',
                'User.ReadBasic.All',
                'Group.Read.All',
                'Domain.Read.All',
                'GroupMember.Read.All',
                'Directory.Read.All',
                'Application.ReadWrite.All',
                'User.ReadWrite.All'
            ]
            //User.ReadBasic.All and GroupMember.Read.All, User.Read.All and GroupMember.Read.All, User.ReadBasic.All and Group.Read.All, User.Read.All and Group.Read.All, Directory.Read.All
        };
        let re = await loginAuthProvider.acquireTokenSilent(accessTokenRequest).then(r => {
            
            return r.accessToken;
        }).catch((reason) => {
            console.log(reason);
        });

        //let tokenString=await loginAuthProvider.getAccessToken();
        let token = re;//tokenString?.accessToken;
        localStorage.setItem('auth.msal', token);
        console.log('sec')
        try {
            const authProvider = (callback) => {
                callback(error, token);
            };
            let options = {
                authProvider,
            };
            const client = Client.init(options);
            let userDetails = await client.api("/organization").get();
            
            let domainsValue = userDetails.value[0].verifiedDomains;
            let finalDomainValue;
            for (let index = 0; index < domainsValue.length; index++) {
                finalDomainValue = finalDomainValue + ',' + domainsValue[index].name;
            }
            if (finalDomainValue.indexOf('virtualdcs.co.uk') > -1) {
                setIsAdmin(true);
                localStorage.setItem("msal.auth.role", btoa(1));
            } else {
                let g = await getGroupMembership(token);
            }
            let a = await continueLogin(finalDomainValue);
        } catch (error) {
            throw error;
        }
    }
    const getGroupMembership = async (t) => {
        //me/getMemberGroup
        const authProvider = (callback) => {
            callback(error, t);
        };
        let options = {
            authProvider,
        };
        const string = {
            securityEnabledOnly: true
        };
        const client = Client.init(options);
        let groupDetails = await client.api("/me/getMemberObjects").post(string);
        console.log(groupDetails);
        let re = await getGroupMembershipDetails(t, groupDetails.value);
        return groupDetails;
    }
    const getGroupMembershipDetails = async (t, array) => {
        //me/getMemberGroup
        const authProvider = (callback) => {
            callback(error, t);
        };
        let options = {
            authProvider,
        };
        const directoryObject = {
            ids: array,
            types: [
                'user',
                'group',
                'device'
            ]
        };
        const client = Client.init(options);
        let groupDetails = await client.api("/directoryObjects/getByIds").post(directoryObject);
     
        var _isFilteredGroups = groupDetails.value.filter(x => (x.displayName == 'CloudCoverAdmin'))
        setIsAdmin(_isFilteredGroups.length > 0);
        localStorage.setItem("msal.auth.role", btoa(isAdmin ? 1 : 0));
        return groupDetails;
    }

    const continueLogin = async (domain) => {
        var accountInfo = await loginAuthProvider.getAccountInfo();
        var email = accountInfo.account.userName;
        
        var _isAdmin = atob(localStorage.getItem('msal.auth.role'));
        var _isAdmin_res = _isAdmin == '1';
        var _request = {
            UserName: email,
            Password: domain,
            AuthType: _isAdmin_res ? 4 : 3,
            AuthCacheKey: generateAuthCacheKey()
        };
        localStorage.setItem("loggedItem", btoa(_request.UserName));
        authService().authenticate(_request, true)
            .then(response => {
                let resultData = response.data;
                if (resultData.toLowerCase() == 'error') {
                    console.log('invalid attempt to login')
                    // window.location.href='/login';
                }
                var loginData = JSON.parse(resultData);
                localStorage.setItem('AuthCacheKey', _request.AuthCacheKey);
                localStorage.setItem('OrgId', btoa(loginData.CCOrgId));
                window.location.href = `/Dashboard?orgId=${loginData.CCOrgId}`;
            }).catch((reason) => {
                console.log("catch VAC Login" + reason);
                setIsError(true);
                setIsTriggered(false)
            });
    }

    const loadingSpinner = (<>
        {isError &&
            <><span onClick={e => loginContinue()} style={{ cursor: "pointer" }}>
                <img src={microsoftSignInDarkBig} alt="Sign In" />
            </span>
                <br></br>
                {isError && <span style={{ color: 'red' }}>
                    Authentication failed, Please check with support team!.
                </span>}
            </>}
        {!isError && <Spinner color="primary" />}
    </>);

    var loginFrame = (

        <div className="input-content">
            <div style={{ maxWidth: '450px', margin: 'auto' }}>
                <div className="input-icons">
                    <div style={inputStyle} >
                        <i className="fa fa-user icon"></i>
                        <input disabled
                            className={"input-field input-vac-login"} type="text" placeholder="Company\User" id="userName" />

                    </div>
                    <div style={inputStyle} className="input-field-text tooltips">
                        <i className="fa fa-key icon"></i>
                        <input disabled
                            className={"input-field input-vac-login"} type="password" placeholder="Password" id="password" />

                    </div>
                </div>
            </div>
            <button className="btn btn-dark" style={{ cursor: "pointer" }} disabled>
                <span className="button_text">Sign In</span>
            </button>

        </div>
    );
    return (<div id="loginPannel" className="loginPannel">
        <div className="row login col-xs-12">
            <div className="col-xs-6 loginboxouter">
                <img src={ccbanner} alt="Login" />
            </div>
            <div className="col col-xs-2 intermediate">

            </div>
            <div className="col-xs-6 col-sm-6 text-center loginboxinner">
                <div className="card loginbox">
                    <div className="card-body">
                        <img src={loginIcon} alt="Login" />
                        <div
                            id="login_header"
                            className="form-group"
                        ></div>
                        {loginFrame}
                        <br />
                        <HelpDocuments message="Having trouble logging in?" />
                    </div>
                    <div className="col pb-3 ssobutton">
                        <div className='login-sso'>{loadingSpinner}
                            {(() => {
                                if (loginAuthProvider.authenticationState === AuthenticationState.Authenticated) {
                                    if (!isError && !isTriggered)
                                        loginContinue();
                                }

                            })()}
                        </div>
                        <div className="card-footer">
                            <section className="small">
                                <SubscriberAgreement />
                                |
                                <SecurityAndPrivacy />
                                <br />© Saas It {currentYear}
                            </section>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    );
};
export default LoginSSO; 