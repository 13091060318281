import React, { useState } from 'react'
import { useEffect } from 'react';
import { memo } from 'react';
import dashboardservice from '../service/dashboard.service';
import moment from "moment";
import '../Features/CustomTabs.css';
import Checkbox from '../../Shared/components/Checkbox/CheckBox';
import { CC365_ROUTE } from '../../../util/constants';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import c365 from "../../../images/img/v-c365.png";
import NoDataFound from '../../details/component/NoDataFound';
import { loginAuthProvider } from '../../../util/AuthProvider';
import detailsService from '../../details/service/details.service';
import authService from '../../Shared/Services/AuthService';

const CloudCover365 = (props) => {
    const detailsServices = detailsService();
    const [isEdit, setIsEdit] = useState(false);
    const [noData, setNoData] = useState(false);
    const [isSpaceLoaded, setIsSpaceLoaded] = useState(false)
    const [isAdmin, setIsAdmin] = useState(() => {
        let isAdminVal = localStorage.getItem("msal.auth.role");//,btoa(isAdmin?1:0));
        return atob(isAdminVal) == '1';
    })
    const [modeldata, setModeldata] = useState({
        header: '',
        body: '',
        action: ''
    });
    const [isModel, setIsModel] = useState(false);
    const [priceModel, setPriceModel] = useState({});
    const [oldData, setOldData] = useState({})
    const [formdata, setFormData] = useState({
        AppExpiry: '',
        ContractDate: '',
        ContractStartDate: '',
        CreationDate: '',
        CurrentStatus: '',
        DiskId: '',
        Managed: '',
        Name: '',
        Note: '',
        NotificationsEnabled: '',
        OrgId: '',
        OrgName: '',
        PriceModelId: '',
        RepId: '',
        RepName: '',
        ResellerId: '',
        SpaceUsedGb: '',
        TaxCode: '',
        TenantDomain: '',
        Term: '',
        TrialUntil: '',
        UsageDate: '',
        UserCount: '',
        UserUsageDate: '',
        UsercountId: ''
    });
    const [stats, setStats] = useState({
        sitesCount: 0,
        teamsCount: 0,
        usersCount: 0,
        SpaceUsedGb: 0,
        lastRunDate:null,
        isLoaded: false,
        isSiteLoaded: false,
        isTeamsLoaded: false,
        isDateLoaded:false
    })
    const [profileInfo, setProfileInfo] = useState({
        IsBackupAdmin: false,
        IsMasterAdmin: true,
        IsViewOnly: false,
        companyId: '',
        companyName: '',
        scopes: [],
        status: '',
        userName: '',
        userUid: ''
    })

    const _noData = `<div><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left;margin-bottom:14.0pt"><span lang="en-US" style="">Although Microsoft provides Cloud services, they take no responsibility for customer data. It is your customer’s responsibility to protect their business and maintain an independent backup.</span></p><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left;margin-bottom:14.0pt"><span lang="en-US" style="">Enhancing the power of world-leading Veeam 365 backup software, CloudCover 365 is a completely web-driven Microsoft 365 backup experience that can be used as both a top-level backup management portal for anyone wishing to supply managed backup services to customers, or as a complete multi-tenant solution for employees.</span></p><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left"><span lang="en-US" style="">As an Enterprise alternative to customers hosting their own Veeam 365 Backup software, the CloudCover 365 service offers a Channel-focused, market-leading portal with full Multi-factor Authentication. It protects Exchange Online Mailbox items, SharePoint sites,&nbsp;</span><a href="https://www.virtualdcs.co.uk/blog/microsoft-teams-vs-zoom-which-application-is-best-for-business" target="_blank" rel="noopener noreferrer"><span lang="en-US" style="text-decoration:underline">Teams data</span></a><span lang="en-US" style="">, Contacts, OneDrive files, Public folders, Calendars and more.</span><span lang="en-US"></span></p><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left;"><span lang="en-US">&nbsp;</span></p><br></div>`
    const _authService = authService();
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getCloudCoverData(signal);
        // getCloud365Details(signal);
        // getTeamsCount365(signal);
        // getSitesCount365(signal);
        getProfile();
        return () => {
            controller.abort();
        }
    }, [props.selectedValue])

    const handleChange = e => {
        const { name, value } = e.target;
        const val = e.target.value;
        console.log(name)
        setFormData(prevState => ({
            ...prevState,
            [name]: val
        }));
    };
    const dateFormatter = (val) => {
        //console.log(val)
        return val ? moment(val).format("MM/DD/YYYY").toString() : '';
    };
    const dateFormatterCreate = (val) => {
        //console.log(val)
        return val ? moment(val).add(2, "weeks").format("MM/DD/YYYY").toString() : '';
    };
    const getProfile = async (e) => {
        let urlParams = new URLSearchParams(window.location.search);
        let pageName = urlParams.get("pagename");
        let _serverData = await _authService.GetProfileInformation()
            .then(response => {
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        
        if (_serverData) {
            setProfileInfo(_serverData);
        }
    }


    const getCloud365Details = async (signal) => {
        var _VBOId = props.selectedValue;
        
        var _vboData = await detailsServices.getCloudCover365Details(_VBOId, signal).then(
            r => {
                console.log(r.data)
                return r.data;
            }
        ).catch((reason) => {
            console.log("Get cloud cover details" + reason);
            overlayProgress(false);
            //toast(({ closeToast }) => <div>{`No Org details available in CloudCover365 Database`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
        });
        setStats({
            sitesCount: _vboData.sitesCount,
            teamsCount: _vboData.teamsCount,
            //usersCount: _vboData.usersCount,
            isLoaded: true
        });
    }
    const getSitesCount365 = async (orgId,signal) => {
        var _VBOId = orgId;
     
        var _vboData = await detailsServices.getSiteCount365(_VBOId, signal).then(
            r => {
                return r.data;
            }
        ).catch((reason) => {
            console.log("Get cloud cover details" + reason);
            overlayProgress(false);
            //toast(({ closeToast }) => <div>{`No Org details available in CloudCover365 Database`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
        });
        setStats(prevState => ({
            ...prevState,
            ['sitesCount']: _vboData.configValue
        }));
        setStats(prevState => ({
            ...prevState,
            ['isSiteLoaded']: true
        }));
    }
    const getTeamsCount365 = async (orgId,signal) => {
        var _VBOId = orgId;
        
        var _vboData = await detailsServices.getTeamsCount365(_VBOId, signal).then(
            r => {
                
                return r.data;
            }
        ).catch((reason) => {
            console.log("Get cloud cover details" + reason);
            overlayProgress(false);
            //toast(({ closeToast }) => <div>{`No Org details available in CloudCover365 Database`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
        });
        setStats(prevState => ({
            ...prevState,
            ['teamsCount']: _vboData.configValue
        }));
        setStats(prevState => ({
            ...prevState,
            ['isTeamsLoaded']: true
        }));
    }

    const getCloudCoverData = async (signal) => {
        overlayProgress(true);
        //toast(({ closeToast }) => <div>{`hi`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
        if (props.selectedValue.length > 0) {
            var _result = await dashboardservice().getCloud365Details(props.selectedValue, signal).then(
                r => {
                    if (r === undefined) {
                        setNoData(true);
                        toast.info(`No Org details available in CloudCover365 Database`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });

                        // toast(({ closeToast }) => <div>{`No Org details available in CloudCover365 Database`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                        overlayProgress(false);
                        return;
                    }
                    console.log(r)
                    var dataL = JSON.parse(r.data);
                    setFormData(dataL);
                    // console.log(formdata);
                    return JSON.parse(r.data);
                }
            ).catch((reason) => {
                console.log("Get cloud cover details" + reason);
                overlayProgress(false);
                //toast(({ closeToast }) => <div>{`No Org details available in CloudCover365 Database`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
            });
            overlayProgress(false);
            if (_result && _result.length > 0) {
                overlayProgress(false);
                setFormData(_result[0]);
                //console.log(formdata);
                await getResellerDetails(_result[0].OrgId, _result[0].ResellerId, signal);
                await GetSpaceUsed(_result[0].OrgId, _result[0].TenantDomain, signal)
                await getTeamsCount365(_result[0].OrgId,signal);
                await getSitesCount365(_result[0].OrgId,signal);
                await GetLastRunDate(_result[0].OrgId, _result[0].TenantDomain);
            }
        }
    }
    const getResellerDetails = async (orgId, resellerId, signal) => {
        var _result = await dashboardservice().getResellerDetails(orgId, resellerId, signal).then(
            r => {
                setPriceModel(r.data.priceModels)
                return r.data;
            }
        ).catch((reason) => {
            toast.info(`No Reseller details found for the organization id`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            // toast(({ closeToast }) => <div>{`No Reseller details found for the organization id`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
            console.log("Get cloud cover details" + reason);
        });
    }

    const GetSpaceUsed = async (orgId, orgName, signal) => {
        setIsSpaceLoaded(false)
        var _result = await detailsServices.GetSpaceUsed(orgId, orgName)
            .then(r => {
                console.log('space used');
                return r.data;
            }
            ).catch((reason) => {
                console.log("GetSpaceUsed" + reason);
            });
        setIsSpaceLoaded(true)
        setStats(prevState => ({
            ...prevState,
            ['SpaceUsedGb']: _result
        }));
    }
    const GetLastRunDate = async (orgId, orgName) => {
        var _result = await detailsServices.GetLastRunDate(orgId, orgName)
            .then(r => {
                return r.data;
            }
            ).catch((reason) => {
                console.log("GetLastRunDate" + reason);
            });
            setStats(prevState => ({
                ...prevState,
                ['lastRunDate']: _result.configValue[0]
            }));
            setStats(prevState => ({
                ...prevState,
                ['isDateLoaded']: true
            }));
    }
    const redirectCC365 = (e) => {
        let orgId = formdata.OrgId;
        let orgName = formdata.TenantDomain;
        let pageName = 'Main';
        let login_hint = loginAuthProvider.getAllAccounts();
        if (login_hint && login_hint.length > 0) {
            var _loginHint = login_hint[0].userName;
            window.open(`${CC365_ROUTE}elogin?pageName=${pageName}&orgId=${orgId}&orgName=${orgName}&loginHint=${_loginHint}`, "_blank")
        } else {
            window.open(`${CC365_ROUTE}main?orgId=${orgId}&orgName=${orgName}`, "_blank")
        }
    }
    const getPricaeMode = () => {
        //console.log(priceModel)
        var res = [{
            id: 0, name: 'select'
        }]
        return priceModel != null ? priceModel : res;
    }

    const openConfirm = (a) => {
        if (isEdit) {
            return;
        }
        let modal = {
            header: '',
            body: '',
            action: a
        }
        switch (a) {
            case 'e':
                modal.header = 'Edit Organization';
                modal.body = 'Are you sure you want to edit this orgainisation?';
                break;
            case 's':
                modal.header = 'Suspend Organization';
                modal.body = 'Are you sure you want to suspend this orgainisation?';
                break;
            case 'd':
                modal.header = 'Remove Organization';
                modal.body = 'THIS WILL REMOVE THIS TENNENT, OK TO CONTINUE?';
                break;
            case 'a':
                modal.header = 'Activate Organization';
                modal.body = 'Are you sure you want to activate this orgainisation?';
                break;
            case 'm':
                modal.header = 'Manage Organization';
                modal.body = 'Only manage if you have permision to do so. Ask the Organisation first.';
                break;


            default:
                break;
        }
        setModeldata(modal);
        setIsModel(true);
    }
    const redirectModelAction = (a) => {
        setIsModel(false);
        switch (a) {
            case 'e':
                editDetails();
                break;
            case 's':
                suspendOrg(formdata.OrgId);
                break;
            case 'd':
                deleteOrg(formdata.OrgId);
                break;
            case 'a':
                activateOrg(formdata.OrgId);
                break;
            case 'm':
                redirectCC365(null);
                break
            default:
                break;
        }
    }
    const editDetails = () => {
        //setOpenConfirm(true);
        if (formdata.OrgId == undefined || formdata.OrgId == '' || formdata.OrgId == null)
            return;
        setOldData(formdata);
        setIsEdit(!isEdit)
    }

    const suspendOrg = async (orgId) => {
        if (orgId == undefined || orgId == '' || orgId == null)
            return;
        overlayProgress(true)
        var _result = await dashboardservice().updateOrgActions(orgId, 'suspend').then(
            r => {
                //setFormData(dataL[0]);
                toast.success(`Organization suspended successfully.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
                //toast(({ closeToast }) => <div>{`Organization suspended successfully.`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                setIsEdit(false);
                getCloudCoverData(null);
                return JSON.parse(r.data);
            }
        ).catch((reason) => {
            overlayProgress(false)
            if (reason.indexOf('404') > -1)
                toast.info(`Could not find organization.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            else
                toast.error(`something went wrong. Please contact support team.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            console.log("Get cloud cover details" + reason);
        });
    }
    const deleteOrg = async (orgId) => {
        if (orgId == undefined || orgId == '' || orgId == null)
            return;
        overlayProgress(true)
        var _result = await dashboardservice().updateOrgActions(orgId, 'delete').then(
            r => {
                toast.success(`Organization deleted successfully.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
                //toast(({ closeToast }) => <div>{`Organization deleted successfully.`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                setIsEdit(false);
                getCloudCoverData(null);
                //setFormData(dataL[0]);
                return JSON.parse(r.data);
            }
        ).catch((reason) => {
            overlayProgress(false)
            if (reason.indexOf('404') > -1)
                //toast(({ closeToast }) => <div>{`Could not find organization`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                toast.info(`Could not find organization.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            else
                toast.error(`something went wrong. Please contact support team.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            //toast(({ closeToast }) => <div>{`Something went wrong. Please contact support team`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
            console.log("Get cloud cover details" + reason);
        });
    }
    const activateOrg = async (orgId) => {
        if (orgId == undefined || orgId == '' || orgId == null)
            return;

        overlayProgress(true);
        var _result = await dashboardservice().updateOrgActions(orgId, 'activate').then(
            r => {
                toast.success(`Organization activated successfully.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
                //toast(({ closeToast }) => <div>{`Organization activated successfully.`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                //window.location.reload();
                setIsEdit(false);
                getCloudCoverData(null);
                //setFormData(dataL[0]);
                return JSON.parse(r.data);
            }
        ).catch((reason) => {
            overlayProgress(false)
            if (reason.indexOf('404') > -1)
                //toast(({ closeToast }) => <div>{`Could not find organization`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                toast.info(`Could not find organization.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            else
                toast.error(`something went wrong. Please contact support team.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            //toast(({ closeToast }) => <div>{`Something went wrong. Please contact support team`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });

            console.log("Get cloud cover details" + reason);
        });
    }
    const saveOrgDetails = async () => {
        var _requestObj = {
            "orgId": formdata.OrgId,
            "tenantName": formdata.TenantDomain,
            "status": formdata.CurrentStatus,
            "priceMpdelId": formdata.PriceModelId,
            "orgName": formdata.TenantDomain
        }
        overlayProgress(true);
        var _result = await dashboardservice().updateOrgDetails(formdata.OrgId, _requestObj).then(
            r => {
                if (r.data == '-1') {
                    //toast(({ closeToast }) => <div>{`something went wrong. Please contact support team.`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                    toast.error(`something went wrong. Please contact support team.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
                } else {
                    toast.success(`Organization updated successfully.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
                    //toast(({ closeToast }) => <div>{`Organization updated successfully.`} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                    setIsEdit(false);
                }
                overlayProgress(false);
                return JSON.parse(r.data);
            }
        ).catch((reason) => {
            console.log("Get cloud cover details" + reason);
        });

        getCloudCoverData(null);
    }
    const overlayProgress = (show) => {
        if (show)
            document.getElementById('main-div-form-elements').style.display = 'block';
        else
            document.getElementById('main-div-form-elements').style.display = 'none';
    }
    const handleChaneDrop = (e) => {
        var requestId = formdata;
        requestId.PriceModelId = e.target.value
        var selectedValue = priceModel.filter(x => x.id == requestId.PriceModelId)[0];
        requestId.Name = selectedValue.name;
        setFormData(requestId);
        // setFormData(requestId);
    }
    const cancel = (e) => {
        setIsEdit(false);
        setFormData(oldData);
    }
    const cancelAction = () => {
        setIsModel(false);
    }
    const ignoreNull = (v) => {
        return v ? v : '';
    }
    const ignoreNullZero = (v) => {
        return v ? v : 0;
    }
    const displayCount = (v) => {
        if (v && v != '') {
            return `${v}GB`
        } else {
            return '0GB';
        }
    }
    return (
        // fade tab-pane 
        <div>
            {!noData &&
                <div className='active show'>
                    <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
                    <div className='row col-lg-12'>
                        {!profileInfo.IsViewOnly && <><div id='manageOrgs' className={isEdit ? 'action-button btn-disable' : 'action-button'} >
                            <div onClick={e => {
                                if (!isEdit) {
                                    return openConfirm('e');
                                }
                            }}><i className='fal fa-edit fa-lg m-r-1 cursor' title='Edit Customer' ></i></div>
                            <div onClick={e => openConfirm('s')}><i className='fal fa-pause-circle fa-lg m-r-1 cursor' title='Suspend Organisation'></i></div>
                            <div onClick={e => openConfirm('a')}><i className='fal fa-play-circle fa-lg m-r-1 cursor' title='Activate Organisation'></i></div>
                            <div onClick={e => openConfirm('d')}><i className='fal fa-trash-alt fa-lg m-r-1 cursor' title='Remove Tennent'></i></div>
                            {/* <div><i className='fal fa-store-alt fa-lg m-r-1 cursor' title='Convert to reseller'></i></div> */}
                            <div onClick={e => openConfirm('m')}><i className='fal fa-tools fa-lg m-r-1 cursor' title='Manage Organisation'></i></div>
                        </div></>}
                    </div>
                    <div className='stats-containter'>
                        <div className='stats-item'>
                            <span>Users</span>
                            <div className='countsvalue'>{ignoreNullZero(formdata.UserCount)}</div>
                        </div>
                        <div className='stats-item'>
                            {!stats.isSiteLoaded && <div className="loader-chart">
                                <div className="lds-company"><div></div><div></div><div></div></div>
                            </div>}
                            {stats.isSiteLoaded && <><span>Sites</span>
                                <div className='countsvalue'>{stats.sitesCount}</div>
                            </>}
                        </div>
                        <div className='stats-item'>
                            {!stats.isTeamsLoaded && <div className="loader-chart">
                                <div className="lds-company"><div></div><div></div><div></div></div>
                            </div>}
                            {stats.isTeamsLoaded && <><span>Teams</span>
                                <div className='countsvalue'>{stats.teamsCount}</div>
                            </>}
                        </div>
                        <div className='stats-item'>
                            {!isSpaceLoaded && <div className="loader-chart">
                                <div className="lds-company"><div></div><div></div><div></div></div>
                            </div>}
                            {isSpaceLoaded && <><span>Disk Space</span>
                                <div className='countsvalue'>{displayCount(ignoreNull(stats.SpaceUsedGb))}</div>
                            </>}
                        </div>
                        <div className='stats-item'>
                            {!stats.isDateLoaded && <div className="loader-chart">
                                <div className="lds-company"><div></div><div></div><div></div></div>
                            </div>}
                            {stats.isDateLoaded && <><span>Last Run</span>
                                <div className='countsvalue'>{stats.lastRunDate}</div>
                            </>}

                        </div>
                    </div>
                    <div className='form-containter'>
                        <div className='row col-lg-12 row-form'>
                            <div className='col-lg-6 pt-2'>
                                <label id='lblService' htmlFor='txtService'>Service Started</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type="text" id='txtService' value={dateFormatterCreate(formdata.TrialUntil)} onChange={handleChange} disabled name="txtService" placeholder="" />
                            </div>
                        </div>
                        <div className='row col-lg-12 row-form'>
                            <div className='col-lg-6 pt-2'>
                                <label id='lblStatus' htmlFor='txtStatus'>Status</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type="text" id='txtStatus' name="txtStatus" value={ignoreNull(formdata.CurrentStatus)} onChange={handleChange} disabled placeholder="" />
                            </div>
                        </div>
                        <div className='row col-lg-12 row-form'>
                            <div className='col-lg-6 pt-2 row-form'>
                                <label id='lblOrgName' htmlFor='TenantDomain'>Tenant Name</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type="text" id='TenantDomain' name="TenantDomain" value={ignoreNull(formdata.TenantDomain)} onChange={handleChange} disabled={!isEdit} placeholder="" />
                            </div>
                        </div>
                        {/* <div className='row col-lg-12 row-form'>
                <div className='col-lg-6 pt-2 row-form'>
                    <label id='lblTenant' htmlFor='txtTenant'>Tenant Name</label>
                </div>
                <div className='col-lg-6'>
                    <input type="text" id='TenantDomain' name="TenantDomain" value={ignoreNull(formdata.TenantDomain)} onChange={handleChange} disabled placeholder="" />
                </div>
            </div> */}
                        <div className='row col-lg-12 row-form'>
                            <div className='col-lg-6 pt-2 '>
                                <label id='lblPriceModel' htmlFor='txtPriceModel'>Price Model</label>
                            </div>
                            <div className='col-lg-6'>
                                {!isEdit && <input type="text" id='txtPriceModel' name="txtPriceModel" value={ignoreNull(formdata.Name)} onChange={handleChange} disabled placeholder="" />}
                                {isEdit && <select onChange={e => handleChaneDrop(e)} className='input-select-cloud'>
                                    {getPricaeMode().map((r, index) => {
                                        return <option key={r.id} value={r.id}>{r.name}</option>
                                    })}
                                </select>}
                            </div>
                        </div>
                        <div className='row col-lg-12 row-form'>
                            <div className='col-lg-6 pt-2'>
                                <label id='lblTrial' htmlFor='txtTrial'>Trial End Date</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type="text" id='txtTrial' name="txtTrial" value={dateFormatter(formdata.TrialUntil)} disabled onChange={handleChange} placeholder="" />
                            </div>
                        </div>
                    </div>
                    {/* <div className='row col-lg-12 row-form'>
                <div className='col-lg-6 pt-2'>
                    <label id='lblUsers' htmlFor='txtUsers'>Users</label>
                </div>
                <div className='col-lg-6'>
                    <input type="text" id='txtUsers' name="txtUsers" value={ignoreNull(formdata.UserCount)} onChange={handleChange} disabled placeholder="" />
                </div>
            </div>
            <div className='row col-lg-12 row-form'>
                <div className='col-lg-6 pt-2'>
                    <label id='lblDisk' htmlFor='txtDisk'>Disk Space GB</label>
                </div>
                <div className='col-lg-6'>
                    <input type="text" id='txtDisk' name="txtDisk" value={ignoreNull(formdata.SpaceUsedGb)} onChange={handleChange} disabled placeholder="" />
                </div>
            </div>
            <div className='row col-lg-12 row-form'>
                <div className='col-lg-6 pt-2'>
                    <label id='lblLastRun' htmlFor='txtLastRun'>Last Run</label>
                </div>
                <div className='col-lg-6'>
                    <input type="text" id='txtLastRun' name="txtLastRun" value={dateFormatter(formdata.UsageDate)} disabled onChange={handleChange} placeholder="" />
                </div>
            </div> */}

                    <div className='row col-lg-12 row-form' style={{ justifyContent: 'center' }}>
                        <div className='col-lg-2 pt-2'>
                            <label id='lblEndUserRecovery' htmlFor='txtEndUserRecovery'>End User Recovery</label>
                        </div>
                        <div className='col-lg-2'>
                            <Checkbox label={''} checked={formdata.EndUserRecovery} disabled={!isEdit} id="chkEndUserRecovery"></Checkbox>
                        </div>
                        <div className='col-lg-2 pt-2'>
                            <label id='lblDelegated' htmlFor='txtDelegated'>Delegated Management</label>
                        </div>
                        <div className='col-lg-2'>
                            <Checkbox label={''} checked={formdata.Managed} disabled={!isEdit} id="chkDelegated"></Checkbox>
                        </div>
                    </div>
                    <div className='row col-lg-12 row-form' style={{ display: 'none' }}>
                        <div id='manageOrg' onClick={redirectCC365}>
                            <i className='fal fa-tools fa-lg'></i>
                        </div>
                    </div>
                    <div>
                        {isEdit && <div className='btn-form-style'>
                            <div style={{ paddingRight: '25px' }}>
                                <Button id='btnSubmit' className='btn btn-outline-primary' onClick={e => saveOrgDetails(e)}>Save</Button></div>
                            <div>
                                <Button className='btn btn-outline-secondary' onClick={e => cancel(e)}> Cancel</Button></div>
                        </div>}
                    </div>
                    {/* <ToastContainer limit={4} style={{ fontSize: "small", top: "80px", right: "5px" }} /> */}
                    <Modal isOpen={isModel} toggle={() => setIsModel(false)}>
                        <ModalHeader toggle={() => setIsModel(false)}>
                            {modeldata.header}
                        </ModalHeader>
                        <ModalBody>
                            {modeldata.body}
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn btn-outline-primary" onClick={() => redirectModelAction(modeldata.action)}>Ok</button>
                            <button className="btn btn-primary" onClick={() => cancelAction()}>Cancel</button>

                        </ModalFooter>
                    </Modal>
                </div>
            }
            {noData &&
                <NoDataFound header={'Who is protecting your Microsoft 365? NOT Microsoft'}
                    content={_noData}
                    imgUrl={c365} />
            }
        </div>
    );
}

export default memo(CloudCover365);