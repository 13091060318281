import React from 'react'

const ErrorPage = (props) => {
const redirect=()=>{
    window.location.href='/Login';
}
    return (
        <div>
            {redirect()}
        </div>
    )
}

export default ErrorPage;