const NoDataFound = (props) => {
const headerCss= props.headerCss??'ccb-no-data-header';
    return (<div>
       <div className="no-data-page-center">
        <span className={headerCss}>{props.header}</span>
        </div>    
        <div className='ccb-no-data'>
            <div className="elementor-widget-container ccb-first rest-container col-md-6">  
                  
                <div dangerouslySetInnerHTML={{ __html: props.content }}>
                </div>
            </div>
            <div className="no-data-img col-md-6">
                <img src={props.imgUrl} alt='cc backup' />
            </div>
        </div>
    </div>)
}
export default NoDataFound;