import React, { useEffect, useState } from "react";
import { FormGroup, Spinner, Input } from "reactstrap";
//import "../../CSS/login.css";
import detailsService from "../details/service/details.service";
import authService from "../Shared/Services/AuthService";
import ActionButtons from "./ActionButton";

const Two = (props) => {
    const [info2, setInfo2] = useState({});
    const [error, setError] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [profileInfo, setProfileInfo] = useState({
        IsBackupAdmin: false,
        IsMasterAdmin: true,
        IsViewOnly: false,
        companyId: '',
        companyName: '',
        scopes: [],
        status: '',
        userName: '',
        userUid: ''
    });

    const _authService = authService();
    const _service = detailsService();

    const onTenantIdChanged = (event) => {
        setError("");
        const targetName = event.target.name;
        const targetValue = event.target.value;
        //console.log(props);
        setInfo2((info2) => ({
            ...info2,
            [targetName]: targetValue
        }));
    };

    const onSecurityMemberChanged = (event) => {
        setError("");
        const targetName = event.target.name;
        const targetValue = event.target.value;

        setInfo2((info2) => ({
            ...info2,
            [targetName]: targetValue
        }));
    };

    const validate2 = () => {
        setInProgress(true);
        //setInfo2((info2) => ({
        //    ...info2,
        //    profile: props.user.profile,
        //    userDetails: props.user.userDetails
        //}));
        if (!info2.tenantID) {
            setError("Tenant Id is mandatory field");
            setInProgress(false);
        }
        else if (!info2.securityMember) {
            setError("Security Member is mandatory");
            setInProgress(false);
        }
        else {
            var wait = saveUserGroups();
            setError("");
        }
    };

    const completeLaterClicked = (e) => {
        setError("");
        props.nextStep();
    }

    const getProfile = async (e) => {
        let _serverData = await _authService.GetProfileInformation()
            .then(response => {
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        if (_serverData) {
            setProfileInfo(_serverData);
        }
    }

    const saveUserGroups = async () => {
        var _postData = {
            Profile: {
                MicrosoftTenantID: info2.tenantID,
                ManagementGroups: {
                    MasterAdmin: info2.securityMember,
                }
            }
        };
        var _newPost = { value: btoa(JSON.stringify(_postData)), path: '', op: props.user.profile.companyId };

        var _response = await _service.postUserData(_newPost, profileInfo.companyId).then(response => {
            setInProgress(false);
            props.nextStep();
            props.userCallback(info2);
            return response.data;
        })
            .catch((reason) => {
                setInProgress(false);
                console.log("postUserData Org" + reason);
            });
    }

    useEffect(() => {
        const controller = new AbortController();
        getProfile(props.cmpId)
        return () => {
            controller.abort();
        }
    }, [])

    return (
        <div>
            <FormGroup>
                <p>You can access CloudCover Services using your Microsoft credentials this will provide you with a number of benefits: <br /> </p>
                <ul>
                    <li>Defined your own access control.</li>
                    <li>Single Sign on SSO</li>
                    <li>Use your existing Multifactor Authentication (MFA)</li>
                    <li>Control access using your owe Azure AD security groups.</li>
                </ul>

                To enable this now please provide the following:
                <br />
                <span style={{ color: "red" }}>{error}</span>
                <table width="400px">
                    <tr>

                        <td>
                            <label>Tenant Id </label>
                            {/*<label> Tenant Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>*/}
                        </td>
                        <td>
                            <Input
                                style={{ width: "100%" }}
                                type="text"
                                name="tenantID"
                                placeholder="Enter tenant id"
                                onChange={onTenantIdChanged}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label> Security Group</label>
                        </td>
                        <td>
                            <Input
                                style={{ width: "100%" }}
                                type="text"
                                name="securityMember"
                                placeholder="Enter security group name"
                                onChange={onSecurityMemberChanged}
                            />
                        </td>
                    </tr>
                </table>
            </FormGroup>
            <div>
                <table cellSpacing="5" cellPadding="5" align="right">
                    <tbody>
                        <tr>
                            <td >
                                <button className="btn btn-outline-secondary btn-sm" onClick={completeLaterClicked}>Complete Later</button>
                            </td>
                            <td >
                                {
                                    inProgress &&
                                    <Spinner color="primary" style={{ position: "absolute", left: "45%" }} />

                                }
                                <ActionButtons {...props} nextStep={validate2} disabled={inProgress} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default Two;