import React, { Component } from "react";
import loginIcon from "../images/loginIcon.PNG";
import "bootstrap/dist/css/bootstrap.css";
import { SubscriberAgreement } from "../components/SubscriberAgreement";
import { SecurityAndPrivacy } from "../components/SecurityAndPrivacy";
import { HelpDocuments } from "../components/HelpDocuments";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authService from "./Shared/Services/AuthService";
import { Button, Spinner } from "reactstrap";
import ccbanner from "../images/img/cc-services.png";
import microsoftSignInDarkBig from "../images/microsoftSignInDarkBig.png";
import { loginAuthProvider } from "../util/AuthProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { Client, AuthProvider, AuthProviderCallback, Options } from "@microsoft/microsoft-graph-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export class Login extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const autoLoginString = urlParams.get("autoLogin");
        var emailString = urlParams.get("email");
        var autoLogin = false;
        if (autoLoginString) {
            autoLogin = false;
        }
        this.state = {
            loading: false,
            isLoginClicked: false,
            autoLogin: autoLogin,
            email: emailString,
            userName: '',
            password: '',
            errorField: {
                userName: false,
                password: false
            },
            errorMessage: {
                userName: 'User name is required',
                password: 'Password is required',
                submitError: false,
                genericMessage: 'password'
            },
            passwordType: "password",
            icon: <FontAwesomeIcon icon={faEyeSlash} />
        };
        this.authService = authService();
        // this.login = this.login.bind(this);
        // this.logout = this.logout.bind(this);
        //  this.handleUserName = this.handleUserName.bind(this);
        //  this.handlePassword = this.handlePassword.bind(this);
        // this.generateAuthCacheKey = this.generateAuthCacheKey.bind(this);
        // this.handler = this.handler.bind(this);
        //this.validationHandler = this.validationHandler.bind(this);
        this.loading = '';
        //this.continue=this.continue.bind(this);
    }

    static displayName = Login.name;

    componentDidMount() {
        var isLoginTrigger = localStorage.getItem('isloginclicked');
        if (isLoginTrigger && isLoginTrigger == '1') {
            this.setState({
                isLoginClicked: true
            })
        }
        //this.usernameField.focus();
        // this.showNotifications();
    }
    generateAuthCacheKey = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    togglePassword = () => {
        if (this.state.passwordType === "password") {
            this.setState({
                passwordType: "text",
                icon: <FontAwesomeIcon icon={faEye} />
            })
            return;
        }
        this.setState({
            passwordType: "password",
            icon: <FontAwesomeIcon icon={faEyeSlash} />
        })
    }
    async login(event) {
        if (this.state.loading) {
            event.preventDefault();
            return;
        }
        localStorage.removeItem('AuthCacheKey')
        if (this.state.errorField.userName || this.state.errorField.password || this.state.userName.length == 0 || this.state.password.length == 0) {
            event.preventDefault();
            this.usernameField.focus();
            return;
        }
        this.setState({
            loading: true
        });
        this.loading = '<div id="overlay" className="overlayOn"><div id="text">Loading...</div></div>';
        var _request = {
            UserName: this.state.userName,
            Password: this.state.password,
            AuthType: 0,
            AuthCacheKey: this.generateAuthCacheKey()
        };
        localStorage.setItem("loggedItem", btoa(_request.UserName));
        this.authService.authenticate(_request, false)
            .then(response => {
                localStorage.setItem('isVAC', btoa('1'));
                this.setState({
                    loading: false
                });
                let resultData = response.data;
                if (resultData.toLowerCase() == 'error')
                    console.log('invalid attempt to login')
                var loginData = JSON.parse(resultData);
                var agreementSigned = loginData.isAgreementSigned;
                var portalRole = loginData.PortalRole;
                var _cmpName = loginData.companyName;
                localStorage.setItem('AuthCacheKey', _request.AuthCacheKey);
                localStorage.setItem('OrgId', btoa(loginData.CCOrgId));
                localStorage.setItem('portalRole', btoa(portalRole));
                localStorage.setItem('loggedCompanyName', btoa(_cmpName));
                if (portalRole.includes('companyowner') || portalRole.includes('companyadministrator')){
                    localStorage.setItem('OrgId', btoa(loginData.companyId));
                }
                if(agreementSigned){
                    if (portalRole.includes('companyowner') || portalRole.includes('companyadministrator')){
                        window.location.href = `pages?orgId=${loginData.companyId}&pagename=company&name=${_cmpName}`;
                    }
                    else
                        window.location.href = `/Dashboard?orgId=${loginData.CCOrgId}`;
                }
                else
                    window.location.href = `/signup`;
            }).catch((reason) => {
                this.setState({
                    loading: false
                });
                this.loading = '';
                var errmsg = {
                    userName: 'User name is required',
                    password: 'Password is required',
                    submitError: true,
                    genericMessage: 'Authentication failed'
                };
                console.log("catch VAC Login" + reason);
                this.setState({
                    errorMessage: errmsg
                });
            });
    }

    error = (err) => {
        console.log(err)
    }
    loginContinueSSO = () => {
        window.location.assign('/loginSSO');
    }
    loginContinue = async () => {
        console.log('firs')
        const accessTokenRequest = {
            scopes: ["user.read"],
        };
        let re = await loginAuthProvider.acquireTokenSilent(accessTokenRequest).then(r => {
            
            return r.accessToken;
        }).catch((reason) => {
            console.log(reason);
        });

        //let tokenString=await loginAuthProvider.getAccessToken();
        let token = re;//tokenString?.accessToken;
        console.log('sec')
        try {
            const authProvider = (callback) => {
                callback(this.error, token);
            };
            let options = {
                authProvider,
            };
            const client = Client.init(options);
            let userDetails = await client.api("/organization").get();
            
            this.continue(userDetails.value[0].verifiedDomains[0]);
        } catch (error) {
            throw error;
        }
    }
    continue = async (domain) => {
        var accountInfo = await loginAuthProvider.getAccountInfo();
        console.log(accountInfo)
        console.table(accountInfo)
        var email = accountInfo.account.userName;
        var _request = {
            UserName: email,
            Password: domain.name,
            AuthType: 3,
            AuthCacheKey: this.generateAuthCacheKey()
        };
        localStorage.setItem("loggedItem", btoa(_request.UserName));
        this.authService.authenticate(_request, true)
            .then(response => {
                this.setState({
                    loading: false
                });
                let resultData = response.data;
                if (resultData.toLowerCase() == 'error')
                    console.log('invalid attempt to login')
                var loginData = JSON.parse(resultData);
                localStorage.setItem('AuthCacheKey', _request.AuthCacheKey);
                localStorage.setItem('OrgId', btoa(loginData.data.CCOrgId));
                window.location.href = `/Dashboard?orgId=${loginData.data.CCOrgId}`;
            }).catch((reason) => {
                this.setState({
                    loading: false
                });
                console.log("catch VAC Login" + reason);
            });
    }
    loginSSO = async (event) => {
        // if(loginAuthProvider.authenticationState == AuthenticationState.Authenticated){
        //     this.loginContinueSSO()
        // }else{
        loginAuthProvider.login();
        localStorage.setItem('isloginclicked', '1');
        //}

    }
    logout() {
        loginAuthProvider.logout();
    }
    handleUserName = (e) => {
        var errmsg = {
            userName: 'User name is required',
            password: 'Password is required',
            submitError: false,
            genericMessage: ''
        };
        this.setState({
            userName: e.target.value,
            errorMessage: errmsg
        });
    }
    handlePassword = (e) => {
        var errmsg = {
            userName: 'User name is required',
            password: 'Password is required',
            submitError: false,
            genericMessage: ''
        };
        this.setState({
            password: e.target.value,
            errorMessage: errmsg
        });
    }
    handler = (event) => {
        console.log(event)
        console.log('even')
        if (event.key === 'Enter')
            this.login(event);
    };
    resetPass = (e) => {
        window.location.href = '/reset';
    }
    validationHandler = (e, t) => {
        var erroFld = {
            userName: this.state.errorField.userName,
            password: this.state.errorField.password,
        }
        switch (t) {
            case 'user':
                erroFld.userName = !e.target.value.length > 0;
                break;
            case 'pass':
                erroFld.password = !e.target.value.length > 0;

                break;
            default:
                break;
        }
        this.setState({
            errorField: erroFld
        });
    }

    render() {
        const inputStyle = {
            marginTop: '10px'
        }
        let forceLogin = false;
        if (this.state.autoLogin) {
            forceLogin = false;
        }
        const loadingSpinner = <Spinner color="primary" />;
        var loginFrame = (

            <div className="input-content">
                {this.state.errorMessage.submitError && (<span style={{ color: 'red' }}>
                    {this.state.errorMessage.genericMessage}
                </span>)}

                <div style={{ maxWidth: '450px', margin: 'auto' }}>
                    <div className="input-icons">
                        <div style={inputStyle} className={this.state.errorField.userName ? 'input-field-text-error tooltips' : ''}>
                            <i className="fa fa-user icon"></i>
                            <input disabled={this.state.loading} ref={inputEl => (this.usernameField = inputEl)}
                                onBlur={(e) => this.validationHandler(e, 'user')}
                                className={!this.state.errorField.userName ? "input-field input-vac-login" : "input-field-text-error input-field input-vac-login"} type="text" placeholder="Company\User" id="userName" onChange={e => this.handleUserName(e)} value={this.state.userName} />
                            <span className="tooltiptext">
                                {this.state.errorField.userName ? this.state.errorMessage.userName : ''}
                            </span>
                        </div>
                        <div style={inputStyle} className={this.state.errorField.password ? 'input-field-text-error tooltips' : ''}>
                            <div className="parent-password"> <i className="fa fa-key icon"></i>
                                <div className="child-password">
                                    <input disabled={this.state.loading}
                                        onBlur={(e) => this.validationHandler(e, 'pass')}
                                        className={!this.state.errorField.password ? "input-field input-vac-login" : "input-field-text-error input-field  input-vac-login"} type={this.state.passwordType} placeholder="Password" id="password" onKeyPress={(e) => this.handler(e)} onChange={e => this.handlePassword(e)} value={this.state.password} />
                                    <i onClick={this.togglePassword}>{this.state.icon}</i>
                                </div>
                            </div>
                            <span className="tooltiptext">
                                {this.state.errorField.password ? this.state.errorMessage.password : ''}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col pb-3 ">
                    <Button className="btn btn-dark" block='true' onClick={e => this.login(e)} style={{ cursor: "pointer" }} disabled={this.state.loading}>
                        {
                            this.state.loading &&
                            <span>
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "black" }} ></i>
                            </span>
                        }
                        {
                            !this.state.loading &&
                            <span className="button_text">
                                Sign In
                            </span>
                        }

                        {/* <span className="button_text">Sign In <i className={this.state.loading ? "fa fa-spinner fa-spin" : ""}></i></span> */}
                    </Button>
                    <a className={this.state.loading ? "disable-link":"" } style={{ float: "right" }} onClick={e => this.resetPass(e)}>Forgot your password?</a>

                    {/*<button className="btn btn-dark" onClick={e => this.resetPass(e)} style={{ cursor: "pointer" }} disabled={this.state.loading}>*/}
                    {/*    <span className="button_text">Reset Password</span>*/}
                    {/*</button>*/}
                </div >
            </div>
        );
        var loginButton = (<div>
            {console.log(`first:${new Date().toDateString()}`)}
            <AzureAD provider={loginAuthProvider} forceLogin={false}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                    { console.log(`second:${new Date().toDateString()}`) }
                    if (authenticationState === AuthenticationState.Authenticated && this.state.isLoginClicked) {
                        // case AuthenticationState.Authenticated:
                        return (<div>
                            {this.loginContinueSSO()}</div>);
                        // case AuthenticationState.Unauthenticated:
                        //     //this.logout();
                        //     return (
                        //         <span onClick={this.loginSSO} style={{ cursor: "pointer" }}>
                        //             <img src={microsoftSignInDarkBig} alt="Sign In" />
                        //         </span>

                        //     );
                        // case AuthenticationState.InProgress:
                        //     return <div><p>Authenticating...</p><Button onClick={this.logout}>Cancel</Button></div>;
                        // default:
                        //     return (
                        //         <span onClick={this.loginSSO} style={{ cursor: "pointer" }}>
                        //             <img src={microsoftSignInDarkBig} alt="Sign In" />
                        //         </span>
                        //     );
                    }
                }}
            </AzureAD>
            <span onClick={this.loginSSO} style={{ cursor: "pointer" }}>
                <img src={microsoftSignInDarkBig} alt="Sign In" />
            </span>
        </div>);

        const LoginPageTheme = React.lazy(() =>
            import("./ThemeObjects/Login/LoginLayout")
        );
        let chosenTheme = <LoginPageTheme />;
        let themeObject = (
            <React.Suspense fallback={<></>}>{chosenTheme}</React.Suspense>
        );

        let loadingScreen = (<div>
            {loginAuthProvider.authenticationState == AuthenticationState.Authenticated && <div></div>}
        </div>)
                
        const currentYear = new Date().getFullYear(); 

        return (
            <div id="loginPannel" className="loginPannel">
                {loadingScreen}
                <div className="row login col-xs-12">
                    <div className="col-xs-6 loginboxouter">
                        <img src={ccbanner} alt="Login" />
                    </div>
                    <div className="col col-xs-2 intermediate">

                    </div>
                    <div className="col-xs-6 col-sm-6 text-center loginboxinner">
                        <div className="card loginbox">
                            <div className="card-body">
                                <img src={loginIcon} alt="Login" />
                                <div
                                    id="login_header"
                                    className="form-group"
                                ></div>
                                {loginFrame}
                                <br />
                                <HelpDocuments message="Having trouble logging in?" />
                            </div>
                            <div className="col pb-3 ssobutton">
                                {loginButton}
                                {/* <span onClick={this.loginSSO} style={{ cursor: "pointer" }}>
                                    <img src={microsoftSignInDarkBig} alt="Sign In" />
                                </span> */}
                            </div>
                            <div className="card-footer">
                                <section className="small">
                                    <SubscriberAgreement />
                                    |
                                    <SecurityAndPrivacy />
                                    <br />© Saas It { currentYear}
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer limit={4} style={{ fontSize: "small", top: "80px", right: "5px" }} />
            </div>

        );
    }
}
