import React, { Component } from "react";
import subscriberagreement from "../pdfs/subscriberagreement.pdf";
export class SubscriberAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <React.Fragment>
        <a href={subscriberagreement} target="_blank" style={{marginRight: "5px"}}>Subscriber Agreement</a>
      </React.Fragment>
    );
  }
}
