import React, { useEffect, useState } from "react";
import { FormGroup, Spinner, Input } from "reactstrap";
//import "../../CSS/login.css";
import detailsService from "../details/service/details.service";
import authService from "../Shared/Services/AuthService";
import ActionButtons from "./ActionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const One = (props) => {
    const [info1, setInfo1] = useState({});
    const [error, setError] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [inProgress, setInProgress] = useState(false);
    const [icon, setIcon] = useState(<FontAwesomeIcon icon={faEyeSlash} />);
    const validPassword = new RegExp('^(?=.*?[a-zA-Z])(?=.*?[^a-zA-Z]).{8,}$');
    //let spinner = null;
    //if (inProgress) {
    //    spinner =
    //}

    const [profileInfo, setProfileInfo] = useState({
        IsBackupAdmin: false,
        IsMasterAdmin: true,
        IsViewOnly: false,
        companyId: '',
        companyName: '',
        scopes: [],
        status: '',
        userName: '',
        userUid: ''
    });
    const [userDetails, setUserDetails] = useState({});

    const _service = detailsService();
    const _authService = authService();

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            setIcon(<FontAwesomeIcon icon={faEye} />);
            return;
        }
        setPasswordType("password");
        setIcon(<FontAwesomeIcon icon={faEyeSlash} />);
    };

    const onInputChanged = (event) => {
        setError("");
        const targetName = "password";
        const targetValue = event.target.value;

        setInfo1((info1) => ({
            ...info1,
            [targetName]: targetValue
        }));
    };

    const validate = () => {
        setInProgress(true);

        if (!info1.password) {
            setError("Password is mandatory field");
            setInProgress(false);
        } else if (!validPassword.test(info1.password)){
            setError("The password provided does not meet given the requirements");
            setInProgress(false);
        }
        else {
            setError("");
            
            updateUserPassword();
        }
    };

    const getProfile = async (e) => {
        let _serverData = await _authService.GetProfileInformation()
            .then(response => {
                var result = JSON.parse(response.data);
                //console.log(result);
                setProfileInfo(response.data);
                setInfo1((info1) => ({
                    ...info1,
                    "profile": result
                }));
                getUserDetails(result);
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        //console.log(_serverData);
        if (_serverData) {
            setTimeout(() => {
                setProfileInfo(_serverData);
            }, 100);
        }
    }

    const getUserDetails = async (e) => {
        console.log(profileInfo);
        let _data = await _service.getUserDetails(e.userUid)
            .then(response => {
                //console.log(response);
                setUserDetails(response.data);
                setInfo1((info1) => ({
                    ...info1,
                    "userDetails": response.data
                }));
                return response.data;
            })
            .catch((reason) => {
                console.log("getUserDetails" + reason);
            });
    }

    const updateUserPassword = async (e) => {
        var postData = {
            Id: userDetails.instanceUid,
            Value: info1.password,
            Path: '/credentials/password',
            Operations: 'replace',
            MethodName: `users/${userDetails.instanceUid}`
        }
        console.log(info1);
        let result = await _service.updateUserDetails(postData)
            .then(response => {
                //console.log(response);
                setInProgress(false);
                props.nextStep();
                props.userCallback(info1);
                return response.data;
            })
            .catch((reason) => {
                console.log("getUserDetails" + reason);
            });
    }

    useEffect(() => {
        const controller = new AbortController();
        var wait = getProfile(props.cmpId);
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <div>

            <FormGroup>
                <p align="center" >Welcome to CloudCover holistic approach to data protection. <br /><br />

                    To get you up and running please complete this getting started wizard. <br /><br />

                    Although your initial password was randomly generated, for your own security please update it now.
                    <br />
                </p>
                <span style={{ color: "red" }}>{error}</span>
                <div className="input-text">
                    <i onClick={togglePassword}>{icon}</i>
                    <Input
                        class="form-control"
                        type={passwordType}
                        name="password"
                        placeholder="Enter password"
                        onChange={onInputChanged}
                    />

                </div>
                <br />
                <p align="center" style={{ fontSize: "80%" }} >
                    passwords must be at least eight positions in length and contain a mix of <br />
                    alphabetic and non-alphabetic characters (numbers, punctuation or special <br />
                    characters) or a mix of at least two types of non-alphabetic characters.<br />
                </p>
            </FormGroup>

            <div>
                <table cellSpacing="5" cellPadding="5" align="right">
                    <tbody>
                        <tr>
                            <td >
                                {
                                    inProgress &&
                                    <Spinner color="primary" style={{ position: "absolute", left: "45%" }} />

                                }
                                <ActionButtons {...props} nextStep={validate} disabled={inProgress} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default One;