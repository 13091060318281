import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal } from "reactstrap";
import "../CSS/general.css";
import { API_ROUTE, LEGACY_SITE, Routes } from "../util/constants";
import transpix from '../images/transpix.png';
//import Banner65 from "../images/Banner65.jpg";


export class NavBar extends Component {
    static displayName = NavBar.name;
    // static context = AppContext;
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        this.state = {
            orgId: urlParams.get("orgId") ? urlParams.get("orgId") : "",
            orgName: urlParams.get("orgName") ? urlParams.get("orgName") : "",
            impersonating: props.impersonating,
            page: props.page,
            isAdmin: false,
            isReseller: false,
            isDirectCustomer: false,
            inAdminGroup: false,
            showHelpPages: false,
            fetchedData: false,
            licensedUsersCsv: "",
            showdropdown: false,
            accountInfoUrl: "",
            accountInfoHeader: "",
            showAccountInfo: false,
            alertPop: false,
            alertMessage: [],
        };

        this.showHelpPages = this.showHelpPages.bind(this);
        this.getLicensedUsers = this.getLicensedUsers.bind(this);
        this.protectionReport = this.protectionReport.bind(this);
        this.csvLinkEl = React.createRef();
        this.hideHelpPages = this.hideHelpPages.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.hideAccountInfoIframe = this.hideAccountInfoIframe.bind(this);
        this.cancelAlertClick = this.cancelAlertClick.bind(this);
        this.getSettings();

    }


    componentDidMount() {
    }

    async getSettings() {
       
    }
    hideAccountInfoIframe() {
        this.setState({
            showAccountInfo: false
        });
    }
    cancelAlertClick() {
        this.setState({
            alertPop: false
        });
    }

    showHelpPages(event) {
        event.preventDefault();
        this.setState({
            showHelpPages: true,
        });
    }

    async getLicensedUsers(e) {
       

    }



    hideHelpPages(event) {
        event.preventDefault();
        this.setState({
            showHelpPages: false,
        });
    }

    async protectionReport(e) {
        
    }

    render() {
        
        return (
            <React.Fragment>
              
            </React.Fragment>
        );
    }
}
