import React, { Component } from 'react';
export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isLogin: window.location.href.toLocaleLowerCase().includes('login') || window.location.href.toLocaleLowerCase().includes('reset') || window.location.href.toLocaleLowerCase().includes('signup')
    };
   
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    if(this.state.isLogin ){
      let LoginHeader = React.lazy(() =>
      import("../components/ThemeObjects/Login/LoginHeader")
    );
    let chosenTheme = <LoginHeader />;
    let themeObject = (
      <React.Suspense fallback={<></>}>{chosenTheme}</React.Suspense>
    );
    return (
      <div>
        {themeObject}
      </div>
    );
  }
    }
   
}
