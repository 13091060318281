
import React, { useState, useEffect, useRef } from "react";
import useInterval from "./UseInterval";
const Retry=(props)=>{
    const [delay, setDelay] = useState(5000);
  
    useInterval(() => {
      // Your custom logic here
      props.ProcessRetry();
    }, delay);
  
    function handleDelayChange(e) {
      setDelay(Number(e.target.value));
    }
  
    return (
      <>
      </>
    );
  }
  export default Retry;
  