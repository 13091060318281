import React, { useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import StepWizard from "react-step-wizard";
//import "../../CSS/login.css";
import One from "./Step1";
import Two from "./Step2";
import Three from "./Step3";


const Sample = () => {
    const [stepWizard, setStepWizard] = useState(null);
    const [user, setUser] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const assignUser = (val) => {
        //console.log("parent receive user callback");
        //console.log(val);
        setUser((user) => ({
            ...user,
            ...val
        }));
    };

    const handleStepChange = (e) => {
        //console.log("step change");
        //console.log(e);
        setActiveStep(e.activeStep - 1);
    };

    const handleComplete = () => {
        let _portalRole = localStorage.getItem('portalRole');
        var portalRole = null
        if (_portalRole) {
            portalRole = atob(_portalRole);
        }
        if (portalRole && (portalRole.includes('companyowner') || portalRole.includes('companyadministrator')) ) {
            let _OrgId = localStorage.getItem('OrgId');
            let _loggedCompanyName = localStorage.getItem('loggedCompanyName');
            window.location.href = `pages?orgId=${atob(_OrgId)}&pagename=company&name=${atob(_loggedCompanyName)}`;
        } else {
            window.location.href = `/Dashboard?orgId=a`;
        }
    };

    return (
        <div style={{ width: "auto", height: "auto" }} className="signupBox">
            <div className="card loginbox" >
                <Stepper className={'stepper'} stepClassName={'stepper__step'} activeStep={activeStep}>
                    <Step label="Welcome" />
                    <Step label="Advanced" />
                    <Step label="Privacy & Data Protection" />
                </Stepper>
                <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
                    <One userCallback={assignUser} />
                    <Two user={user} userCallback={assignUser} />
                    <Three user={user} completeCallback={handleComplete} />
                </StepWizard>
            </div>
        </div>
    );
};

export default Sample;
