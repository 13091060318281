import React, { Component } from 'react';

export default class CustomTooltips extends Component {
    constructor(props) {
        super(props);
        props.reactContainer.classList.add('custom-tooltip');
    }
    componentDidMount() {
        // this.loadData();
    }
  render() {
    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
   // console.log(data);
    const getData=(fieldName)=>{
return data[fieldName];
    }
    const getStatus=(f,s)=>{
      if(f==='k8s' && s==='Error')
        return data[`${f}S${s}`];
      else
        return data[`${f}${s}`];
    }
    return (
       
      <div className='custom-tooltip'>
        <p>
          <span>Success: {getStatus(this.props.groupName,'Success')}</span></p>
          <p><span>Error: {getStatus(this.props.groupName,'Error')}</span></p>
          <p><span>Warning: {getStatus(this.props.groupName,'Warning')}</span>    </p>      
          {/* <p> <span>NA: {getStatus(this.props.groupName,'NA')}</span></p>         */}
      </div>
      
    );
  }
}