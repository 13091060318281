import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
    auth: {
        //https://login.microsoftonline.com/{tenant}/oauth2/v2.0/authorize?client_id=44c6d527-a263-40ae-a9eb-f0a05b93f083&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%2Fmyapp%2F&response_mode=query&scope=https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&state=12345
        authority: "https://login.microsoftonline.com/common", //Directory (tenant) ID Overview blade of App Registration
        clientId: "44c6d527-a263-40ae-a9eb-f0a05b93f083", //Application (client) ID
        postLogoutRedirectUri: window.location.origin,
        redirectUri: `${window.location.origin}/Login`,
        validateAuthority: true,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

export const authenticationParameters = {
    scopes: [
        'User.Read',
        'User.Read.All',
        'User.ReadBasic.All',
        'Group.Read.All',
        'Domain.Read.All',
        'GroupMember.Read.All',
        'Directory.Read.All',
        'Application.ReadWrite.All',
        'User.ReadWrite.All'
    ]
}

export const authenticationParametersGraph = {
    scopes: [
        'openid'
    ]
}

export const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.origin}`,

}

export const loginAuthProvider = new MsalAuthProvider(config, authenticationParameters, options)