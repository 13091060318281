import React from 'react'
import AlarmsDetails from './AlarmsDetails';
import CloudCover365 from './CloudCover';

const DetailsElement = (props) => {
    const getCompanyName = (id, dataSet) => {
        var selItems = dataSet.filter(x => { return x[props.selectedKeyVal] === id });
        return selItems.length > 0 ? selItems[0].name : '';
    }
    const getResellerName = (id, dataSet) => {
        var selItems = dataSet.filter(x => { return x[props.selectedKeyVal] === id });
        return selItems.length > 0 ? selItems[0].name : '';
    }
   
    const generateElement = (e) => {
        switch (e) {
            case "company": return (<div><span>Company Name:</span><p>{getCompanyName(props.selectedValue, props.details)}</p></div>);
            case "reseller": return (<div><span>Reseller Name:</span><p>{getResellerName(props.selectedValue, props.details)}</p></div>);
            case "alarms": return (<AlarmsDetails details={props.details} selectedValue={props.selectedValue}></AlarmsDetails>);
            case "cloud": return (<CloudCover365 selectedValue={props.selectedValue}></CloudCover365>);
        }
    }
    return (
        <div>
            {generateElement(props.selectedKey)}
        </div>
    )
}

export default DetailsElement