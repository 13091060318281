import React, { Component, Suspense } from 'react';
import { Route, Routes  } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import AppRouter from './AppRouter';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Routes>
              {AppRoutes.map((route, index) => {
                const { element,importPath, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
              </Routes>
      </Layout>
    );
  }
}
