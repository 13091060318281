import React, { useEffect, useRef } from "react";
import Button from "../Button/Button";
import Close from "../Close/Close";
import styles from "./Modal.module.css";

const Modal = ({ modalStyle, children, show, onClose, backdropStyle }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);
  return (
    <React.Fragment>
      <div ref={modalRef} style={backdropStyle} className={`${styles.modal}`}>    
      <div style={modalStyle} className={styles.modal__wrap}>
        <div className="modal-header">
            Header       
        <span onClick={onClose} className="close-btn close">&times;</span>
        
        </div>
        <div>
          {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
