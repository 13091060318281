import { useState, useEffect } from "react";
import { Charts } from "../../Shared/components/Charts/Charts";
import detailsService from "../service/details.service";
import '../css/details.css';
import '../../../CSS/Placeholder.css';
import { Button, Spinner } from "reactstrap";
import authService from "../../Shared/Services/AuthService";
import Refresh from "../../dashboard/components/Refresh";

const PriceDetails = (props) => {
    const [resellerName, setResellerName] = useState('')
    const [isEdit, setIsEdit] = useState(false);
    const [profileInfo, setProfileInfo] = useState({
        IsBackupAdmin: false,
        IsMasterAdmin: true,
        IsViewOnly: false,
        companyId: '',
        companyName: '',
        scopes: [],
        status: '',
        userName: '',
        userUid: ''
    })
    const _service = detailsService();
    const _authService = authService();
    const [data, setData] = useState({});
    useEffect(() => {
        var _reseller = localStorage.getItem('rsName');
        setResellerName(atob(_reseller));
        getCompanyWise();
        getProfile();
        return () => {

        }
    }, [])

    const [formdata, setFormData] = useState({
        EndUserPrice: '',
        PartnerPrice: ''
    });
    const [oldFormdata, setOldFormData] = useState({
        EndUserPrice: '',
        PartnerPrice: ''
    });
    const getProfile = async () => {
        let _serverData = await _authService.GetProfileInformation()
            .then(response => {
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        if (_serverData) {
            setProfileInfo(_serverData);
        }
    }
    const getCompanyWise = async () => {
        let _serverData = await _service.getPriceData('test')
            .then(response => {
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        if (_serverData)
            setFormData(_serverData);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        const val = e.target.value;
        setFormData(prevState => ({
            ...prevState,
            [name]: val
        }));
    };
    const getInputValue = (inp) => {
        if (inp == undefined)
            return ''
        if (inp)
            return inp
        else
            return '';
    }
    const saveUserGroups = (e) => {
        setIsEdit(false);
    }
    const reset = (e) => {
        setFormData(oldFormdata);
        setIsEdit(false);
    }
    return (
        <div className="col-lg-12">
            <Refresh resellerId={resellerName}></Refresh>
            {profileInfo.IsMasterAdmin && <div className='row col-lg-12'>
                <div id='manageOrgs' className={isEdit ? 'action-button btn-disable' : 'action-button'} >
                    <div onClick={e => {
                        if (profileInfo.IsMasterAdmin && !isEdit) {
                            //return openConfirm('e');
                            setIsEdit(!isEdit);
                        }
                    }}><i className='fal fa-edit fa-lg m-r-1 cursor' title='Edit Users' ></i></div>
                </div>
                <div className="row col-lg-8 row-form users-form">
                    <span>Price Model</span>
                    <div className="row col-lg-12 row-form">
                        <div className='col-lg-4 pt-2'>
                            <label id='lblService' htmlFor='txtuserName'>End user Price</label>
                        </div>
                        <div className='col-lg-8'>
                            <input type="text" style={{ width: '100%' }} value={getInputValue(formdata.EndUserPrice)} id='EndUserPrice' onChange={handleChange} disabled={!(profileInfo.IsMasterAdmin && isEdit)} name="EndUserPrice" placeholder="" />

                        </div>
                    </div>
                    <div className='row col-lg-12 row-form'>
                        <div className='col-lg-4 pt-2 row-form'>
                            <label id='lblOrgName' htmlFor='Password'>Partner Price</label>
                        </div>
                        <div className='col-lg-8'>
                            <input type="text" style={{ width: '100%' }} value={getInputValue(formdata.PartnerPrice)} id='PartnerPrice' onChange={handleChange} disabled={!(profileInfo.IsMasterAdmin && isEdit)} name="PartnerPrice" placeholder="" />
                        </div>
                    </div>
                    <div className='row col-lg-12 row-form'>
                        <div className="user-btn" style={{ paddingRight: '25px' }}>
                            {isEdit && <div><Button id='btnSubmit' className='btn btn-outline-primary' onClick={e => saveUserGroups(e)}>Save</Button></div>}

                            {isEdit && <div style={{ paddingLeft: '20px' }}> <Button id='btnCancel' className='btn btn-outline-secondary' onClick={e => reset(e)}>Cancel</Button></div>}</div>
                        {/* <div>
                        <Button className='btn btn-outline-secondary' onClick={e => reset(e)}> Reset</Button></div> */}
                    </div>
                </div>
            </div>} </div>)
}


export default PriceDetails;