import './ComingSoon.css'
import cs from '../../../../images/img/coming-soon.png';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../util/AppContext';
const ComingSoon = () => {
    const context = useContext(AppContext);
    const urlParams = new URLSearchParams(window.location.search);
    const Cname = urlParams.get("name") ? urlParams.get("name") : "";
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const setWindowDimensions = () => {
        setWindowHeight(window.innerHeight)
    }
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [])

    const setHeaderName = () => {
        context.headerName = Cname; context[0].headerName = Cname;
        console.log(context.headerName)
        console.log(context[0].headerName)
        console.log(context[0].headerName)
    }

    return (<div className='col-xs-12' style={{overflowX:'auto'}}><div id='imgComingSoon' style={{height:`${windowHeight-133}px`}} className='imgback'><img src={cs} />{setHeaderName()}</div></div>)
}
export default ComingSoon;