import React, { useContext } from 'react';
import '../../NavMenu.css';
import logoLeftWhite from '../../../images/logo/logo_left-white.png';
import { AppContext } from '../../../util/AppContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { loginAuthProvider } from '../../../util/AuthProvider';
import authService from '../../Shared/Services/AuthService';

const SiteHeader = () => {
    const context = useContext(AppContext);
    const [toggleMenu, setToggleMenu] = useState(false)
    const [siteHeader, setSiteHeader] = useState(null)
    const[profileInfo,setProfileInfo]=useState({
        firstName:'',
        lastName:'',
        fullName:'',
        icon:'',
        role:''
    })
    const authServices=authService();
    useEffect(() => {
        // console.log(context.headerName);
        // console.log(context[0].headerName);
        setSiteHeader(context[0].headerName);
        getProfileInfo();       
    }, [context[0].headerName])

    const getProfileInfo = () => {
        
        let profileData=authServices.GetProfileInformation()
        .then(response => {
            //console.log(response);
            
            var loggedinUser=localStorage.getItem('loggedItem');
            var data=JSON.parse(response.data);
            if(window.location.href.toString().toLowerCase().includes('/main/')){
                setSiteHeader(data.ResellerName);
            }
            if (data) {
                if(data.profile?.firstName){
                setProfileInfo({
                    firstName: atob(loggedinUser),
                    lastName: data.profile?.lastName,
                    fullName:`${data.profile?.firstName} ${data.profile?.lastName}`,
                    icon: data.profile?.firstName.charAt(0) + data.profile?.lastName.charAt(0),
                    role: data.role
                })}
                else{
                    setProfileInfo({
                        firstName: atob(loggedinUser),
                        fullName:`${data.userName}`,
                        icon: data.userName.charAt(0) + data.userName.charAt(1),
                        role: data.role
                    }) 
                }
            }else{
                setProfileInfo({
                    firstName: loggedinUser?atob(loggedinUser):'',
                    fullName:'',
                    icon: '',
                    role: ''
                }) 
            }
            return data;
        }).catch((reason) => {           
            console.log("catch profile information" + reason);           
        });
        
        // console.log(accountInfo)
        // console.log(account)
        // console.table([account, accountInfo])
    }
    const onToggleMenu = () => {
        console.log(toggleMenu)
        if (toggleMenu) {
            document.getElementById('contentPane').classList.add('pl-content');
            document.getElementById('contentPane').classList.remove('pl-default-content');
            document.getElementById('nav-main-menu').classList.add('expanded');
            document.getElementById('toggleIcon').classList.add('fa-toggle-on');
            document.getElementById('toggleIcon').classList.remove('fa-toggle-off');
        } else {
            document.getElementById('contentPane').classList.remove('pl-content');
            document.getElementById('contentPane').classList.add('pl-default-content');
            document.getElementById('nav-main-menu').classList.remove('expanded');
            document.getElementById('toggleIcon').classList.remove('fa-toggle-on');
            document.getElementById('toggleIcon').classList.add('fa-toggle-off');
        }
        setToggleMenu(!toggleMenu);
        console.log(toggleMenu)
        context[0].navigationExpand = toggleMenu;
    }
    
    return (

        <header className='header' key={1}>
            <div className='headerCC row'>              
                <div className='col-sm-3'>
                    <div className='headerToggleIcon'>
                        {/* <div className='togglemenuIcon' onClick={(e)=>onToggleMenu()}>
                    <i id='toggleIcon'   className={toggleMenu?' fa fa-toggle-on':' fa fa-toggle-off'}></i>
                    </div>
                    <div className=''> */}
                        <img src={logoLeftWhite} alt='CC header' />
                        {/* </div> */}
                    </div>
                </div>
                <div className='header-profile col-sm-6' id='siteHeader'>{siteHeader}</div>
                <div className="col-sm-3">

                    <div className="widget-content-right  ml-3 header-user-info">
                        {/* <div className='col-sm-2'>
                            <div className='header-user-icon'>{profileInfo.icon.toLocaleUpperCase()} </div>
                        </div> */}
                        <div className='col-sm-12'>
                        <div className="widget-heading"> {profileInfo.firstName} </div>
                        {/* <div className="widget-subheading"> {profileInfo.role} </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default SiteHeader;