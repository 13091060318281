import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import dashboardservice from '../service/dashboard.service';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LinkCellsRender from '../Features/link.cell';
import CustomfloatingFilter from '../Features/floatingFilter';
import '../../../CSS/alarms.css';

const AlarmsDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState({})
  const [modalData, setModalData] = useState({
    header: '',
    body: '',
    footer: '',
    oKbutton: true,
    cancelButton: false,
    okAction: () => { }
  });
  const [clientStyle, setClientStyle] = useState({
    borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", borderCollapse: "collapse",
    height: `${window.innerHeight - 155}px`,
  });
  const setWindowDimensions = () => {
    setClientStyle({
      borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", borderCollapse: "collapse",
      height: `${window.innerHeight - 155}px`,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, []);

  const overlayProgress = (show) => {
    if (show)
        document.getElementById('main-div-form-elements').style.display = 'block';
    else
        document.getElementById('main-div-form-elements').style.display = 'none';
}
  const urlParams = new URLSearchParams(window.location.search);
  const orgId = urlParams.get("orgId") ? urlParams.get("orgId") : "";
  const _service = dashboardservice();
  const getDashboardData = async (api) => {
    overlayProgress(true);
    getcloudcoverBackupDetails(orgId);
    let data = await _service.getCompanyDashboardData(orgId)
      .then(response => {
       
        let selectItems = response.data.results;
        if (selectItems.length > 0) {
          selectItems = selectItems.filter(x => { return x.jobStatus != '' && x.jobStatus != null });
        }
        console.log(selectItems)
        return selectItems;
      })
      .catch((reason) => {
        console.log("catch Org" + reason);
      });
    console.log(data);
    var _results = data.filter(x => {
      return x.companyId === orgId;
    });
    var _finalAlaramsResult = [..._results];
    // setRowData(_results);
    // api.setRowData(_results);
    // let data365 = await _service.get365AlarmsData(atob(localStorage.getItem('OrgId')))
    //   .then(response => {
    //     console.log(response.data);
    //     let selectItems = response.data.results;
    //     if (selectItems.length > 0) {
    //       selectItems = selectItems.filter(x => { return x.jobStatus != '' && x.jobStatus != null });
    //     }
    //     console.log(selectItems)
    //     return selectItems;
    //   })
    //   .catch((reason) => {
    //     console.log("catch Org" + reason);
    //   });
    //console.log(data365);
    // if (data365 && data365.length > 0) {
    //   var _results365 = data365.filter(x => {
    //     return x.companyId === orgId;
    //   })
    //   _finalAlaramsResult = [..._results, ..._results365];
    // }
    setRowData(_finalAlaramsResult);
    api.setRowData(_finalAlaramsResult);
    overlayProgress(false)
  }

  const getAlarmsDataForAll=async (api)=>{
    let data = await _service.getDashboardData(orgId)
    .then(response => {
     
      let selectItems = response.data.results;
      if (selectItems.length > 0) {
        selectItems = selectItems.filter(x => { return x.jobStatus != '' && x.jobStatus != null });
      }
      console.log(selectItems)
      return selectItems;
    })
    .catch((reason) => {
      console.log("catch Org" + reason);
    });
  console.log(data);
  var _results = data.filter(x => {
    return x.companyId === orgId;
  });
  var _finalAlaramsResult = [..._results];
  setRowData(_results);
  api.setRowData(_results);
  let data365 = await _service.get365AlarmsData(atob(localStorage.getItem('OrgId')))
    .then(response => {
      console.log(response.data);
      let selectItems = response.data.results;
      if (selectItems.length > 0) {
        selectItems = selectItems.filter(x => { return x.jobStatus != '' && x.jobStatus != null });
      }
      console.log(selectItems)
      return selectItems;
    })
    .catch((reason) => {
      console.log("catch Org" + reason);
    });
  console.log(data365);
  if (data365 && data365.length > 0) {
    var _results365 = data365.filter(x => {
      return x.companyId === orgId;
    })
    _finalAlaramsResult = [..._results, ..._results365];
  }
  setRowData(_finalAlaramsResult);
  api.setRowData(_finalAlaramsResult);
  overlayProgress(false)
  }

  const getcloudcoverBackupDetails = async (_orgId) => {
    let dataccb = await _service.getCloudCoverBackupDetails(_orgId)
      .then(response => {
        
        return response.data;
      })
      .catch((reason) => {
        console.log("getcloudcoverBackupDetails Org" + reason);
      });
    
  }


  const dateFormatter = (params) => {
    return params.value ? moment(params.value).format("DD/MM/YYYY hh:mm:ss").toString() : '';
  };
  const customDateFormatter = (v) => {
    return v ? moment(v).format("DD/MM/YYYY hh:mm:ss").toString() : '';
  };

  const rowHeight = 25;
  const headerHeight = 30;
  const columnDefs = [
    {
      field: 'serviceName', maxWidth: 150, headerName: 'Service', floatingFilter: true, floatingFilterComponent: 'floating', floatingFilterComponentParams: {
        suppressFilterButton: true,
        fieldName: 'serviceName',
        rowData: rowData,
        timeOut: 3000
      },
    },
    {
      field: 'jobStatus', maxWidth: 180, headerName: 'Status', floatingFilter: true, floatingFilterComponent: 'floating', floatingFilterComponentParams: {
        suppressFilterButton: true,
        fieldName: 'serviceName',
        rowData: rowData,
        timeOut: 3000
      },
    },
    {
      field: 'lastRuntime', maxWidth: 340, headerName: 'Time', valueFormatter: dateFormatter, floatingFilter: true, filter: 'agDateColumnFilter',
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = cellValue;
          var dateParts = dateAsString.split('/');
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
          var celDate = moment(cellValue).format("DD/MM/YYYY").toString();
          var selectedDate = moment(filterLocalDateAtMidnight).format("DD/MM/YYYY").toString();
          if (celDate == selectedDate) {
            return 0;
          }

          if (celDate < selectedDate) {
            return -1;
          }

          if (celDate > selectedDate) {
            return 1;
          }
        },
      },
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'objectName', headerName: 'Object', floatingFilter: true, floatingFilterComponent: 'floating', floatingFilterComponentParams: {
        suppressFilterButton: true,
        fieldName: 'serviceName',
        rowData: rowData,
        timeOut: 3000,
      },
    },
    {
      field: 'alName', maxWidth: 120, headerName: 'Detail', cellRenderer: 'link', cellRendererParams: {
        fieldName: 'messageText',
        secondaryfield: 'remakrs',
        keyId: 'alName'
      }
    },

  ];

  const dateFilterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('/');
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };
  const onGridReady = (params) => {
   
    params.api.actionMethod = OpenPopup;
    var portalRole=atob( localStorage.getItem('portalRole'));
    if(portalRole.includes('companyowner') || portalRole.includes('companyadministrator')){
      getDashboardData(params.api); 
    }else{    
    getAlarmsDataForAll(params.api)
    }
  };
  const defaultColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    resizable: true
  };
  const style = {
    width: '99%',
    height: '100%'
  };
  const getRowStyle = params => {
    return { fontSize: "Small" };
  };
  const ShowLinkcell = (p) => {
    return `<div className='link-grid' onClick={(e)=>{${OpenPopup(p.data[`messageText`])}}}>view</div>`;
  }
  const OpenPopup = (m, p) => {
    let mdData = modalData;
    mdData.header = 'Alarm details';
    mdData.body = setPopupdata(p);
    mdData.okAction = (e) => closeModal(e);
    setModalData(mdData);
    setShowModal(true);
  }

  const setPopupdata = (p) => {
    let isalarams = p.serviceName.toLowerCase() == 'general';
    let result = '';
    if (isalarams) {
      result = (<div className='popup-content'>
        <div><span>Type:</span> <p>{p.serviceName}</p></div>
        <div><span>Status:</span> <p>{p.jobStatus}</p></div>
        <div><span>Last Activation Time:</span> <p>{customDateFormatter(p.lastRuntime)}</p></div>
        <div><span>Message:</span> <div className='modal-message'>{p.messageText}</div></div>
        <div><span>Remark:</span> <p>{p.remarks}</p></div>
      </div>)
    } else {
      result = (<div className='popup-content'>
        <div><span>Type:</span> <p>{p.serviceName}</p></div>
        <div><span>Status:</span> <p>{p.jobStatus}</p></div>
        <div><span>Last Run:</span> <p>{customDateFormatter(p.lastRuntime)}</p></div>
        <div><span>Last End Time:</span> <p>{customDateFormatter(p.lastFinished)}</p></div>
        <div><span>Failure Message:</span> <div className='modal-message'>{p.messageText}</div></div>
      </div>)
    }
    return result;

    //         Type: 
    // Status: 
    // Last Activation Time:
    // Message:
    // Remark:

  }
  const closeModal = (e) => {
    setShowModal(e);
  }

  function onFirstDataRendered(params) {
    //params.api.sizeColumnsToFit();
  }

  const getFrameworkComponents = () => {

  }
  return (
    <div>
      <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
      <div className="ag-theme-alpine" id='alarms-grid' style={clientStyle} >
        <AgGridReact style={style}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowStyle={getRowStyle}
          onGridReady={onGridReady}
          pagination={true}
          paginationAutoPageSize={true}
          paginationPageSize={5}
          frameworkComponents={{ 'link': LinkCellsRender, 'floating': CustomfloatingFilter }}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center"></span>'
          }
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center">No current alarms</span>'
          }
          suppressScrollOnNewData={true} >
        </AgGridReact>
      </div>
      <Modal isOpen={showModal} centered>
        <ModalHeader >
          {modalData.header}
        </ModalHeader>
        <ModalBody>
          {modalData.body}
        </ModalBody>
        <ModalFooter>
          {modalData.oKbutton && <button className="btn btn btn-primary" onClick={() => modalData.okAction()}>Ok</button>}
          {modalData.cancelButton && <button className="btn btn-primary" onClick={() => closeModal(false)}>Cancel</button>}

        </ModalFooter>
      </Modal>
    </div>
  );
}
export default AlarmsDetails;