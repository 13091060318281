import React, { Component } from 'react';
import { loginAuthProvider } from '../util/AuthProvider';
import { InteractionRequiredAuthError } from 'react-aad-msal';

export class Counter extends Component {
  static displayName = Counter.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
    this.loginREs=this.loginREs.bind(this);
    var request = {
      scopes: ['Domain.Read.All',
        'GroupMember.Read.All',
        'Application.ReadWrite.All',
        'User.ReadWrite.All'],
      loginHint: "admin@yorkshiremail.co.uk"
    };
    if (window.location.href.includes('token')) {
      console.log('test');
    }
    else {
      this.loginAuthcode();
    }

  }
loginAuthcode=()=>{
  const authCodeUrlParameters = {
    redirectUri: '',
    responseMode: "form_post",
    scopes: ["User.Read"],
  };

  loginAuthProvider
    .getAuthCodeUrl(authCodeUrlParameters)
    .then((response) => {
     console.log('response');
    })
    .catch((error) => {
      console.log(error);
    });
}

  loginREs = async () => {
    var request = {
      scopes: ['Domain.Read.All',
        'GroupMember.Read.All',
        'Application.ReadWrite.All',
        'User.ReadWrite.All'],
      loginHint: "admin@yorkshiremail.co.uk"
    };
    try {
      const loginResponse = await loginAuthProvider.login(request);
      var contre=loginAuthProvider.ssoSilent(request).then(re=>{
        console.log('re');
        return re;
      });
      console.log(contre)
    } catch (err) {
      console.log(err)
    }
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render() {
    return (
      <div>
        <h1>Counter</h1>

        <p>This is a simple example of a React component.</p>

        <p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>

        <button className="btn btn-primary" onClick={this.incrementCounter}>Increment</button>
      </div>
    );
  }
}
