import React, { Fragment,useRef,useState,forwardRef,useImperativeHandle } from 'react';
import moment from "moment";
import './floatingFilter.css';
import Select from 'react-select';

export default forwardRef((props, ref) => {
    const [currentValue, setCurrentValue] = useState(null);
const [filterData, setFilterData] = useState([])
    const inputRef = useRef(null);
 
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {           
            onParentModelChanged(parentModel) {
                // When the filter is empty we will receive a null value here
                if (!parentModel) {
                    inputRef.current.value = '';
                    setCurrentValue(null);
                } else {
                    console.log(parentModel.filter)
                    inputRef.current.value = parentModel.filter + '';
                    setCurrentValue(parentModel.filter);
                }
            }
 
        }
    });
 
 
    const onInputBoxChanged = input => {
        if (input.target.value === '') {
            // clear the filter
            props.parentFilterInstance(instance => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }
 
        setCurrentValue(input.target.value);
        props.parentFilterInstance(instance => {
            instance.onFloatingFilterChanged('equalTo', input.target.value);
        });
    }
    const customDateFormatter = (v) => {       
        return v && v!=''? moment(v).format("DD/MM/YYYY hh:mm:ss").toString() : '';
    };
    const style = {
        color: props.color,
        width: "30px"
    };
    const getOptionData=()=>{
        var timeOut = props.timeOut ?? 3000;
        setTimeout(() => {
            let arr = [];
            arr.push({ id: '', value: '' });
            props.api.forEachNodeAfterFilterAndSort((r) => {
                let val = r.data[props.column.colId];
                let values=r.data[props.column.colId];
                if (props.isDate) {
                    values = customDateFormatter(values);
                }
                let arrFinal = arr.map((r, i) => {
                    return r.id;
                })
                if (arrFinal.indexOf(val) == -1)
                    arr.push({ id: val, value: values });
            })
            setFilterData(arr);
            return;
        }, timeOut);
    }
    const handleChaneDrop = (e) => {
    setCurrentValue(e.target.value);
    if (e.target.value === '') {
        // clear the filter
        props.parentFilterInstance(instance => {
            instance.onFloatingFilterChanged('equalTo', null);
        });
        return;
    }
    props.parentFilterInstance(instance => {
        instance.onFloatingFilterChanged('equalTo', e.target.value);
    });
 }
    return (
        <Fragment>
            {getOptionData()}
            <div className='options'>
            <select ref={inputRef} onChange={e => handleChaneDrop(e)} >
                         {filterData.map((r, index) => {
                            return <option key={r.id} value={r.id}>{r.value}</option>
                        }) }
                    </select>
                    {/* <Select options={filterData} /> */}
                    </div>
        </Fragment>
    );
 });