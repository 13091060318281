import axiosInstance from "../../../util/request.interceptor";
import React,{useState} from "react";
import { API_ROUTE,Routes } from '../../../util/constants';
import { loginAuthProvider } from "../../../util/AuthProvider";
 
const authService = ()=>{
    const authenticate = async (requestObj,isAuthenticate) =>{
        if(!isAuthenticate){
            return await axiosInstance.post(`${API_ROUTE}${Routes.GET_VAC_LOGIN}`,requestObj ); 
        }
        let tokenString=await loginAuthProvider.getAccessToken();
        let token=tokenString?.accessToken;
        const config = {
            headers: {
                Authorisation: `Bearer ` + token,
            },
        };
        return await axiosInstance.post(`${API_ROUTE}${Routes.GET_VAC_LOGIN}`,requestObj,config );
    }
    const authenticateMFA=async(username,mfaCode)=>{
        return await axiosInstance.get(`${API_ROUTE}${Routes.GET_DASHBOARD_DATA}?orgId=${username}&id=${mfaCode}` );
    }
    const GenerateSSOURL=async()=>{
        return await axiosInstance.get(`${API_ROUTE}${Routes.GET_SSO_LOGIN}` );
    }
    const GetProfileInformation=async()=>{
        const authKey=localStorage.getItem('AuthCacheKey');
        return await axiosInstance.get(`${API_ROUTE}${Routes.GET_PROFILE_INFO}?key=${authKey}` );
    }
    const GetVerifieddomains=async()=>{
        const url='https://graph.microsoft.com/v1.0/organization'
        let tokenString=await loginAuthProvider.getAccessToken();
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString.accessToken,
            },
        };      
        return axiosInstance.get(url,config);
    }
    
    const ResetPassword=async(postData)=>{
        return await axiosInstance.post(`${API_ROUTE}${Routes.GET_AUTH}/ResetPassword`,postData );
    }
    const ChangePassword=async(postData)=>{
        return await axiosInstance.post(`${API_ROUTE}${Routes.GET_AUTH}/ChangePassword`,postData );
    }
    const GetAsyncStatus=async()=>{
        const authKey = localStorage.getItem('AuthCacheKey');
        if (!authKey){
            return null;
        }
        const config = {
            headers: {
                AuthKey:authKey,
            },
        };
        return await axiosInstance.get(`${API_ROUTE}${Routes.GET_AUTH}/GetAsyncActions`,config);
    }

    return{
        authenticate,
        authenticateMFA,
        GenerateSSOURL,
        GetProfileInformation,
        GetVerifieddomains,
        ResetPassword,
        ChangePassword,
        GetAsyncStatus
    }
}
export default authService;