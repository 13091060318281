import { useState } from "react";
import './CheckBox.css';
const Checkbox = ({ label, checked, onChange, ...props }) => {
    const defaultChecked = checked ? checked : false;
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const changeEvent = (e) => {
        setIsChecked((prev) => !prev);
        onChange(e);
    }
    return (
        <div className="wrapper">
            <label className="toggler-wrapper style-13">
                <input type="checkbox"  {...props} onChange={(e) => { changeEvent(e) }} />
                <div className="toggler-slider">
                    <div className="toggler-knob"></div>
                </div>
            </label>
        </div>
    );
};
export default Checkbox;
