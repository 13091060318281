import React, { Component } from 'react';

export class Home extends Component {
  constructor(props){
    super(props)
    this.isAuthKey = localStorage.getItem('AuthCacheKey');
    if (!this.isAuthKey) {
      window.location.assign('/login')
    } else {
      var orgId=atob(localStorage.getItem('OrgId'))
      window.location.assign(`/login`)
    }
    window.location.href='/login';
  }
  static displayName = Home.name;

  render() {
    return (
      <div></div>
    )
  }
}
