import React, { useEffect, useState } from 'react'
import '../../../CSS/alarms.css';
import '../../../CSS/CustomTable.css';
import detailsService from '../service/details.service';
import ccr from "../../../images/img/v-ccr.png";
import { CC365Charts } from '../../Shared/components/Charts/CC365Charts';
import NoDataFound from './NoDataFound';

const Replication = (props) => {
  const [resultData, setResultData] = useState({})
  const _service = detailsService();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getcloudcoverReplicationDetail(props.OrgId);
    return () => {
      controller.abort();
    }
  }, [props.OrgId]);
  const getcloudcoverReplicationDetail = async (_orgId) => {
    overlayProgress(true);
    let dataccb = await _service.getReplicationDetails(_orgId)
      .then(response => {
        return response.data;
      })
      .catch((reason) => {
        console.log("getcloudcoverBackupDetails Org" + reason);
      });

    overlayProgress(false);
    if (dataccb) {
      setResultData(dataccb);
    }
    else {
      setResultData(null)
    }
  }
  const getSumValues = (key) => {

    var val = resultData.reduce((previousValue, currentValue) =>
      previousValue + currentValue[key], 0);
    return val.toFixed(2);
  }
  const overlayProgress = (show) => {
    if (show)
      document.getElementById('main-div-form-elements').style.display = 'block';
    else
      document.getElementById('main-div-form-elements').style.display = 'none';
  }
  const getUsed = (used, quota) => {
    return (used / quota) * 100

  }
  const calculateUsage = (val) => {
    //9.313225/10000000000
    return (val * 9.313225 / 10000000000).toFixed(0);
  }
  const CloudCoverReplicationUI = (<div>
    {resultData && resultData.length > 0 &&
      <div>
        <div className='item-center' style={{ color: '#1e5d9d', textAlign: 'center;', position: 'relative' }}><h4>Hardware Quota</h4></div>
        <div className='table'>
          {resultData.map((item, index) => {
            var unlimitedCpu = item.vcpuQuota == 999999 ? 100 : item.vcpuQuota;
            return (<> <div className='table-cell cell-header-custom' style={{ backgroundColor: '#fff' }}>
              {<CC365Charts chartType={'radialBar'} label={''}
                dataPoints={{ used: item.vcpuUsed, quota: unlimitedCpu,unit:'' }}
                usage={getUsed(item.vcpuUsed, unlimitedCpu)} />}
              <div className='row black'>
                <div style={{ backgroundColor: '#15497e', color: '#fff', fontWeight: '700' }}>
                  <span>vCPU</span>
                </div>
                <div className='col-md-6'><b>Used</b></div>
                <div className='col-md-6'><b>Quota</b></div>
                <div className='col-md-6'>{item.vcpuUsed}</div>
                <div className='col-md-6'>{item.vcpuQuota == 999999 ? 'Unlimited' : item.vcpuQuota}</div>
              </div>
            </div>
              <div className='table-cell cell-header-custom' style={{ backgroundColor: '#fff' }}>
                {<CC365Charts chartType={'radialBar'} label={''}
                  dataPoints={{ used: calculateUsage(item.memoryUsed), quota: calculateUsage(item.memoryQuota) ,unit:'GB'}}
                  usage={getUsed(item.memoryUsed, item.memoryQuota)} />}

                <div className='row black' >
                  <div style={{ backgroundColor: '#15497e', color: '#fff', fontWeight: '700' }}>
                    <span>Memory</span>
                  </div>
                  <div className='col-md-6'><b>Used</b></div>
                  <div className='col-md-6'><b>Quota</b></div>
                  <div className='col-md-6'>{calculateUsage(item.memoryUsed)}GB</div>
                  <div className='col-md-6'>{calculateUsage(item.memoryQuota)}GB</div>
                </div>
              </div>
              <div className='table-cell cell-header-custom' style={{ backgroundColor: '#fff' }}>
                {<CC365Charts chartType={'radialBar'} label={''}
                  dataPoints={{ used: calculateUsage(item.storageUsed), quota: calculateUsage(item.storageQuota),unit:'GB' }}
                  usage={getUsed(item.storageUsed, item.storageQuota)} />}

                <div className='row black'>
                  <div style={{ backgroundColor: '#15497e', color: '#fff', fontWeight: '700' }}>
                    <span>Storage</span>
                  </div >
                  <div className='col-md-6'><b>Used</b></div>
                  <div className='col-md-6'><b>Quota</b></div>
                  <div className='col-md-6'>{calculateUsage(item.storageUsed)}GB</div>
                  <div className='col-md-6'>{calculateUsage(item.storageQuota)}GB</div>
                </div>
              </div>

              <div className='col-md-12 item-center' style={{ color: '#1e5d9d', textAlign: 'center;' }}><h4>Protected Resources</h4></div>
              <div className='row col-md-12' style={{ border: 0 }}>
                <div className='col-md-4'>
                  <div className='col-md-12 rowBlue' style={{ textAlign: 'center' }}>Protected Servers</div>
                  <div className='col-md-12' style={{ textAlign: 'center' }}>{item.protectedUsed}</div>
                </div>
                <div className='col-md-4'>

                </div>
                <div className='col-md-4'>
                  <div className='col-md-12 rowBlue' style={{ textAlign: 'center' }}>Public Ip</div>
                  <div className='col-md-12' style={{ textAlign: 'center' }}>{item.publicIpCount}</div>
                </div>
              </div>
            </>);
          })}

        </div>
      </div>
    }

    {
      (resultData == null || resultData.length == 0) &&
      <div>
        <NoDataFound
          header={'RTO will improve with CloudCover Replication'}
          content={`<p><span >In a data-driven world, your customers need a fast, reliable method of both data protection and data recovery. Veeam Cloud Connect Replication helps ensure the availability of business-critical systems.
            </span></p>
            <p><span >The threat to modern business data has never been higher and whilst backups provide protection against data loss, one factor that is often overlooked is business interruption.</span></p>
            <p>How long would it take for your customer to retrieve their data after an incident? This is known as your Recovery Time Objective (RTO) and it is an important, yet often overlooked factor in any Disaster Recovery Plan. Restoring and copying data from off-site locations takes time and recovering a TB of data will take hours, potentially even days.</p>              
              `}
          imgUrl={ccr}
        />
      </div>
    }
  </div>);

  return (
    <div>
      <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
      {CloudCoverReplicationUI}
    </div>
  );

}
export default Replication;