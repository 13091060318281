import React from "react";
import "../../CSS/general.css";

const ActionButtons = (props) => {
    const handleBack = () => {
       props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
       props.lastStep();
    };

    const handleCancel = () => {
        //props.closePopupModal();
        window.location.href = `/login`;
    };

    return (
        <div>
            <table cellSpacing="5" cellPadding="5">
                <tbody>
                    <tr>
                        <td align="right">
                            {props.currentStep > 1 && (
                                <button onClick={handleBack} className="btn btn-primary btn-sm" disabled={props.disabled }>Previous</button>
                            )}
                        </td>
                        <td align="right">
                            {props.currentStep < props.totalSteps && (
                                <button onClick={handleNext} className="btn btn-primary btn-sm" disabled={props.disabled}>Next</button>
                            )}
                            {props.currentStep === props.totalSteps && (
                                <button onClick={handleFinish} className="btn btn-primary btn-sm" disabled={props.disabled}>Finish</button>
                            )}
                        </td>
                        <td align="right">
                            <button onClick={handleCancel} className="btn btn-outline-primary btn-sm" disabled={props.disabled}>Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default ActionButtons;