import React from 'react';
import dashboardservice from '../service/dashboard.service';
import { useEffect } from 'react';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import '../Features/ag-grid-header.css';
import CustomfloatingFilter from '../Features/floatingFilter';
import LinkCellRender from '../Features/linkcell.render';
import Refresh from './Refresh';
import { useCallback } from 'react';
import ContextMenuRender from '../Features/contextmenu.cell';
import PopupCellRenderer from '../Features/PopulateCellRender';

const WorkloadSummary = (props) => {
  const [showRightMenu, setShowRightMenu] = useState(false);
	const [postion, setPosition] = useState({ x: 0, y: 0 });
  const [gridAPI, setGridAPI] = useState()
    const [columnDef, setColumnDef] = useState([]);
    const [resellerName, setResellerName] = useState('')
    const [rowData, setRowData] = useState({})
    const _service = dashboardservice();
    useEffect(() => {
        const controller = new AbortController();
        //getWorloadData();
        var _reseller=localStorage.getItem('rsName');
        setResellerName(atob(_reseller));
        return () => {
            controller.abort();
        }
    }, [props]);

    const contextMenu = (e) => {
      e.preventDefault();
      setPosition({ x: e.pageX, y: e.pageY });
      setShowRightMenu(true);
    };
  
    const hideContextMenu = () => {
      setShowRightMenu(false);
    };

    const getWorloadData = async (p) => {
      overlayProgress(true);
        let _WSData = await _service.getWorkLoadSummary()
            .then(response => {
                return response.data;
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        overlayProgress(false);
        updateColumnDef(_WSData);       
        setTimeout(() => {
            p.setRowData(_WSData);
            p.setGroupHeaderHeight(50);
        }, 300);
        
    }
    const getContextMenuItems = useCallback((params) => {
      return ['aler','test']
    }, []);

    const overlayProgress = (show) => {
      if (show)
          document.getElementById('main-div-form-elements').style.display = 'block';
      else
          document.getElementById('main-div-form-elements').style.display = 'none';
  }
  const updateColumnDef = (d) => {
    var colDef = [
      {
        field: 'reseller', minWidth: 270, maxWidth: 350, headerName: 'Reseller',headerClass:'regular-header',floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
          suppressFilterButton: true,
          fieldName:'reseller',
          timeOut:3000
        }
      }, {
        field: 'companyName', minWidth: 270, maxWidth: 350, headerName: 'Company'
        ,floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
          suppressFilterButton: true,
          fieldName:'companyName',
          timeOut:3000
        }
        , cellRendererParams: {
          fieldName: 'companyName',
          isLink:true,
          keyId: 'companyId'
        },headerClass:'regular-header'
      }, {
        headerName: 'Local',
        children: [
          { field: 'backedupVms', maxWidth: 82, headerName: 'Backed VM',cellClass:'text-align-center' },
          { field: 'replicatedVms', maxWidth: 82, headerName: 'Replicated VM',cellClass:'text-align-center' },
        ]
      },
      {
        headerName: 'Managed Agents',
        children: [
          { field: 'managedAgentsLinuxOS', maxWidth: 82, headerName: 'Linux OS' ,cellClass:'text-align-center'},
          { field: 'managedAgentsMacOS', maxWidth: 82, headerName: 'Mac OS',cellClass:'text-align-center' },
          { field: 'managedAgentsWindowsDesktopOS', maxWidth: 82, headerName: 'Win Desktop OS',cellClass:'text-align-center' },
          { field: 'managedAgentsWindowsServerOS', maxWidth: 82, headerName: 'Win Server OS',cellClass:'text-align-center'},
          { field: 'managedServerAgents', maxWidth: 82, headerName: 'ManagedServerAgents',cellClass:'text-align-center' },
        ]
      }, {
        headerName: 'Cloud', children: [
          { field: 'vmCloudReplicas', maxWidth: 82, headerName: 'Replicated VM',cellClass:'text-align-center' },
          { field: 'vmCloudBackups', maxWidth: 82, headerName: 'Backedup Server',cellClass:'text-align-center' },
          { field: 'managedCloudVms', maxWidth: 82, headerName: 'VM',cellClass:'text-align-center' },
          { field: 'workstationCloudBackups', maxWidth: 82, headerName: 'Workstation',cellClass:'text-align-center' },
        ]
      },
      {
        headerName: 'SaaS', children: [
          { field: 'vb365ProtectedUsers', maxWidth: 90, headerName: '365 Protected Users',cellClass:'text-align-center'},
        ]
      }
    ];
    // var contentKeys = Object.keys(d[0]);
    // var allColumns = contentKeys.splice(2, contentKeys.length - 1);
    // allColumns.forEach(d => {
    //     colDef = [...colDef, { headerName: capitalizeFirst(d), field: d,suppressSizeToFit: true, }];
    // });


   

    setColumnDef(colDef);
    }

    const printPreview=(e)=>{
      console.log(e)
      window.print();
     }
     
     const exportCSV=(e)=>{
      gridAPI.exportDataAsCsv();
     }
    const onGridReady = (params) => {
        setGridAPI(params.api)
        getWorloadData(params.api);
      };
    const defaultColDef = {
        editable: false,
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        cellRenderer:'populate',
        resizable: true
      };
      const style = {
        width: '99%',
        height: '100%'
      };
      const getRowStyle = params => {
        return { fontSize: "Small" };
      };
    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

const onCellContextMenu=(e)=>{
  console.log('first')
return (<div>test</div>)
}

    const rowHeight = 25;
    const [clientStyle, setClientStyle] = useState({
        borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", borderCollapse: "collapse",
        height: `${window.innerHeight - 155}px`,
      });
  const headerHeight = 230;
    return (
        <div>
          <div className='left-function display-header-function'>
            <div style={{width:'30px'}} className='cursor' onClick={e=>printPreview(e)}>
          <i className="fa fa-solid fa-print" ></i>
          </div>
          <div>
          <i className=" fa-solid fa-q"></i>  
          </div>
          <div onClick={e=>exportCSV(e)} className='cursor'>          
          <i className="fa fa-solid fa-download" ></i>
          </div>
          </div>
          <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
           <Refresh resellerId={resellerName}></Refresh>
             <div className="ag-theme-alpine workload" id='alarms-grid' style={clientStyle} >
<AgGridReact style={style}
          columnDefs={columnDef}
          defaultColDef={defaultColDef}
          getRowStyle={getRowStyle}
          onGridReady={onGridReady}
          pagination={true}
          paginationAutoPageSize={true}
          frameworkComponents={{ 'link': LinkCellRender, 'floating': CustomfloatingFilter,'contextmenu':ContextMenuRender,'populate':PopupCellRenderer }}
          paginationPageSize={5}
          // onCellContextMenu={onCellContextMenu}
          // oncontextmenu="return false;"
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          overlayLoadingTemplate={
            '<div class="" style="margin: 7em"></div> <span class="ag-overlay-loading-center " style="font-size: 18px; z-index: 100000"></span>'
          }
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center">No workload summary data</span>'
          }
          suppressScrollOnNewData={true} >
        </AgGridReact></div>
        </div>
    )
}

export default WorkloadSummary