import Axios from "axios";
import React, { useState } from "react";
import { API_ROUTE, Routes } from "../../../util/constants";

const dashboardservice = () => {
    const getDashboardData = async (orgId) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_DASHBOARD_DATA}?orgId=${orgId}`);
    }
    const get365DashboardData = async (orgId) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_365DASHBOARD_DATA}?orgId=${orgId}`);
    }
    const getCompanyDetailsById = async (orgId, id) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_DASHBOARD_DATA}?orgId=${orgId}&id=${id}`);
    }
    const getAlarmsDetails = async (requestObj) => {
        return await Axios.post(`${API_ROUTE}${Routes.GET_DASHBOARD_DATA}`, requestObj);
    }
    const getCloud365Details = async (vacOrgId,signal) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_CLOUDCOVER_DETAILS}?vacOrgId=${vacOrgId}`,{signal:signal});
    }
    const getResellerDetails = async (orgID,resellerId,signal) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/${orgID}/reseller/${resellerId}`,{signal:signal});
    }
    const updateOrgActions = async (orgID,action) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/${orgID}/${action}`);
    }

    const updateOrgDetails = async (orgID,requestObj) => {
        return await Axios.post(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/${orgID}/update`, requestObj);
    }
    const getCloudCoverBackupDetails = async (orgID) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/ccbackup/${orgID}/details`);
    }
    const get365AlarmsData = async (orgId) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_365ALARMS_DATA}?orgId=${orgId}`);
    }
    const getWorkLoadSummary = async () => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_WORKLOADSUMMARY_DATA}`);
    }
    const getLicSummary = async () => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_LICSUMMARY_DATA}`);
    }
    const getCompanyDashboardData = async (orgId) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_COMPANYDASHBOARD_DATA}?orgId=${orgId}`);
    }
    const get365ChartData = async (orgId) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_DASHBOARD}/Get365ChartData?orgId=${orgId}`);
    }

    return {
        getDashboardData,
        get365DashboardData,
        getCompanyDetailsById,
        getAlarmsDetails,
        getCloud365Details,
        getResellerDetails,
        updateOrgActions,
        updateOrgDetails,
        getCloudCoverBackupDetails,
        get365AlarmsData,
        getWorkLoadSummary,
        getLicSummary,
        getCompanyDashboardData,
        get365ChartData
    }
}
export default dashboardservice;