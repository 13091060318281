import React, { Component, useState } from 'react';
import ContextMenus from './ContextMenu';

const ContextMenuRender = (props) => {
    const [showRightMenu, setShowRightMenu] = useState(false);
    const [postion, setPosition] = useState({ x: 0, y: 0 });

    const contextMenu = (e) => {
        e.preventDefault();
        setPosition({ x: e.pageX, y: e.pageY });
        setShowRightMenu(true);
    };

    const hideContextMenu = () => {
        setShowRightMenu(false);
    };

    return (
        <>
        <div  onContextMenu={contextMenu}>{props.value} {showRightMenu ? <ContextMenus postion={postion} /> : ""}</div>
       
        </>
    );

}
export default ContextMenuRender;