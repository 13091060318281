import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../util/AppContext';
import MenuItem from './MenuItems';

const Navigations = () => {
  const context = useContext(AppContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isParentExpanded, setIsParentExpanded] = useState('');
 const [isLoaded, setIsLoaded] = useState(false);
 const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [expandedItem, setExpandedItem] = useState([{
    menuId:'',
    isExpanded:false
  }]);
  const [expandItem, setExpandItem] = useState([])
  useEffect(() => {
    //setChildrens();
    //console.log(context[0].navigationExpand)
    setIsExpanded(context[0].navigationExpand)
    }, [context.navigationExpand]);
   

    const setWindowDimensions = () => {
        setWindowHeight(window.innerHeight)
    }
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, []);


  const logout = (e) => {
    localStorage.removeItem('AuthCacheKey');
    localStorage.removeItem('OrgId');
    window.location.href='/login';
  }
  const expandMeu = (e) => {
    setIsExpanded(!isExpanded);
    if (!isExpanded){
      document.getElementById('contentPane').classList.add('pl-content');
      document.getElementById('contentPane').classList.remove('pl-default-content');
    }
    else{
      document.getElementById('contentPane').classList.remove('pl-content');
      document.getElementById('contentPane').classList.add('pl-default-content');
    }
  }
  const onMouseEvent = (e) => {
    //console.log(isExpanded);
    if (e) {
      if (!isExpanded) {
        document.getElementById('contentPane').classList.add('pl-content');
        document.getElementById('contentPane').classList.remove('pl-default-content');
      } else {
        document.getElementById('contentPane').classList.add('pl-content');
        document.getElementById('contentPane').classList.remove('pl-default-content');
      }
    } else {
      if (!isExpanded) {
        document.getElementById('contentPane').classList.remove('pl-content');
        document.getElementById('contentPane').classList.add('pl-default-content');
      } else {
        document.getElementById('contentPane').classList.add('pl-content');
        document.getElementById('contentPane').classList.remove('pl-default-content');
      }
    }
  }

  const getMenuNavigations=()=>{
    let urlContent=window.location.href.split('/');
    let _menuItemName = '';
    //console.log(urlContent)
    if (urlContent[urlContent.length - 1].toLocaleLowerCase().includes('dashboard') || urlContent.indexOf('main') > -1) {
      _menuItemName = 'dashboard';
    } else {
      _menuItemName = 'pages';
    }
    let isAdmin = atob(localStorage.getItem('msal.auth.role')) == 1;
    if (_menuItemName === 'dashboard') {
      if (isAdmin) {        
        return MenuItem.dashboard.filter(x => x.allowedRoles.indexOf('admin') > -1);
      } else {
        return MenuItem.dashboard.filter(x => x.allowedRoles.indexOf('user') > -1);
      }
    }   
    return MenuItem[_menuItemName];
  }
  const getMenuItemByRole=(list)=>{
    let isAdmin = atob(localStorage.getItem('msal.auth.role')) == 1;
    let roleName=isAdmin?'admin':'user';
    return list.filter(l => l.allowedRoles.indexOf(roleName)>-1);
  }

  const getBottomMenu = () => {
    let prtalRole = localStorage.getItem('portalRole');
    var _portalRole = '';
    if (prtalRole) {
      _portalRole = atob(prtalRole);
    }
    let urlparams = window.location.href.split('/');
    let finalURL = urlparams[urlparams.length - 1].split('?')[0];
      if (_portalRole.includes('companyowner') || _portalRole.includes('companyadministrator')) {
      finalURL = 'dashboard';
    } else {
      if (urlparams.indexOf('main') > -1) {
        finalURL = 'dashboard';
      }
      if (urlparams.indexOf('template') > -1) {
        finalURL = 'pages';
      }
    }
    let finalMenu = MenuItem.bottom.filter(x => x.parent === finalURL?.toLocaleLowerCase())
    return finalMenu;
  }
  const redirectLink = (url) => {
    if (url)
      window.location.href = url
  }
  const redirectParentLink = (url, r) => {
    if (r.children && r.children.length > 0) {

      var __expandItem = expandItem;
      __expandItem[r.menuId] = !expandItem[r.menuId];

      for (let index = 0; index < r.children.length; index++) {
        document.getElementById(`${r.menuId}-${index}`).className = (expandItem[r.menuId] ? 'hidesubmenu' : 'showsubmenu');
      }
      if (!__expandItem[r.menuId]) {
        document.getElementById(`${r.menuId}-icon`).getElementsByClassName('submenu')[0].classList.remove('fa-caret-right');
        document.getElementById(`${r.menuId}-icon`).getElementsByClassName('submenu')[0].classList.add('fa-caret-down');
      } else {
        document.getElementById(`${r.menuId}-icon`).getElementsByClassName('submenu')[0].classList.add('fa-caret-right');
        document.getElementById(`${r.menuId}-icon`).getElementsByClassName('submenu')[0].classList.remove('fa-caret-down');
      }
      setExpandItem(__expandItem);
      return;
    }
    if (url)
      window.location.href = url
  }

  const getsubMenuIcon = (r) => {
    try {
      if (r.children && r.children.length > 0) {
        if (expandedItem) {
          let isExpanded = expandedItem instanceof Array && expandedItem.filter(x => x.menuId === r.menuId);
          return isExpanded.length > 0 ? isExpanded[0].isExpanded : false;
      }
    }
  }catch(e){
    console.log(e)
  }
  }
    const setChildrens = () => {
      if(isLoaded){
        return;
      }
      let menuTe=[];
      let _expandItem=[]
      getMenuNavigations().map((route, index) => {
        //console.log(index)
      let item={
        menuId:route.menuId,
        isExpanded:false
      }
      _expandItem[route.menuId]=false;
      menuTe.push(item)
      });
      setExpandedItem(menuTe);
      setIsLoaded(true)
      setExpandItem(_expandItem);
  }
  const childrens = (route) => {
    var resp;
    if (route.children) {
        resp = (getMenuItemByRole(route.children).map((r, index) => {
          return (
            <li id={`${route.menuId}-${index}`} contenteditable={`true`} key={index} className='hidesubmenu'>
              <div className='menu-items' onClick={e => redirectLink(r.redirect)}>
                <i className={`fa-2x ${r.cssClass}`}></i>
                <span className="nav-text">
                  {r.menuName}
                </span>
              </div>
            </li>
          )
        })
        )
      return (<ul>{resp}</ul>)
    }
  }

  return (
    <div>
      {setChildrens()}
      <nav id='nav-main-menu' className='sidebar' >
        <div id='main-ul' style={{height:`${windowHeight-90}px`}}>
          <ul >
            {getMenuNavigations().map((route, index) => {
              return (<li key={index} className={route.isActive && !route.children ? 'active' : ''} onClick={e => redirectParentLink(route.redirect, route)}>
                <div style={{ display: 'flex' }}><div className='menu-items' >
                  <i className={`fa-2x ${route.cssClass}`}></i>
                  {route.menuName}
                </div>
                  {/* {route.children && <div id={`${route.menuId}-icon`} style={{ width: '10%' }}>
                    <i className={getsubMenuIcon(route) ? 'fal fa-caret-down fa-2x submenu' : 'fal fa-caret-right fa-2x submenu'}></i>
                  </div>} */}
                </div>
                {/* {childrens(route)} */}

              </li>
              )
            })}
          </ul></div>
        <div>
          <ul className="logout">
            {getBottomMenu().map((route, index) => {             
              return (<li key={`bt-${index}`} className='last'>
                <div className='menu-items' onClick={e => redirectLink(route.redirect)}>
                  <i className={`fa-2x logout-icon ${route.cssClass}`}></i>
                  <span className="nav-text logout-span">
                    {route.menuName}
                  </span>
                </div>

              </li>);
            })}
          </ul></div>
      </nav>
    </div>
  );
}
export default Navigations;