import Axios from "axios";
import React, { useState } from "react";
import { API_ROUTE, Routes } from "../../../util/constants";

const detailsService = () => {
   
    const getCloudCoverBackupDetails = async (orgID) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/ccbackup/${orgID}/details`);
    }
    const getCloudCoverLocalDetails = async (orgID) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/cclocal/${orgID}/details`);
    }
    const getReplicationDetails = async (orgID) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/replication/${orgID}/details`);
    }
    const getBackServerDetails = async (orgID) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/backup/service/${orgID}/details`);
    }
    const getOrgDetails=async (orgId)=>{
        return await Axios.get(`${API_ROUTE}${Routes.GET_AUTH}/GetOrgDetail/${orgId}`);
    }
    const getLocalCredentials=async (orgId,isReseller)=>{
        return await Axios.get(`${API_ROUTE}${Routes.GET_AUTH}/GetLocalCredentials/${isReseller}/${orgId}`);
    }
    const postUserData=async(_requestObj,cmpId)=>{
        return await Axios.post(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/Company/${cmpId}/Update`,_requestObj ); 
    }
    const getPriceData=async(cmpId)=>{
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/GetPriceRates/${cmpId}`); 
    }
    const updateUserDetails=async (_postData)=>{
        return await Axios.post(`${API_ROUTE}${Routes.GET_AUTH}/UpdateUserDetails`,_postData);
    }
    const getUserDetails = async (userId) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/GetUserDetails/${userId}`);
    }
    const getCloudCover365Details=async (vboId,signal)=>{
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/GetcloudCover365/${vboId}/details`,{signal:signal});
    }
    const sendEmail = async (emailId) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/SendEmail/${emailId}`);
    }
    const GetSpaceUsed = async (orgId,orgName) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/SpaceUsed?orgId=${orgId}&orgName=${orgName}`);
    }
    const getSiteCount365=async (vboId,signal)=>{
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/GetSite365Count?orgId=${vboId}`,{signal:signal});
    }
    const getTeamsCount365=async (vboId,signal)=>{
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/GetTeams365Count?orgId=${vboId}`,{signal:signal});
    }
    const GetLastRunDate = async (orgId,orgName) => {
        return await Axios.get(`${API_ROUTE}${Routes.GET_RESELLER_DETAILS}/GetLastRunDate?orgId=${orgId}&orgName=${orgName}`);
    }

    
    return {
        getCloudCoverLocalDetails,
        getCloudCoverBackupDetails,
        getReplicationDetails,
        getBackServerDetails,
        getOrgDetails,
        postUserData,
        getPriceData,
        getLocalCredentials,
        updateUserDetails,
        getCloudCover365Details,
        getUserDetails,
        sendEmail,
        GetSpaceUsed,
        getSiteCount365,
        getTeamsCount365,
        GetLastRunDate
    }
}
export default detailsService;