import Chart from "react-apexcharts";
import { Component } from "react";
  export class CC365Charts extends Component{
    constructor(props) {
      super(props);

      this.state = {
      
        series: [props.usage],
        options: {
          chart: {
            height: 250,
            type: props.chartType, //'radialBar'
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
               hollow: {
                margin: 0,
                size: '70%',
                background: '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }
              },
              track: {
                background: '#fff',
                strokeWidth: '67%',
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35
                }
              },
          
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: '#888',
                  fontSize: '15px'
                },
                value: {
                  formatter: (val)=> {
                    return `${props.dataPoints.used}${props.dataPoints.unit}`;
                  },
                  color: '#111',
                  fontSize: '25px',
                  show: true,
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#1159a3'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          title: {
            text: props.label,
            align: 'center',
            style: {
              fontSize:  '10px',
              fontWeight:  'bolder',
              color:  '#1159a3'
            },
          }, tooltip: {
            enabled: true,
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return `<div class="tooltip-chart"><span>${props.dataPoints.used} ${props.dataPoints.unit}</span> <span>out of</span> <span>${props.dataPoints.quota} ${props.dataPoints.unit}</span></div>`
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: ['Used'],
        },


      };
    }



    render() {
      return (
        

  <div id="card">
<div id="chart">
<Chart options={this.state.options} series={this.state.series} type="radialBar" height={250} />
</div>
</div>
          );
        }
      }
