import React from 'react';
import dashboardservice from '../service/dashboard.service';
import { useEffect } from 'react';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import '../Features/ag-grid-header.css';
import CustomfloatingFilter from '../Features/floatingFilter';
import LinkCellRender from '../Features/linkcell.render';
import Refresh from './Refresh';

const LicenceSummary = (props) => {
    const [columnDef, setColumnDef] = useState([]);
    const [rowData, setRowData] = useState({})
    const [gridAPI, setGridAPI] = useState();
    const [resellerName, setResellerName] = useState('')
    const _service = dashboardservice();
    useEffect(() => {
        const controller = new AbortController();
        //getWorloadData();
        var _reseller = localStorage.getItem('rsName');
        setResellerName(atob(_reseller));
        return () => {
            controller.abort();
        }
    }, [props]);
    const getWorloadData = async (p) => {
        overlayProgress(true);
        let _WSData = await _service.getLicSummary()
            .then(response => {
                return response.data;
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        overlayProgress(false);
        updateColumnDef(_WSData);
        setTimeout(() => {
            p.setRowData(_WSData);            
            p.setGroupHeaderHeight(50);
            p.setHeaderHeight(120);
        }, 300);

    }
    const overlayProgress = (show) => {
        if (show)
            document.getElementById('main-div-form-elements').style.display = 'block';
        else
            document.getElementById('main-div-form-elements').style.display = 'none';
    }
    const updateColumnDef = (d) => {
        var colDef = [
            { field: 'reseller', maxWidth: 350, headerName: 'Reseller',headerClass:'regular-header'
            ,floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'reseller',
                timeOut:3000
              }
        },
            {
                field: 'name', minWidth: 250, maxWidth: 350, headerName: 'Company',headerClass:'regular-header', cellRenderer: 'link', cellRendererParams: {
                    fieldName: 'name',
                    keyId: 'instanceUid'
                }
                ,floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    fieldName:'name',
                    timeOut:3000
                  }
            },
            { field: 'edition', maxWidth: 350, headerName: 'Edition',headerClass:'regular-header',floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'edition',
                timeOut:3000
              }},
            { field: 'package', maxWidth: 350, headerName: 'Package' ,headerClass:'regular-header',floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'package',
                timeOut:3000
              }},
            { field: 'autoUpdateEnabled', maxWidth: 350, headerName: 'Auto',cellRenderer:statusCell ,headerClass:'regular-header' ,floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'autoUpdateEnabled',
                timeOut:3000
              }},
            { field: 'expiration', maxWidth: 350, headerName: 'Expiration',headerClass:'regular-header',floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'expiration',
                timeOut:3000
              } },
            { field: 'model', maxWidth: 350, headerName: 'Model' ,headerClass:'regular-header',floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'model',
                timeOut:3000
              }},
            { field: 'status', maxWidth: 350, headerName: 'Status',headerClass:'regular-header',floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'status',
                timeOut:3000
              }},
            { field: 'ltype', maxWidth: 350, headerName: 'Type',headerClass:'regular-header' ,floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                suppressFilterButton: true,
                fieldName:'ltype',
                timeOut:3000
              }},
            { field: 'qty', maxWidth: 350, headerName: 'Qty' },
            { field: 'used', maxWidth: 350, headerName: 'Used'},            
            { field: 'capacity', maxWidth: 350, headerName: 'Capacity'},
            { field: 'usedCapacity', maxWidth: 350, headerName: 'Used Capacity'},
        ];
        setColumnDef(colDef);
    }
    const statusCell = (p) => {
        if (p.value) {
            return '<div style="color:blue"><i class="fa fa-check-circle-o" aria-hidden="true"></i></div>'
        } else {
            return '<div style="color:blue"><i class="fa fa-times-circle-o" aria-hidden="true"></i></div>'
        }
    }

    const printPreview=(e)=>{
        // console.log(e)
        // window.print();
        const api = gridAPI;
        setPrinterFriendly(api);
        setTimeout(function () {
            window.print();
        }, 2000);
       }
       const setPrinterFriendly=(api)=> {
        const eGridDiv = document.querySelector('#alarms-grid');
        eGridDiv.style.width = '';
        eGridDiv.style.height = '';
        api.setDomLayout('print');
      }
       
       const exportCSV=(e)=>{
        gridAPI.exportDataAsCsv();
       }

    const onGridReady = (params) => {
        setGridAPI(params.api);
        getWorloadData(params.api);
    };
    const defaultColDef = {
        editable: false,
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true
    };
    const style = {
        width: '99%',
        height: '100%'
    };
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };
    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    const rowHeight = 25;
    const [clientStyle, setClientStyle] = useState({
        borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", borderCollapse: "collapse",
        height: `${window.innerHeight - 155}px`,
    });
    
    const headerHeight = 230;
    return (
        <div>
            <div className='left-function display-header-function'>
            <div style={{width:'30px'}} className='cursor' onClick={e=>printPreview(e)}>
          <i className="fa fa-solid fa-print" ></i>
          </div>
          <div>
          <i className=" fa-solid fa-q"></i>  
          </div>
          <div onClick={e=>exportCSV(e)} className='cursor'>          
          <i className="fa fa-solid fa-download" ></i>
          </div>
          </div>
            <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
            <Refresh resellerId={resellerName}></Refresh>
            <div className="ag-theme-alpine workload" id='alarms-grid' style={clientStyle} >
                <AgGridReact style={style}
                    columnDefs={columnDef}
                    defaultColDef={defaultColDef}
                    getRowStyle={getRowStyle}
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationAutoPageSize={true}
                    frameworkComponents={{ 'link': LinkCellRender, 'floating': CustomfloatingFilter }}
                    paginationPageSize={5}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    overlayLoadingTemplate={
                        '<div class="" style="margin: 7em"></div> <span class="ag-overlay-loading-center " style="font-size: 18px; z-index: 100000"></span>'
                    }
                    overlayNoRowsTemplate={
                        '<span className="ag-overlay-loading-center">No Licence summary data</span>'
                    }
                    suppressScrollOnNewData={true} >
                </AgGridReact></div>
        </div>
    )
}

export default LicenceSummary