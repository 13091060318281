import React,{ useContext } from 'react';
import { useEffect } from 'react';
import { AppContext } from '../../../util/AppContext';

const Refresh = (props) => {
    const context = useContext(AppContext);
    useEffect(() => {
        context[0].headerName=props.resellerId;
        context.headerName=props.resellerId;
        document.getElementById('siteHeader').innerHTML = props.resellerId;
    }, [props.resellerId])
    
    const updateHeaderTitle = () => {     
        context.headerName=props.resellerId;
    }
    return (
        <div>{updateHeaderTitle()}</div>
    );
}
export default Refresh;