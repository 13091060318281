import { useState, useRef } from 'react';
import React from 'react';
import Tippy from '@tippyjs/react';
import './contextmenu.css';
const PopupCellRenderer = (props) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div className="menu-container" id='menu-container' onContextMenu="return false;">
      <div onClick={() => onClickHandler('download')} className="menu-item">
      <i className="fa fa-solid fa-download"></i>
        <span>Export</span>
      </div>
      <div onClick={() => onClickHandler('print')} className="menu-item">
      <i className="fa-sharp fa fa-print"></i>
      <span>Print</span>
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }
    if (option === 'download') {
      props.api.exportDataAsCsv();
    }
    if (option === 'print') {
      window.print();
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  const getData = (f) => {
    console.log(f)
    var rowNode = props.api.getRowNode(props.node.id);
    //var data= props.api.getDisplayedRowAtIndex(props.rowIndex).data;
    var data = rowNode.data;
    return data[f];
  }
  const redirectPage = () => {
    window.localStorage.setItem('pageHeader', getData(props.fieldName));
    window.localStorage.setItem('clientOrgId', getData(props.keyId));
    window.location.href = `/pages?orgId=${getData(props.keyId)}&pagename=company&name=${getData(props.fieldName)}`;
  }

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right-start"
    >
      <div onContextMenu={(e) => {
        document.addEventListener('menu-container', event => event.preventDefault());
        e.preventDefault();
        setVisible(true);
      }}>
        {props.isLink ? <div className='link-grid' onClick={() => { redirectPage() }}>{getData(props.fieldName)}</div> : props.value}
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
