import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { AppContext } from '../../../util/AppContext';
import CloudCoverBackup from '../../details/component/CloudCoverBackup';
import CloudCoverLocal from '../../details/component/CloudCoverLocal';
import Company from '../../details/component/Company';
import NoDataFound from '../../details/component/NoDataFound';
import Replication from '../../details/component/Replication';
import AlarmsDetails from './AlarmsDetails';
import CloudCover365 from './CloudCover';
import ccri from '../../../images/img/v-cck.png'
import UsersList from '../../details/component/users';

const DetailsPage = (props) => {
    const context = useContext(AppContext);
    const urlParams = new URLSearchParams(window.location.search);
    const orgId = urlParams.get("orgId") ? urlParams.get("orgId") : "";
    const pageName = urlParams.get("pagename") ? urlParams.get("pagename") : "";
    const Cname = urlParams.get("name") ? urlParams.get("name") : "";
    const getCompanyName = (id, dataSet) => {
        var selItems = dataSet.filter(x => { return x[props.selectedKeyVal] === id });
        return selItems.length > 0 ? selItems[0].name : '';
    }
    const getResellerName = (id, dataSet) => {
        var selItems = dataSet.filter(x => { return x[props.selectedKeyVal] === id });
        return selItems.length > 0 ? selItems[0].name : '';
    }
    const setHeaderName = () => {
        context.headerName = Cname; context[0].headerName = Cname;
        // console.log(context.headerName)
        // console.log(context[0].headerName)
        // console.log(context[0].headerName)
    }

    const getDetailsByPage = (pagename) => {
        // let filtered = allItems.filter(x => {
        //     return x.key === pagename;
        // });
        // return filtered;
        return '';
    }
    const theme = "";

    const generateElement = (e) => {
        let urlParamsParent = new URLSearchParams(window.location.search);
        let orgIds = urlParamsParent.get("orgId");
        let cmpNames=urlParamsParent.get("name");
        localStorage.setItem('pageHeader',cmpNames);
        localStorage.setItem('clientOrgId', orgIds);
        setHeaderName();
        switch (e) {
            case "company": return (               
                <Company Cname={Cname} orgId={orgId}></Company >
            );
            case "ccb": return (
                <CloudCoverBackup></CloudCoverBackup >
            );
            case "ccl": return (
                <CloudCoverLocal OrgId={orgId}></CloudCoverLocal >
            );
            case "ccr": return (
                <Replication OrgId={orgId}></Replication >
            );
            case "users": return (
                <UsersList cmpId={orgId}></UsersList >
            );
            case "cck8s": return (
                <NoDataFound
                header={'Are you protecting your kubernetes?'}
                content={`<div><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left;margin-bottom:14.0pt"><span lang="en-US" style="">The need for quick container-based deployment and mobility means that Kubernetes is fast becoming the tool of choice. Leverage this growth and offer complete Kubernetes protection.</span> </p><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left;margin-bottom:14.0pt"><span lang="en-US" style="">Although one of the fastest-growing open-source technologies in the world, while Kubernetes provides the high availability and scalability of application services, these benefits do not extend to the organisation’s data.</span> </p><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left;"><span lang="en-US" style="">This is leaving enterprise organisations at risk of data loss from application failures, insider attacks, cyber-security threats such as&nbsp;</span><a href="https://www.virtualdcs.co.uk/blog/how-to-protect-yourself-from-ransomware-as-a-service-raas-insider-attacks" target="_blank" rel="noopener noreferrer"><span lang="en-US" style="text-decoration:underline">Ransomware and much more</span></a><span lang="en-US" style="">. You can help protect this critical information and provide application mobility on demand with CloudCover K8s.</span><span lang="en-US"></span> </p><p style="margin-right:0pt;text-indent:0pt;margin-top:0pt;margin-bottom:6pt;line-height:119%;text-align:left;"><span lang="en-US">&nbsp;</span> </p><br> </div>`}
                imgUrl={ccri}
              />
            );
            case "reseller": return (<div><span>Reseller Name:</span><p>{getResellerName(getDetailsByPage('reseller').selectedValue, getDetailsByPage('reseller').details)}</p></div>);
            case "alarms": return (<form>
                <fieldset><AlarmsDetails></AlarmsDetails> </fieldset>
            </form>);
            case "cloud": return (<form>
                <fieldset><CloudCover365 selectedValue={orgId}></CloudCover365> </fieldset>
            </form>);
        }
    }
    return (
        <div>

            {generateElement(pageName)}

        </div>
    )
}

export default DetailsPage