import { List } from "reactstrap";

    const urlParams = new URLSearchParams(window.location.search);
        const orgId = urlParams.get("orgId");
        let cmpName = urlParams.get("name");
        localStorage.setItem('pageHeader', cmpName);
        localStorage.setItem('clientOrgId', orgId);

const getPageRedirect=(pageName)=>{
    var pageh=window.localStorage.getItem('pageHeader');
    var orgId=window.localStorage.getItem('clientOrgId');
    return `/pages?orgId=${orgId}&pagename=${pageName}&name=${pageh}`;
}
const RedirectToUsers=()=>{
    var orgId=window.localStorage.getItem('OrgId');
    return `/main/users/users?orgId=${atob(orgId)}`;
}
const RedirectToSettings=()=>{
    var orgId=window.localStorage.getItem('OrgId');
    return `/main/settings/price`;
}
const RedirectToWorkLoad=()=>{
    var orgId=window.localStorage.getItem('OrgId');
    return `/main/work/workload?orgId=${atob(orgId)}`;
}
const RedirectToLicence=()=>{
    var orgId=window.localStorage.getItem('OrgId');
    return `/main/Licence/Summary`;
}
const getDashboard=()=>{
    var orgId=window.localStorage.getItem('OrgId');
    return `/Dashboard?orgId=${atob(orgId)}`;
}

const PartnerPortalURL=()=>{
    return 'https://partner.virtualdcs.co.uk/';
}

const getActiveClass=(pageName)=>{
    const urlParams = new URLSearchParams(window.location.search);
    if(pageName =='dashboard')
    {
        return window.location.href.toLocaleLowerCase().includes('dashboard');
    }
    let pgname=pageName.split('/')[0];
    if(pgname == 'template'){
        let module=pageName.split('/')[1];
        return window.location.href.toLocaleLowerCase().includes(module)
    }
    if(pgname == 'work'){
        let module=pageName.split('/')[1];
        return window.location.href.toLocaleLowerCase().includes(module)
    }    
    if(pgname == 'settings'){
        let module=pageName.split('/')[1];
        return window.location.href.toLocaleLowerCase().includes(module)
    }
    if(pgname == 'main'){
        let module=pageName.split('/')[1];
        return window.location.href.toLocaleLowerCase().includes(module)
    }
    var urlPageName=urlParams.get("pagename") ? urlParams.get("pagename") : "";
    return pageName.toLocaleLowerCase() === urlPageName.toLocaleLowerCase();
}
const getPageRedirects=(pageName)=>{
    var pageh=window.localStorage.getItem('pageHeader');
    return `${pageName}?name=${pageh}`
}

const DashboardMenuItems = [{
    cssClass: 'statusOverview',
    menuName: 'Status Overview',
    menuId: 'statusOverview',
    redirect:getDashboard(),
    isActive:getActiveClass('dashboard'),
    allowedRoles:['admin','user']
},
{
    cssClass: 'quota',
    menuName: 'Workload Summary',
    menuId: 'quotaSummary',
    redirect:RedirectToWorkLoad(),
    isActive:getActiveClass('main/work'),
    allowedRoles:['admin','user']
},
{
    cssClass: 'Contract',
    menuName: 'Licence Summary',
    menuId: 'contractSummary',
    redirect:RedirectToLicence(),
    isActive:getActiveClass('main/licence'),
    allowedRoles:['admin','user']
}, {
    cssClass: 'Billing',
    menuName: 'Billing',
    menuId: 'billing',
    redirect:'/main/billing',
    isActive:getActiveClass('main/billing'),
    allowedRoles:['admin','user']
}, {
    cssClass: 'Partner',
    menuName: 'Partner Configuration',
    menuId: 'partnerConfiguration',
    redirect:'/main/partner',
    isActive:getActiveClass('main/partner'),
    allowedRoles:['user']
}, {
    cssClass: 'Addcustomer',
    menuName: 'Add Customer',
    menuId: 'addCustomer',
    redirect:'/main/customer',
    isActive:getActiveClass('main/customer'),
    allowedRoles:['user']
}, {
    cssClass: 'company-users',
    menuName: 'Users',
    menuId: 'users',
    redirect:RedirectToUsers(),
    isActive:getActiveClass('main/users'),
    allowedRoles:['user']
},{
    cssClass: 'Addcustomer',
    menuName: 'Add Partner',
    menuId: 'addPartner',
    redirect:'/main/partner',
    isActive:getActiveClass('main/partner'),
    allowedRoles:['admin']
},{
    cssClass: 'adminFunctions',
    menuName: 'Settings',
    menuId: 'adFunctions',
    redirect:RedirectToSettings(),
    isActive:getActiveClass('main/settings'),
    allowedRoles:['admin','user'],
    // children:[
    //     {
    //         cssClass: 'fa fa-circle',
    //         menuName: 'Event Viewer',
    //         menuId: 'eventVwr',
    //         allowedRoles:['admin'],
    //     }, {
    //         cssClass: 'fa fa-circle',
    //         menuName: 'Run batches',
    //         menuId: 'runBatches',
    //         allowedRoles:['admin'],
    //     }, {
    //         cssClass: 'fa fa-circle',
    //         menuName: 'Refresh proxy list',
    //         menuId: 'refeshProxyList',
    //         allowedRoles:['admin'],
    //     }, {
    //         cssClass: 'fa fa-circle',
    //         menuName: 'Stranded bucket list',
    //         menuId: 'strandedBucketList',
    //         allowedRoles:['admin'],
    //     }]
}, {
    cssClass: 'Reports',
    menuName: 'Reports',
    menuId: 'reports',
    redirect:'/main/reports',
    isActive:getActiveClass('main/reports'),
    allowedRoles:['admin','user']
}, {
    cssClass: 'partner-portal',
    menuName: 'Partner Portal Resources',
    menuId: 'partnerportal',
    redirect:PartnerPortalURL(),
    isActive:getActiveClass('main/partner'),
    allowedRoles:['admin','user']
}];

const PagesMenuItems = [{
    cssClass: 'company-info',
    menuName: 'Company Information',
    menuId: 'company-info',
    redirect:getPageRedirect('company'),
    isActive:getActiveClass('company')
},
{
    cssClass: 'company-users',
    menuName: 'Users',
    menuId: 'company-users',
    redirect:getPageRedirect('users'),
    //redirect:getPageRedirect('users'),
    isActive:getActiveClass('template/users'),
},
{
    cssClass: 'comapany-alarms',
    menuName: 'Alarms',
    menuId: 'comapany-alarms',
    redirect:getPageRedirect('alarms'),
    isActive:getActiveClass('alarms')
}, {
    cssClass: 'comapany-ccl',
    menuName: 'CloudCover Local',
    redirect:getPageRedirect('ccl'),
    menuId: 'comapany-ccl',
    isActive:getActiveClass('template/ccl'),
}, {
    cssClass: 'comapany-ccb',
    menuName: 'CloudCover Backup',
    menuId: 'comapany-ccb',
    redirect:getPageRedirect('ccb'),
    isActive:getActiveClass('template/ccb')
}, {
    cssClass: 'comapany-ccr',
    menuName: 'CloudCover Replication',
    menuId: 'comapany-ccr',
    redirect:getPageRedirect('ccr'),
    isActive:getActiveClass('template/ccr'),
}, {
    cssClass: 'comapany-cc365',
    menuName: 'CloudCover 365',
    menuId: 'comapany-cc365',
    redirect:getPageRedirect('cloud'),
    isActive:getActiveClass('cloud')
}, {
    cssClass: 'comapany-cck8s',
    menuName: 'CloudCover K8s',
    menuId: 'comapany-cck8s',
    redirect:getPageRedirect('cck8s'),
    isActive:getActiveClass('cck8s')
}];

const BottomMenuItem = [{
    cssClass: 'fa fa-power-off fa-2x',
    menuName: 'Sign out',
    menuId: 'logout',
    parent:'dashboard',
    redirect:`/logout`
},{
    cssClass: 'fa fa-caret-left fa-2x',
    menuName: 'Back to list',
    menuId: 'back',
    parent:'pages',
    redirect:`/Dashboard?orgId=${atob(localStorage.getItem('OrgId'))}`
}]
const MenuItem =
{
    dashboard: DashboardMenuItems,
    pages:PagesMenuItems,
    bottom:BottomMenuItem
};

export default MenuItem;