import { useState ,useEffect} from "react";
import { Charts } from "../../Shared/components/Charts/Charts";
import detailsService from "../service/details.service";
import '../css/details.css';
import '../../../CSS/Placeholder.css';
import { Spinner } from "reactstrap";
import dashboardservice from "../../dashboard/service/dashboard.service";

const Company = (props) => {
    const [is365Loaded, setIs365Loaded] = useState(false)
    const [resultData, setResultData] = useState({});
    const [oldestActivity, setOldestActivity] = useState('');
    const [statusDone, setStatusDone] = useState(false);
    const [dashboardDone, setDashboardDone] = useState(false);
    const [chartDone, setChartDone] = useState(true);
    const [statusCellValue, setStatusCellValue] = useState({});
    const [managementStatus, setManagementStatus] = useState({
        cssClass:'fa error-icon ',
        textContent:'',
        parentClass:'',
        colorCss:'max-width-card '
    });
    const _service = detailsService();
    const _dService = dashboardservice();
    useEffect(() => {
        const controller = new AbortController();                
        initiateCells();
        getCompanyWise();
        getBackupServerStatus();
    
      return () => {
        controller.abort();
      }
    }, [props.orgId]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const orgId = urlParams.get("orgId");
        let cmpName = urlParams.get("name");
        localStorage.setItem('pageHeader', cmpName);
        localStorage.setItem('clientOrgId', orgId);
    }, []);


   

const initiateCells=()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const orgId = urlParams.get("orgId");
    let cmpName=urlParams.get("name");
    localStorage.setItem('pageHeader',cmpName);
    localStorage.setItem('clientOrgId', orgId);
    var _resultItem=[];
    var _chartColors=['#55A362','#ff0000','#ffa500','grey'];
    _resultItem.push({values: null,chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,header:'General'});
    _resultItem.push({values: null,chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'CC365',link:'cloud'});
    _resultItem.push({values: null,chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'Local',link:'ccl'});
    _resultItem.push({values: null,chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'Off-site',link:'ccb'});
    _resultItem.push({values:null,chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'DraaS',link:'ccr'});
    _resultItem.push({values: null,chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'K8s',link:'cck8s'});
    _resultItem.push({values:null,chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,header:'Oldest activity',isOld:true,dateVal:null});
    setResultData(_resultItem);
}

    const getCompanyWise = async () => {
        var _statusItem = null
        var _Portal = null
        var _PortalRole = localStorage.getItem('portalRole');
        if (_PortalRole) {
            _Portal = atob(_PortalRole);
        }
        if (_Portal && (_Portal.includes('companyowner') || _Portal.includes('companyadministrator'))) {
            _statusItem = await _dService.getCompanyDashboardData(props.orgId).then(
                response => {
                    if ((typeof response == "string" || response instanceof String) && response.includes('ServerPath')) {
                        return null;
                    }
                    let selectItems = response.data.dashboardGroupdata;
                    return selectItems;
                })
                .catch((reason) => {
                    console.log("Error occured while fetch company wise data")
                });
        } else {
            var _localData = localStorage.getItem('cc365.dashboard');
            if (_localData) {
                _statusItem = JSON.parse(atob(_localData));
            }
        }


        //localStorage.getItem('cc365.dashboard')
        if (_statusItem) {
            var statusData = _statusItem;
            var is365Loaded=localStorage.getItem('is365Loaded');
            if(is365Loaded && is365Loaded == '1'){
                setIs365Loaded(true);               
            }
            var finalData = statusData.filter(x => {
                //console.log(`${x.companyId} ${props.orgId}`)
                return x.companyId == props.orgId
            })[0];
            //console.log(finalData)
            var _oldestActiviy = finalData.lastfinished??'';
            _oldestActiviy=_oldestActiviy.replace('-','/').replace('-','/');
            var _chartColors=['#55A362','#ff0000','#ffa500','grey'];
            var _resultItem=[];
            _resultItem.push({values: getchartValues('general', finalData),chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,header:'General'});
            if (is365Loaded) {
                _resultItem.push({ values: getchartValues('cC365', finalData), chartType: 'pie', lables: ['Success', 'Error', 'Warning', ''], colors: _chartColors, width: 80, isLink: true, header: 'CC365', link: 'cloud' });
            }
            else {
                _resultItem.push({ values: null, chartType: 'pie', lables: ['Success', 'Error', 'Warning', ''], colors: _chartColors, width: 80, isLink: true, header: 'CC365', link: 'cloud' });
                setChartDone(false);
            }
            _resultItem.push({values: getchartValues('local', finalData),chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'Local',link:'ccl'});
            _resultItem.push({values: getchartValues('offSite', finalData),chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'Off-site',link:'ccb'});
            _resultItem.push({values: getchartValues('draas', finalData),chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'DraaS',link:'ccr'});
            _resultItem.push({values: getchartValues('k8s', finalData),chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,isLink:true,header:'K8s',link:'cck8s'});
            _resultItem.push({values: getchartValues('general', finalData),chartType: 'pie',lables: ['Success', 'Error', 'Warning',''],colors:_chartColors,width: 80,header:'Oldest activity',isOld:true,dateVal:_oldestActiviy});
            setResultData(_resultItem);
        }
        setDashboardDone(true);
        if(!is365Loaded)
        var a = get365ChartData(_resultItem);
        
    }

const get365ChartData=async(oldData)=>{
    //setDashboardDone(false);
    let chart365data=await _dService.get365ChartData(props.orgId)
    .then(r =>{
        return r.data;
    })
    .catch((reason) => {
        console.log("Error occured while getting chart data");
    });
    let dummyValue = {
        cC365Error: 0,
        cC365NA: 0,
        cC365Success: 0,
        cC365Warning: 0
    }
        var _resultSet=oldData;
       for (let index = 0; index < _resultSet.length; index++) {
        const element = _resultSet[index];
        if(element.header == 'CC365'){
            if(chart365data){
            _resultSet[index].values=getchartValues('cC365', chart365data.org365Data)
            }else{                
                _resultSet[index].values=getchartValues('cC365', dummyValue)
            }
        }
       setResultData(_resultSet);
     setChartDone(true);
    }
}

    const getBackupServerStatus = async () => {
        let _serverData = await _service.getBackServerDetails(props.orgId)
            .then(response => {
                return response.data;
            })
            .catch((reason) => {
                console.log("Error occured while geting the server status");
            });
            setStatusDone(true);
        //console.log(_serverData)
        if (_serverData && _serverData.length > 0) {
           // console.log(_serverData)
            let _serverStatus=_serverData[0].status.toString().toLowerCase();
            switch (_serverStatus) {
                case 'inaccessible':
                    setManagementStatus({
                        cssClass:'fa fa-exclamation-circle',
                        parentClass:'warning-icon',
                        colorCss:'warning-company max-width-card',
                        textContent:`Your Veeam Backup and
                        Replication server is disconnected from our service, please re-connect this as
                        soon as possible. If you require assistance, please contact our support team at <a href="mailto:servicedesk@virtualdcs.co.uk">servicedesk@virtualdcs.co.uk</a>`
                    });
                    break;
                    case 'healthy':
                        setManagementStatus({
                            cssClass:'fa fa-check-circle',
                            parentClass:'success-icon',
                            success:'success max-width-card',
                            textContent:``
                        });
                        break;
            
                default:
                    setManagementStatus({
                        cssClass:'fa fa-times-circle',
                        parentClass:'error-icon',
                        colorCss:'no-found max-width-card',
                        textContent:`Your Veeam Backup and Replication server has not been
                        configured to be part of our managed service. For us to provide support and to
                        comply with our license agreement this MUST be completed. If you require
                        assistance, please contact our support team at <a href="mailto:servicedesk@virtualdcs.co.uk">servicedesk@virtualdcs.co.uk</a>`
                    });
                    break;
            }
        }else{
            setManagementStatus({
                cssClass:'fa fa-times-circle ',
                parentClass:'error-icon',
                colorCss:'no-found max-width-card',
                textContent:`Your Veeam Backup and Replication server has not been
                configured to be part of our managed service. For us to provide support and to
                comply with our license agreement this MUST be completed. If you require
                assistance, please contact our support team at <a href="mailto:servicedesk@virtualdcs.co.uk">servicedesk@virtualdcs.co.uk</a>`
            });
        }

    }
    
   const getStatusByPriority = (module, p) => {
        var flag = false;

        if (module == 'k8s') {
            flag = true;
        }

        let sucessVal = p[`${module}Success`];
        let warningVal = p[`${module}Warning`];
        let errorVal = 0;
        if (flag) {
            errorVal = p[`${module}SError`];
        } else {
            errorVal = p[`${module}Error`];
        }
        return errorVal > 0 ? 5 : (warningVal > 0 ? 4 : (sucessVal > 0 ? 3 : 0));
    }

    const getCellValues = (module, p) => {
        var flag = false;

        if (module == 'k8s') {
            flag = true;
        }

        let sucessVal = p[`${module}Success`];
        let warningVal = p[`${module}Warning`];
        let errorVal = 0;
        if (flag) {
            errorVal = p[`${module}SError`];
        } else {
            errorVal = p[`${module}Error`];
        }
        return (<div className="customTooltiptext">
            <span>Success : {sucessVal}</span><br />
            <span>Error : {errorVal}</span><br />
            <span>Warning : {warningVal}</span>
        </div>)
    }
const statusCell=(val,m,p)=>{
    if (val < 3) {
        val = getStatusByPriority(m,p);
    }
    val = val < 1 ? 0 : val;
    let result = (<div className="alignSpan-center customTooltip"><div className="square none "><i className="fa-sharp fa-solid fa-circle-ellipis"></i>{getCellValues(m,p)}</div></div>)
    switch (val) {
        case 3:
            result = (<div className="alignSpan-center customTooltip"><div className="square success"><i className="fa-sharp fa-solid fa-circle-check"></i>{getCellValues(m,p)}</div></div>);
            break;
        case 4:
            result = (<div className="alignSpan-center customTooltip"><div className="square warning blink_me"><i className="fa-sharp fa-solid fa-circle-check"></i>{getCellValues(m,p)}</div></div>);
            break;
        case 5:
            result = (<div className="alignSpan-center customTooltip"><div className="square failed blink_me"><i className="fa-sharp fa-solid fa-circle-xmark"></i>{getCellValues(m,p)}</div></div>);
            break;
        case 0:
            result = (<div className="alignSpan-center customTooltip"><div className="square na"><i className="fa-sharp fa-solid fa-circle-ellipis"></i>{getCellValues(m,p)}</div></div>);
            break;
        case 2:
            result = (<div className="alignSpan-center customTooltip"><div className="square running"><i className="fa-sharp fa-solid fa-circle-info"></i>{getCellValues(m,p)}</div></div>);
            break;
        case 1:
            result = (<div className="alignSpan-center customTooltip"><div className="square none"><i className="fa-sharp fa-solid fa-circle-half-stop"></i>{getCellValues(m,p)}</div></div>);
            break;
        default:
            break;
    }
    return result;
}

const getchartValues = (module, p) => {
    var flag = false;
    if (module == 'k8s') {
        flag = true;
    }
    let sucessVal = p[`${module}Success`];
    let warningVal = p[`${module}Warning`];
    let errorVal = 0;
    if (flag) {
        errorVal = p[`${module}SError`];
    } else {
        errorVal = p[`${module}Error`];
    }
    
    return [sucessVal,errorVal, warningVal,  (sucessVal == 0 && warningVal == 0 && errorVal == 0 ? 1 : 0)];
}

const onManageClick=(e,page)=>{
    var pageh=window.localStorage.getItem('pageHeader');
    var orgId=window.localStorage.getItem('clientOrgId');
    var _link= `/pages?orgId=${orgId}&pagename=${page}&name=${pageh}`;
    window.location.href=_link;
}

    return (
    <div>    
            <div className="grid-container" style={{ paddingTop: '50px' ,width:'100%', padding:'1% 30px 0 30px'}}>
                <div key={10} className="grid-item">
                    <span className="chart-header">Management Status</span>
                    {statusDone && <div className="company-server-status" style={{paddingTop:'10px'}}>
                        <div className={managementStatus.parentClass}>
                            <i className={managementStatus.cssClass} ></i>
                        </div>
                    </div>}
                    {!statusDone && <div className="loader-chart">
                        <div className="lds-company"><div></div><div></div><div></div></div>
                    </div>}
                </div>
                {resultData.length > 0 && resultData.map((item, i) => {
                    return (
                        <div key={i} className="grid-item">
                            <span className="chart-header">{item.header}</span>
                           {(dashboardDone && (chartDone || item.header != 'CC365') && (item.values != null) )  && <>
                            {!item.isOld &&<Charts chart={item} />}
                            {item.isOld &&<div className="old-activity">
                                <div><i className="fa fa-calendar" aria-hidden="true" style={{fontSize:'100px',color:'grey !important'}}></i></div>
                                <span>{item.dateVal}</span>
                            </div>}
                            {item.isLink && <>
                            <button className="btn btn-outline-primary" onClick={e=>{return onManageClick(e,item.link);}}>Manage</button>
                            </>}
                            </>}
                            {!(dashboardDone && (chartDone || item.header != 'CC365') && (item.values != null)) && <div className="loader-chart">
                        <div className="lds-company"><div></div><div></div><div></div></div>
                    </div>}                   
                        </div>
                        )
                    })}                    
                </div>
{!statusDone && <div id="manage-no-data"><div className="loader-chart">
                        <div className="lds-company"><div></div><div></div><div></div></div>
                    </div></div>}
            {statusDone && <div className="management-status">             
                <div className="company-server-status-p">
                    <div className="company-server-status">
                        <div className={managementStatus.colorCss}>
                            <span>
                                <div dangerouslySetInnerHTML={{ __html: managementStatus.textContent }}>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>


            </div>}
        </div>);
}

export default Company;