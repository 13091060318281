import { Counter } from "./components/Counter";
import { DashBoard } from "./components/dashboard/components/dashboard";
import DetailsPage from "./components/dashboard/components/DetailsPage";
import LicenceSummary from "./components/dashboard/components/LicenceSummary";
import WorkloadSummary from "./components/dashboard/components/WorkloadSummary";
import CloudCoverBackup from "./components/details/component/CloudCoverBackup";
import PriceDetails from "./components/details/component/PriceDetails";
import UsersList from "./components/details/component/users";
import ErrorPage from "./components/Error";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import {Login}  from "./components/login";
import LoginSSO from "./components/LoginSSO";
import Logout from "./components/logout";
import { ResetPassword } from "./components/ResetPassword";
import ComingSoon from "./components/Shared/components/ComingSoon/ComingSoon";
import Sample from "./components/signupWizard/SignupWizard";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
    importPath:'../components/Home'
  },
  {
    path:'/login',
    element:<Login />,
    importPath:'../components/login'
  },
  {
    path:'/Dashboard',
    element:<DashBoard />,
    importPath:''
  },
  {
    path: '/counter',
    element: <Counter />,
    importPath:'../components/Home'
  },
  {
    path: '/ACS',
    element: <FetchData />,
    importPath:'../components/Home'
  }
  ,
  {
    path: '/pages',
    element: <DetailsPage />,
    importPath:'../components/Details'
  },
  {
    path: '/error',
    element: <ErrorPage />,
    importPath:'../components/Details'
  },
  {
    path: '/loginSSO',
    element: <LoginSSO />,
    importPath:'../components/Details'
  },
  {
    path: '/logout',
    element: <Logout />,
    importPath:'../components/Details'
  },
  {
    path: '/template/*',
    element: <ComingSoon />,
    importPath:'../components/Details'
  },
  {
    path: '/main/work/*',
    element: <WorkloadSummary />,
    importPath:'../components/Details'
  },
  {
    path: '/main/Licence/*',
    element: <LicenceSummary />,
    importPath:'../components/Details'
  },
  {
    path: '/main/users/*',
    element: <UsersList />,
    importPath:'../components/Details'
  },
  {
    path: '/main/*',
    element: <ComingSoon />,
    importPath:'../components/Details'
  },
  {
    path: '/test',
    element: <CloudCoverBackup />,
    importPath:'../components/Details'
  },
  {
    path: 'main/settings/*',
    element: <PriceDetails />,
    importPath:'../components/Details'
  },
  {
    path: '/login/reset',
    element: <ResetPassword />,
    importPath:'../components/'
  },
  {
    path: '/reset',
    element: <ResetPassword />,
    importPath:'../components/'
  },
  {
    path: '/signup',
    element: <Sample />,
    importPath:'../components/'
  }
];

export default AppRoutes;
