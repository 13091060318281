import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import authService from "../../Shared/Services/AuthService";
import detailsService from "../service/details.service";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UsersList = (props) => {
    const [formUI, setFormUI] = useState({
        passwordType: 'password',
        icon: <FontAwesomeIcon icon={faEyeSlash} />
    });
    const [error, setError] = useState({
        IsError: false,
        Message: '',
        IsSuccess: false,
        IsRunning: false
    })
    const validPassword = new RegExp('^(?=.*?[a-zA-Z])(?=.*?[^a-zA-Z]).{8,}$');
const [isParent, setIsParent] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const [profileInfo, setProfileInfo] = useState({
        IsBackupAdmin: false,
        IsMasterAdmin: true,
        IsViewOnly: false,
        companyId: '',
        companyName: '',
        scopes: [],
        status: '',
        userName: '',
        userUid: ''
    })
    const [userGroup, setUserGroup] = useState({
        Profile: {
            ManagementGroups: {
                BackupAdmin: '',
                MasterAdmin: '',
                ViewOnly: ''
            },
            MicrosoftTenantID: ''
        }
    })

    const [formdata, setFormData] = useState({
        BackupAdmin: '',
        MasterAdmin: '',
        ViewOnly: '',
        MicrosoftTenantID: '',
        Password: ''
    });
    const [oldFormdata, setOldFormData] = useState({
        BackupAdmin: '',
        MasterAdmin: '',
        ViewOnly: '',
        MicrosoftTenantID: '',
        Password: ''
    });
    const _service = detailsService();
    const _authService = authService();
    const orgIdLocal = localStorage.getItem('OrgId');
    useEffect(() => {
        const controller = new AbortController();
        getProfile(props.cmpId)
        return () => {
            controller.abort();
        }
    }, [orgIdLocal, props.cmpId])

    // useEffect(() => {
    //     const controller = new AbortController();
    //     getProfile();    
    //     getUserGroupDetails(props.cmpId)
    //   return () => {
    //     controller.abort();
    //   }
    // }, [props.cmpId])

    const getProfile = async (e) => {
        let urlParams = new URLSearchParams(window.location.search);
        let pageName = urlParams.get("pagename");
        var isParent=pageName != 'users';
        setIsParent(isParent);
                let _serverData = await _authService.GetProfileInformation()
            .then(response => {
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        if (_serverData) {
            setProfileInfo(_serverData);
            if (e) {
                let a = await getLocalCredentials(e);
                let r = await getUserGroupDetails(e);
            } else {
                let a = await getLocalCredentials(_serverData.companyId);
                let r = await getUserGroupDetails(_serverData.companyId);
            }
        }
    }
    const getUserGroupDetails = async (cmpId) => {
        let _grpData = await _service.getOrgDetails(cmpId)
            .then(response => {
                return response.data;
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        setUserGroup(_grpData.additionalNotes);
        if (_grpData.additionalNotes.Profile) {
            var _respData = {
                BackupAdmin: _grpData.additionalNotes.Profile.ManagementGroups.BackupAdmin,
                MasterAdmin: _grpData.additionalNotes.Profile.ManagementGroups.MasterAdmin,
                ViewOnly: _grpData.additionalNotes.Profile.ManagementGroups.ViewOnly,
                MicrosoftTenantID: _grpData.additionalNotes.Profile.MicrosoftTenantID
            }
            setFormData(_respData);
            setOldFormData(_respData);
        }
    }
    const getLocalCredentials = async (cmpId) => {
        overlayProgress(true);
        let urlParams = new URLSearchParams(window.location.search);
        let pageName = urlParams.get("pagename");
        var isParent = pageName != 'users';
        let _grpData = await _service.getLocalCredentials(cmpId, isParent ? 2 : 0)
            .then(response => {
                return JSON.parse(response.data);
            })
            .catch((reason) => {
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        setUserId(_grpData.userUid);
        if (_grpData.identifierInProvider.toLowerCase().includes('\\')) {
            setUserName(`${_grpData.identifierInProvider}`);
        } else {
            setUserName(`${_grpData.companyName}\\${_grpData.identifierInProvider}`);
        }
        overlayProgress(false)
    }

    const saveLocalCredentials = async (e) => {
        setIsDisable(true);
        overlayProgress(true)
        if (!validPassword.test(formdata.Password)) {
            setError({
                IsError: true,
                Message: 'The password provided does not meet the given requirements',
                IsSuccess: false,
                IsRunning: false
            });
            setIsDisable(false);
            overlayProgress(false);
            document.getElementById('btnSubmitLocal').classList.remove('btn-secondary');
            return;
        }
        setError({
            IsError: false,
            Message: 'Password reset failed',
            IsSuccess: false,
            IsRunning: false
        });
        localStorage.removeItem('_retry');
        var postData = {
            Id: userId,
            Value: formdata.Password,
            Path: '/credentials/password',
            Operations: 'replace',
            MethodName: `users/${userId}`
        }
        setFormData(prevState => ({
            ...prevState,
            ['Password']: ''
        }));

        var _response = await _service.updateUserDetails(postData).then(response => {
          
            return response.data;
        })
            .catch((reason) => {
                console.log("postUserData Org" + reason);
            });
        if (_response == 'success') {
            if (formUI.passwordType === "text") {
                togglePassword(e);
            }
            toast.success(`Password reset successful`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            setError({
                IsError: false,
                Message: 'Password reset successful',
                IsSuccess: false,
                IsRunning: false
            });
            setIsDisable(false);
            setIsEdit(false);
        } else if (_response == 'running') {
            toast.info(`Password reset in-progress...`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            localStorage.setItem('_retry', 'test')
            setError({
                IsError: false,
                Message: 'Password reset in-progress',
                IsSuccess: false,
                IsRunning: false
            });
            setIsDisable(false);
            setIsEdit(false);
        } else {
            toast.error(`Password reset failed`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
            setError({
                IsError: false,
                Message: 'Password reset failed',
                IsSuccess: false,
                IsRunning: false
            });
            setIsDisable(false);
        }
        overlayProgress(false);
    }

    const saveUserGroups = async (e) => {
       
        overlayProgress(true)
        var _postData = {
            Profile: {
                MicrosoftTenantID: formdata.MicrosoftTenantID,
                ManagementGroups: {
                    MasterAdmin: formdata.MasterAdmin,
                    BackupAdmin: formdata.BackupAdmin,
                    ViewOnly: formdata.ViewOnly
                }
            }
        };
        var _newPost = { value: btoa(JSON.stringify(_postData)), path: '', op: profileInfo.companyId };
        var _response = await _service.postUserData(_newPost, profileInfo.companyId).then(response => {
         
            return response.data;
        })
            .catch((reason) => {
                console.log("postUserData Org" + reason);
            });
        toast.success(`User details saved successful`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false, theme: 'colored' });
        setIsEdit(false);
        overlayProgress(false);
    }
    const reset = (e) => {
        setIsEdit(false);
        setFormData(oldFormdata);
        setError({
            IsError: false,
            Message: '',
            IsSuccess: false
        });
        setFormData(prevState => ({
            ...prevState,
            ['Password']: ''
        }));
        togglePassword(e);
    }

    const handleChange = e => {
        setError({
            IsError: false,
            Message: '',
            IsSuccess: false
        });
        const { name, value } = e.target;
        const val = e.target.value;
        setFormData(prevState => ({
            ...prevState,
            [name]: val
        }));
    };

    const togglePassword = (e) => {
        if (!((profileInfo.IsMasterAdmin || profileInfo.IsBackupAdmin) && isEdit)) {
            return;
        }
        if (formUI.passwordType === "password") {
            setFormUI({
                passwordType: "text",
                icon: <FontAwesomeIcon icon={faEye} />
            });
            return;
        }
        setFormUI({
            passwordType: "password",
            icon: <FontAwesomeIcon icon={faEyeSlash} />
        });
    }

    const setTextBoxDisable = () => {
        if (isParent) {
            if (profileInfo.IsMasterAdmin && isEdit) {
                if (!isDisable) {
                    return false;
                }
            }
        } else {
            if ((profileInfo.IsMasterAdmin || profileInfo.IsBackupAdmin) && isEdit) {
                if (!isDisable) {
                    return false;
                }
            }
        }
        return true;
    }
    const overlayProgress = (show) => {
        if (show)
            document.getElementById('main-div-form-elements').style.display = 'block';
        else
            document.getElementById('main-div-form-elements').style.display = 'none';
    }
    const getInputValue = (inp) => {
        if (inp)
            return inp
        else
            return '';
    }
    return (<div className="col-lg-12">
        <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
        {(profileInfo.IsMasterAdmin || profileInfo.IsBackupAdmin) && <div className='row col-lg-12'>
            <div id='manageOrgs' className={isEdit ? 'action-button btn-disable' : 'action-button'} >
                <div onClick={e => {
                    if(isParent){
                    if (profileInfo.IsMasterAdmin && !isEdit) {
                        setIsEdit((isEdit) => !isEdit);
                    }
                }else{
                    if ((profileInfo.IsMasterAdmin) && !isEdit) {
                        setIsEdit((isEdit) => !isEdit);
                    }
                }
                }}><i className='fal fa-edit fa-lg m-r-1 cursor' title='Edit Users' ></i></div>
            </div>
        </div>}
        <div className="row col-lg-8 row-form users-form">
            <div className="header-users"><span>Local Credentials</span><div style={{ marginLeft: '30px' }}>
                <span className="error-message">{error.IsError ? error.Message : ''}</span>
                <span className="success-message">{error.IsSuccess ? error.Message : ''}</span>
                <span className="warning-message">{error.IsRunning ? error.Message : ''}</span>
            </div>
            </div>
            <div className="row col-lg-12 row-form">
                <div className='col-lg-4 pt-2'>
                    <label id='lblService' htmlFor='txtuserName'>UserName</label>
                </div>
                <div className='col-lg-8'>
                    <input type="text" style={{ width: '100%' }} value={getInputValue(userName)} disabled id='txtuserName' name="txtuserName" placeholder="" />
                </div>
            </div>
            <div className='row col-lg-12 row-form'>
                <div className='col-lg-4 pt-2'>
                    <label id='lblOrgName' htmlFor='Password'>Password</label>
                </div>
                <div className='col-lg-8 users-password'>
                    <i onClick={e => togglePassword(e)}>{formUI.icon}</i>
                    <input style={{ width: '100%' }} id='Password' value={formdata.Password} type={formUI.passwordType} name="Password" onChange={handleChange} placeholder="" disabled={setTextBoxDisable()} />
                </div>
                <div className='col-lg-4 pt-2'> </div>
                {isEdit && <div className='col-lg-8'> 
                    <p style={{ fontSize: "70%", color:"#2575fc"}} >
                        Note: password must be at least eight positions in length and contain a mix of 
                    alphabetic and non-alphabetic characters (numbers, punctuation or special 
                    characters) or a mix of at least two types of non-alphabetic characters.
                        </p></div>}
            </div>
            <div className='row col-lg-12 row-form'>
                <div className="user-btn" style={{ paddingRight: '25px' }}>                    
                    {isEdit && <div><Button id='btnSubmitLocal' className="btn btn-outline-primary" disabled={isDisable} onClick={e => saveLocalCredentials(e)}>Save</Button></div>}

                    {isEdit && <div style={{ paddingLeft: '20px' }}> <Button id='btnCancelLocal' disabled={isDisable} className='btn btn-outline-secondary' onClick={e => reset(e)}>Cancel</Button></div>}</div>
                    
                {/* <div>
                        <Button className='btn btn-outline-secondary' onClick={e => reset(e)}> Reset</Button></div> */}
            </div>
        </div>
        <br></br>
        <div className="row col-lg-8 row-form users-form">
            <span>AzureAd integration</span>
            <div className="row col-lg-12 row-form">
                <div className='col-lg-4 pt-2'>
                    <label id='lblService' htmlFor='txtMSdomain'>Microsoft Tenant ID</label>
                </div>
                <div className='col-lg-8'>
                    <input type="text" style={{ width: '100%' }} value={getInputValue(formdata.MicrosoftTenantID)} id='MicrosoftTenantID' onChange={handleChange} disabled={!((profileInfo.IsMasterAdmin || profileInfo.IsBackupAdmin) && isEdit)} name="MicrosoftTenantID" placeholder="" />
                </div>
            </div>
            <div className='row col-lg-12 row-form'>
                <div className='col-lg-4 pt-2 row-form'>
                    <label id='lblmasterGroup' htmlFor='masterGroup'>Master Admin Group</label>
                </div>
                <div className='col-lg-8'>
                    <input type="text" style={{ width: '100%' }} value={getInputValue(formdata.MasterAdmin)} id='MasterAdmin' onChange={handleChange} disabled={!((profileInfo.IsMasterAdmin || profileInfo.IsBackupAdmin) && isEdit)} name="MasterAdmin" placeholder="" />
                </div>
            </div>
            <div className='row col-lg-12 row-form'>
                <div className='col-lg-4 pt-2 row-form'>
                    <label id='lblbackupGroup' htmlFor='backupGroup'>Backup Admin Group</label>
                </div>
                <div className='col-lg-8'>
                    <input type="text" style={{ width: '100%' }} value={getInputValue(formdata.BackupAdmin)} id='BackupAdmin' onChange={handleChange} disabled={!((profileInfo.IsMasterAdmin || profileInfo.IsBackupAdmin) && isEdit)} name="BackupAdmin" placeholder="" />
                </div>
            </div>
            <div className='row col-lg-12 row-form'>
                <div className='col-lg-4 pt-2 row-form'>
                    <label id='lblviewOnlyGroup' htmlFor='viewOnlyGroup'>View Only Group</label>
                </div>
                <div className='col-lg-8'>
                    <input type="text" style={{ width: '100%' }} value={getInputValue(formdata.ViewOnly)} id='ViewOnly' onChange={handleChange} disabled={!((profileInfo.IsMasterAdmin || profileInfo.IsBackupAdmin) && isEdit)} name="ViewOnly" placeholder="" />
                </div>
            </div>
            <div className='row col-lg-12 row-form'>
                <div className="user-btn" style={{ paddingRight: '25px' }}>
                    {isEdit && <div><Button id='btnSubmit' className='btn btn-outline-primary' onClick={e => saveUserGroups(e)}>Save</Button></div>}

                    {isEdit && <div style={{ paddingLeft: '20px' }}> <Button id='btnCancel' className='btn btn-outline-secondary' onClick={e => reset(e)}>Cancel</Button></div>}</div>
                {/* <div>
                        <Button className='btn btn-outline-secondary' onClick={e => reset(e)}> Reset</Button></div> */}
            </div>
        </div>
    </div>)
}
export default UsersList;