import React from 'react';
import { AuthenticationState } from 'react-aad-msal';
import { loginAuthProvider } from '../util/AuthProvider';
const Logout = () => {
    return (<div>Signing out....
        {(() => {
            var _isVAcLogin = localStorage.getItem('isVAC');
            if (_isVAcLogin) {
                var isVacLogin = atob(_isVAcLogin);
                if (isVacLogin == '1') {
                    localStorage.clear();
                    window.location.href = '/Login'
                }
            } else {
                console.log(loginAuthProvider.authenticationState)
                if (loginAuthProvider.authenticationState === AuthenticationState.Authenticated)
                    loginAuthProvider.logout();
                localStorage.clear();
                try {
                    loginAuthProvider.logout();
                } catch (error) {
                    console.log(error)
                }
                if (loginAuthProvider.authenticationState != AuthenticationState.Authenticated) {
                    localStorage.clear();
                    window.location.href = '/Login';
                }
                setTimeout(() => {
                    window.location.href = '/Login'
                }, 5000);
                //window.location.href = '/Login';
            }
        })()}
    </div>);
};
export default Logout;