import React, { useEffect, useState } from 'react'
import '../../../CSS/alarms.css';
import '../../../CSS/CustomTable.css';
import detailsService from '../service/details.service';
import ccl from "../../../images/img/v-ccl.png";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CloudCoverLocal = (props) => {
  const [resultData, setResultData] = useState({})
  const [apiResult, setApiResult] = useState({})
  const [priceDetails, setPriceDetails] = useState({
    partnerPrice:0,
    endUserPrice:0
  })
  const [ccLicData, setCcLicData] = useState([{}])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [totalValue, setTotalValue] = useState(0)
  const [edition, setEdition] = useState({})
  const [isModel, setIsModel] = useState(false);
  const [formdata, setFormData] = useState([{
    name: '',
    quantity: 0,
    rrp: 0,
    cost: 0,
    total: 0,
    id: 0
  }]);
  const [veeamONEFormdata, setVeeamONEFormdata] = useState([{
    name: '',
    quantity: 0,
    rrp: 0,
    cost: 0,
    total: 0,
    id: 0
  }]);
  const [veeamAgFormdata, setVeeamAgFormdata] = useState([{
    name: '',
    quantity: 0,
    rrp: 0,
    cost: 0,
    total: 0,
    id: 0
  }]);

  const _service = detailsService();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getcloudcoverLocalDetails(props.OrgId);
    return () => {
      controller.abort();
    }
  }, [props.OrgId]);

  useEffect(() => {
    const controller = new AbortController();
    CalculateTotalByrow();
    return () => {
      controller.abort();
    }
  }, [isChanged]);

  var resultdata={};

  const CalculateTotalByrow = () => {
    var _row1 = formdata;
    var val = _row1.reduce((previousValue, currentValue) =>
      parseFloat(previousValue) + parseFloat(currentValue[`total`]), 0);

    var _row2 = veeamAgFormdata;
    var val2 = _row2.reduce((previousValue, currentValue) =>
      previousValue + currentValue[`total`], 0);
    var _row3 = veeamONEFormdata;
    var val3 = _row3.reduce((previousValue, currentValue) =>
      previousValue + currentValue[`total`], 0);

    setTotalValue(parseFloat(parseFloat(val) + parseFloat(val2) + parseFloat(val3)).toFixed(2));
  }

  const ChangeLic = (d, e) => {
    var prdId = e ?? d.productId;
    var products = d.products.filter(x => x.productId == prdId)
    if (!products) return;

    var return_data = products[0].workloads.map((item, index) => {
      return {
        name: item.name,
        quantity: GetQuantity(d, prdId, item.workloadId),
        rrp: (item.multiplier * priceDetails.endUserPrice).toFixed(2),
        cost: (item.multiplier * priceDetails.partnerPrice).toFixed(2),
        total: (GetQuantity(d, prdId, item.workloadId) * (item.multiplier * priceDetails.partnerPrice)).toFixed(2),
        id: item.workloadId
      }
    });
    console.log('first')
    //setFormData(return_data);
    ChangeLicVeeamOne(d, e);
    ChangeLicVeeamAgent(d, e);
    setIsChanged(!isChanged);
  }

  const ChangeLicVeeamOne = (d, e) => {
    var prdId = e ?? d.productId;
    var return_data = d.veeamOneWorkLoads.map((item, index) => {
      return {
        name: item.name,
        quantity: GetOtherQuantity(d, 'ONE', item.workloadId),
        rrp: (item.multiplier * priceDetails.endUserPrice).toFixed(2),
        cost: (item.multiplier * priceDetails.partnerPrice).toFixed(2),
        total: 0 * priceDetails.endUserPrice,
        id: item.workloadId
      }
    });
    setVeeamONEFormdata(return_data);
    setIsChanged(!isChanged);
  }
  const ChangeLicVeeamAgent = (d, e) => {
    var prdId = e ?? d.productId;
    var return_data = d.veeamAgentWorkLoads.map((item, index) => {
      return {
        name: item.name,
        quantity: GetOtherQuantity(d, 'VAG', item.workloadId),
        rrp: (item.multiplier * priceDetails.endUserPrice).toFixed(2),
        cost: (item.multiplier * priceDetails.partnerPrice).toFixed(2),
        total: 0 * priceDetails.endUserPrice,
        id: item.workloadId
      }
    });
    setVeeamAgFormdata(return_data);
    setIsChanged(!isChanged);
  }
  const GetQuantity = (d, p, w) => {
    var _res = d.cclData.filter(x => x.productId == p && x.workLoadId == w);
    if (_res && _res.length > 0) {
      return _res[0].quantity;
    }
    return 0;
  }
  const GetOtherQuantity = (d, p, w) => {
    var _res = d.cclData.filter(x => x.productId.indexOf(p) > -1 && x.workLoadId == w);
    if (_res && _res.length > 0) {
      return _res[0].quantity;
    }
    return 0;
  }


  const getcloudcoverLocalDetails = async (_orgId) => {
    overlayProgress(true);
    let dataccb = await _service.getCloudCoverLocalDetails(_orgId)
      .then(response => {
        return response.data;
      })
      .catch((reason) => {
        console.log("getcloudcoverBackupDetails Org" + reason);
      });

    overlayProgress(false);
    if (dataccb) {
      var _price=priceDetails;
      _price.partnerPrice=dataccb.partnerPrice
      _price.endUserPrice=dataccb.endUserPrice
      setPriceDetails(_price);
      resultdata=dataccb;
      setResultData(dataccb);
      setApiResult(dataccb);
      setCcLicData(dataccb.cclData);
      setformData(dataccb);
      getEditions(dataccb);
      ChangeLic(dataccb);
      setTimeout(() => {
        console.log('resultData')
      }, 1000);
      console.log('test api result')
    }
    else {
      console.log('set null for no data')
     setResultData(null)
     resultdata=null
    }
    setIsLoaded(true)

  }
  const setformData = (d) => {
    var resp = d.workLoads.map((item, index) => {
      return {
        quantity: GetValues('quantity', item.workloadId, item,d.cclData),
        id: index,
        rrp: GetValues('rrp', item.workloadId, item,d.cclData),
        cost: GetValues('cost', item.workloadId, item,d.cclData),
        total: GetValues('total', item.workloadId, item,d.cclData),
        name:item.name
      }
    });
    setFormData(resp)
  }

  const getSumValues = (key) => {

    var val = resultData.cclData.reduce((previousValue, currentValue) =>
      previousValue + currentValue[key], 0);
    return val.toFixed(2);
  }
  const overlayProgress = (show) => {
    if (show)
      document.getElementById('main-div-form-elements').style.display = 'block';
    else
      document.getElementById('main-div-form-elements').style.display = 'none';
  }

  const getEditions = (d) => {
    if (d && d.products) {
      var _result = d.products.filter(x => x.productId.indexOf('VBR') > -1);
      var _res = _result.map((item, index) => {
        return { id: item.productId, val: item.edition }
      });
      setEdition(_res)
    }
  }

  const GetValues = (m, p, h,l) => {
    if (!l)
      return 0;
    var _res = l.filter(x => x.workLoadId == p)
    if (!_res) return 0;
    if (_res.length == 0) return 0;
    var qty = _res[0][m];
    if (!qty) {
      return 0;
    }
    if (m == 'quantity') {
      return (qty).toFixed(0);
    } else {
      return (qty).toFixed(2);
    }
  }
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const val = e.target.value;
    var frmDatas = formdata;
    var _requestdata = frmDatas[i];
    _requestdata[name] = parseInt(val);
    _requestdata['total'] = parseInt(val) * _requestdata['cost'];
    frmDatas[i] = _requestdata;
    setFormData(frmDatas);
    setIsChanged(!isChanged);
  };
  const handleAgChange = (e, i) => {
    const { name, value } = e.target;
    const val = e.target.value;
    var frmDatas = veeamAgFormdata;
    var _requestdata = frmDatas[i];
    _requestdata[name] = parseInt(val);
    _requestdata['total'] = parseInt(val) * _requestdata['cost'];
    frmDatas[i] = _requestdata;
    setVeeamAgFormdata(frmDatas);
    setIsChanged(!isChanged);
  };
  const handleOneChange = (e, i) => {
    const { name, value } = e.target;
    const val = e.target.value;
    var frmDatas = veeamONEFormdata;
    var _requestdata = frmDatas[i];
    _requestdata[name] = parseInt(val);
    _requestdata['total'] = parseInt(val) * _requestdata['cost'];
    frmDatas[i] = _requestdata;
    setVeeamONEFormdata(frmDatas);
    setIsChanged(!isChanged);
  };

  const GetValueFromData = (v, key) => {
    if (v) {
      return v[key];
    } else {
      return 0;
    }
  }
  const SetEditForm = () => {
    resultData.workLoads.map((item, index) => {
      var frmText = document.getElementById(`quantity-${index}`)
      if (frmText)
        frmText.value = GetValueFromData(formdata[index], 'quantity');
      setIsChanged(!isChanged);
    });

    veeamAgFormdata.map((item, index) => {
      var frmText = document.getElementById(`quantity-ag-${index}`)
      if (frmText)
        frmText.value = item['quantity'];
      setIsChanged(!isChanged);
    });
    veeamONEFormdata.map((item, index) => {
      var frmText = document.getElementById(`quantity-vo-${index}`)
      if (frmText)
        frmText.value =item['quantity'];
      setIsChanged(!isChanged);
    });
  }

  const GetTotalValues = (d) => {
    if (d) {
      return (parseFloat(d.quantity) * parseFloat(d.cost)).toFixed(2);
    }
    return 0;
  }

  const GetTotalByrows = (d) => {
    if (d)
      return parseFloat(d.total).toFixed(2);

    return 0;
  }
  const CloudCoverLocalUI = (
    <div>
      {resultData && resultData.cclData && resultData.cclData.length > 0 &&
        <div>
          <div className='ccl-edition-header'>
            <div> <span>Veeam Backup & Replication Edition</span>
              <p>{!isEdit && resultData.edition} {isEdit && <select onChange={e => ChangeLic(resultData, e.target.value)}>
                {edition.map((r, index) => {
                  return <option key={r.id} value={r.id}>{r.val}</option>
                })}
              </select>}</p>
            </div>
            <div id='manageOrgs' className={isEdit ? 'action-button btn-disable' : 'action-button'} >
              <div>{!isEdit && <div onClick={e => {
                setIsEdit(!isEdit)
                setTimeout(() => {
                  SetEditForm();
                }, 100);

              }}><i className='fal fa-edit fa-lg m-r-1 cursor' title='Edit Local' ></i></div>}
                {isEdit && <><div style={{ display: 'flex' }}><div
                  onClick={e => {
                    setIsEdit(false)
                    setTimeout(() => {
                      SetEditForm();
                    }, 100);

                  }}
                ><i className='fal fa-ban fa-lg m-r-1 cursor' title='Edit Local' ></i></div>
                  <div onClick={e => {

                    setIsModel(true)
                    setTimeout(() => {
                      SetEditForm();
                    }, 100);

                  }}><i className='fal fa-download fa-lg m-r-1 cursor' title='Edit Local' ></i></div>
                </div></>}
              </div>
            </div>
          </div>

          <div className='ccc-workloads'>
            <div className='tables ccl-tables'>
              <table>
                <thead>
                  <tr>
                    <th>Workloads</th>
                    <th>Qty</th>
                    <th>RRP</th>
                    <th>Partner</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>                  
                  {formdata.map((item, index) => {
                    return (<>
                      <tr>
                        <td className='string'>{item.name}</td>
                        <td className='numbers'>{!isEdit && GetValueFromData(item, 'quantity')}
                          {isEdit && <input key={index} type="number" id={`quantity-${index}`} onChange={e => handleChange(e, index)} name="quantity" placeholder="" />}</td>
                        <td className='numbers'>{GetValueFromData(item, 'rrp')}</td>
                        <td className='numbers'>{GetValueFromData(item, 'cost')}</td>
                        <td className='numbers'>{isChanged && GetTotalByrows(item)}{!isChanged && GetTotalByrows(item)}</td>
                      </tr>
                    </>
                    )
                  })}
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Veeam Agents</th>
                    <th>Qty</th>
                    <th>RRP</th>
                    <th>Partner</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {veeamAgFormdata.map((item, index) => {
                    return (<>
                      <tr>
                        <td className='string'>{item.name}</td>
                        {/* <td className='numbers'>{GetValues('quantity', item.workloadId, item)}</td> */}
                        <td className='numbers'>{!isEdit && GetValueFromData(item, 'quantity')}
                          {isEdit && <input key={index} type="number" id={`quantity-ag-${index}`} onChange={e => handleAgChange(e, index)} name="quantity" placeholder="" />}</td>
                        <td className='numbers'>{GetValueFromData(item, 'rrp')}</td>
                        <td className='numbers'>{GetValueFromData(item, 'cost')}</td>
                        <td className='numbers'>{isChanged && GetTotalValues(item)}{!isChanged && GetTotalValues(item)}</td>
                      </tr>
                    </>
                    )
                  })}
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th>Veeam ONE</th>
                    <th>Qty</th>
                    <th>RRP</th>
                    <th>Partner</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {veeamONEFormdata.map((item, index) => {
                    return (<>
                      <tr>
                        <td className='string'>{item.name}</td>
                        <td className='numbers'>{!isEdit && GetValueFromData(item, 'quantity')}
                          {isEdit && <input key={index} type="number" id={`quantity-vo-${index}`} onChange={e => handleOneChange(e, index)} name="quantity" placeholder="" />}</td>
                        <td className='numbers'>{GetValueFromData(item, 'rrp')}</td>
                        <td className='numbers'>{GetValueFromData(item, 'cost')}</td>
                        <td className='numbers'>{isChanged && GetTotalValues(item)}{!isChanged && GetTotalValues(item)}</td>
                      </tr>
                    </>
                    )
                  })}
                </tbody>
                <tfoot>
                  <td style={{ border: '0 1px 1px 1px inset', borderLeft: '0' }} colSpan={4}><b>Total</b></td>
                  <td style={{ textAlign: 'right', border: '1px inset' }}>{totalValue}</td>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      }

      {
        (resultData == null || !resultData.cclData || (resultData.cclData && resultData.cclData.length == 0)) &&
        <div>
          <div className='no-data-page-center'>
            <span style={{ minHeight: '40px', display: 'inline-flex' }} className='ccb-no-data-header'>Not currently benefiting from CloudCover</span>
          </div>
          <div className='ccb-no-data'>
            <div className="elementor-widget-container ccb-first rest-container col-md-6" style={{paddingTop:'20px',lineHeight:'119%'}}>

              <p><span >As a Veeam supplier for over 10 years, we have the knowledge and experience to support our partners in deploying local Veeam backup solutions to their customer base.
              </span></p>
              <p><span >As part of a service from virtualDCS we are sure to be more competitive and versatile compared with legacy licence renewal and the new Veeam VUL licences.</span></p>
              <p>Veeam Cloud Connect offers a fully integrated, secure and seamless way to transfer customer backups to the Cloud while retaining full control and visibility of the information.&nbsp;</p>
              <p>Key Points:</p>
              <ul>
                <li>Pay for what you need.</li>
                <li>Pay Monthly.</li>
                <li>Still use Standard and Enterprise editions.</li>
                <li>Personal Support Team.</li>
              </ul>
            </div>
            <div className='col-md-6 no-data-img'>
              <img src={ccl} alt='cc backup' style={{ width: '300px' }} />
            </div>
          </div>
          <div></div>
        </div>
      }
    </div>);

  return (
    <div>
      <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
      {isLoaded && CloudCoverLocalUI}


      <Modal isOpen={isModel} toggle={() => setIsModel(false)}>
        <ModalHeader toggle={() => setIsModel(false)}>
          Edit Cloudcover Local
        </ModalHeader>
        <ModalBody>
          Are you sure you want to continue with the changes to the License?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn btn-outline-primary">Ok</button>
          <button className="btn btn-primary">Cancel</button>

        </ModalFooter>
      </Modal>
    </div>
  );

}
export default CloudCoverLocal;