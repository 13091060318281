import { Component } from "react";
import { HelpDocuments } from "./HelpDocuments";
import "bootstrap/dist/css/bootstrap.css";
import { SubscriberAgreement } from "./SubscriberAgreement";
import { SecurityAndPrivacy } from "./SecurityAndPrivacy";
import resetIcon from "../images/reset.png";
import ccbanner from "../images/img/cc-services.png";
import microsoftSignInDarkBig from "../images/microsoftSignInDarkBig.png";
import { Button, Spinner } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import authService from "./Shared/Services/AuthService";

export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams.get("resetCode"));
        this.state = {
            resetCode: urlParams.get("resetCode"),
            loading: false,
            userName: '',
            email: '',
            returnUrl: '',
            errorField: {
                userName: false,
                email: false,
                returnUrl: false
            },
            errorMessage: {
                userName: 'User name is required',
                email: 'Email address is required',
                returnUrl: 'Return URL is required',
                submitError: false,
                genericMessage: ''
            },
            isResetSuccess: false
        };
        this.authService = authService();
    }
    componentDidMount = () => {
    }

    ResetPassword = async () => {
        this.setState({ loading: true });
        let postData = {
            email: this.state.email,
            userName: this.state.userName,
            returnUrl: window.location.href.toString().replace('https:', '')
        };
        var _resetData = await this.authService.ResetPassword(postData)
            .then(response => {
                
                return response.data;
            }).catch((r) => { });

        if (_resetData == 'success') {

            var err = {
                submitError: true,
                genericMessage: 'A password reset was initiated for your account. Check your inbox for the confirmation email.'
            }
            this.setState({
                isResetSuccess: true,
                errorMessage: err
            });
            setTimeout(() => {
                window.location.href = '/login';
            }, 4000);
        } else {
            var err = {
                submitError: true,
                genericMessage: 'Password reset failed.'
            }
            this.setState({
                isResetSuccess: false,
                errorMessage: err
            });

        }
        this.setState({ loading: false });
    }

    ChangePassword = async () => {
        this.setState({ loading: true });
        let postData = {
            code: this.state.resetCode,
            newPassword: this.state.email
        };
        var _resetData = await this.authService.ChangePassword(postData)
            .then(response => {
               
                return response.data;
            }).catch((r) => { });

        if (_resetData == 'success') {
            var err = {
                submitError: true,
                genericMessage: 'Password reset successful.'
            }
            this.setState({
                errorMessage: err
            });
            setTimeout(() => {
                window.location.href = '/login';
            }, 4000);
            return;
        }
        var err = {
            submitError: true,
            genericMessage: 'Password reset failed.'
        }
        this.setState({
            errorMessage: err,
            loading: false
        });
    }

    validationHandler = (e, t) => {
        var erroFld = {
            userName: this.state.errorField.userName,
            email: this.state.errorField.email,

        }
        switch (t) {
            case 'user':
                erroFld.userName = !e.target.value.length > 0;
                break;
            case 'email':
                erroFld.password = !e.target.value.length > 0;

                break;
            default:
                break;
        }
        this.setState({
            errorField: erroFld
        });
    }

    handleUserName = (e) => {
        var errmsg = {
            userName: 'User name is required',
            returnUrl: this.state.returnUrl == '' || this.state.returnUrl == null ? 'Return URL is required' : '',
            email: this.state.email == '' || this.state.email == null ? 'Email is required' : '',
            submitError: false,
            genericMessage: ''
        };
        this.setState({
            userName: e.target.value,
            errorMessage: errmsg
        });
    }
    handleReturn = (e) => {
        var errmsg = {
            userName: this.state.userName == '' || this.state.userName == null ? 'User name is required' : '',
            returnUrl: this.state.returnUrl == '' || this.state.returnUrl == null ? 'Return URL is required' : '',
            email: this.state.email == '' || this.state.email == null ? 'Email is required' : '',
            submitError: false,
            genericMessage: ''
        };
        this.setState({
            returnUrl: e.target.value,
            errorMessage: errmsg
        });
    }
    handleEmail = (e) => {
        var errmsg = {
            userName: this.state.userName == '' || this.state.userName == null ? 'User name is required' : '',
            returnUrl: this.state.returnUrl == '' || this.state.returnUrl == null ? 'Return URL is required' : '',
            email: this.state.email == '' || this.state.email == null ? 'Email is required' : '',
            submitError: false,
            genericMessage: ''
        };
        this.setState({
            email: e.target.value,
            errorMessage: errmsg
        });
    }

    render() {
        const inputStyle = {
            marginTop: '10px'
        }
        const loadingSpinner = <Spinner color="primary" />;
        var loginFrame = (

            <div className="input-content">
                {this.state.errorMessage.submitError && (<span style={{ color: 'red' }}>
                    {this.state.errorMessage.genericMessage}
                </span>)}

                <div style={{ minWidth: '450px', margin: 'auto' }}>
                    <div className="input-icons">
                        <div style={inputStyle} className={this.state.errorField.userName ? 'input-field-text-error tooltips' : ''}>
                            <i className="fa fa-user icon"></i>
                            <input disabled={this.state.loading} ref={inputEl => (this.usernameField = inputEl)}
                                onBlur={(e) => this.validationHandler(e, 'user')}
                                className="input-field input-vac-login" type="text" placeholder="Company\User" id="userName" onChange={e => this.handleUserName(e)} value={this.state.userName} />
                            <span className="tooltiptext">
                                {this.state.errorField.userName ? this.state.errorMessage.userName : ''}
                            </span>
                        </div>
                        <div style={inputStyle} className={this.state.errorField.email ? 'input-field-text-error tooltips' : ''}>
                            <i className="fa fa-envelope icon"></i>
                            <input disabled={this.state.loading}
                                onBlur={(e) => this.validationHandler(e, 'user')}
                                className="input-field input-vac-login" type="text" placeholder="Email" id="email" onChange={e => this.handleEmail(e)} value={this.state.email} />
                            <span className="tooltiptext">
                                {this.state.errorField.email ? this.state.errorMessage.email : ''}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <table style={{width: "90%", marginLeft: "auto", marginRight: "auto", borderCollapse: 'separate', borderSpacing: '0px 7px' }}>
                        <tr>
                            <td>
                                <Button className="btn btn-dark" block="true" onClick={e => this.ResetPassword(e)} style={{ cursor: "pointer" }} disabled={this.state.loading}>
                                    {
                                        this.state.loading &&
                                        <span>
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "23px", color: "black" }} ></i>
                                        </span>
                                    }
                                    {
                                        !this.state.loading &&
                                        <span className="button_text">
                                            Reset Password
                                        </span>
                                    }
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                        <Button className="btn btn-dark btn-outline-secondary" block="true" onClick={e => window.location.href = '/login'} style={{ cursor: "pointer" }} disabled={this.state.loading}>
                            <span className="button_text">Cancel</span>
                                </Button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        );
        var successMessage = (<div color="green">
            Password reset is successfull. An email is sent to the registered email.
        </div>)
        var resetForm = (

            <div className="input-content">
                {this.state.errorMessage.submitError && (<span style={{ color: 'red' }}>
                    {this.state.errorMessage.genericMessage}
                </span>)}

                <div style={{ maxWidth: '450px', margin: 'auto' }}>
                    <div className="input-icons">
                        <div style={inputStyle} className={this.state.errorField.email ? 'input-field-text-error tooltips' : ''}>
                            <i className="fa fa-key icon"></i>
                            <input disabled={this.state.loading}

                                className="input-field input-vac-login" type="password" placeholder="Password" id="email" onChange={e => this.handleEmail(e)} value={this.state.email} />
                            <span className="tooltiptext">
                                {this.state.errorField.email ? this.state.errorMessage.email : ''}
                            </span>
                        </div>
                        <div style={inputStyle} className={this.state.errorField.returnUrl ? 'input-field-text-error tooltips' : ''}>
                            <i className="fa fa-key icon"></i>
                            <input disabled={this.state.loading}

                                className="input-field input-vac-login" type="password" placeholder="Confirm Password" id="returnUrl" onChange={e => this.handleReturn(e)} value={this.state.returnUrl} />
                            <span className="tooltiptext">
                                {this.state.errorField.returnUrl ? this.state.errorMessage.returnUrl : ''}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <table style={{ width: "90%", marginLeft: "auto", marginRight: "auto", borderCollapse: 'separate', borderSpacing: '0px 7px' }}>
                        <tr>
                            <td>
                                <Button className="btn btn-dark" block="true" onClick={e => this.ChangePassword(e)} style={{ cursor: "pointer" }} disabled={this.state.loading}>
                                    {
                                        this.state.loading &&
                                        <span>
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "23px", color: "black" }} ></i>
                                        </span>
                                    }
                                    {
                                        !this.state.loading &&
                                        <span className="button_text">
                                                Update Password
                                        </span>
                                    }
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Button className="btn btn-dark btn-outline-secondary" block="true" onClick={e => window.location.href = '/login'} style={{ cursor: "pointer" }} disabled={this.state.loading}>
                                    <span className="button_text">Cancel</span>
                                </Button>
                            </td>
                        </tr>
                    </table>

                </div>
                {/*<div className="btn-space">*/}
                {/*    <Button className="btn btn-dark" onClick={e => this.ChangePassword(e)} style={{ cursor: "pointer" }} disabled={this.state.loading}>*/}
                {/*        <i className={this.state.loading ? "fa fa-sync fa-spin spinners" : ""}></i>*/}
                {/*        <span className="button_text">Update Password</span>*/}
                {/*    </Button>*/}
                {/*    */}{/*<Button className="btn btn-dark btn-secondary" onClick={e => window.location.href = '/login'} style={{ cursor: "pointer" }} disabled={this.state.loading}>*/}
                {/*    */}{/*    <span className="button_text">Cancel</span>*/}
                {/*    */}{/*</Button>*/}
                {/*</div>*/}
            </div>
        );

        const currentYear = new Date().getFullYear(); 
        return (<div id="loginPannel" className="loginPannel">
            <div className="row login col-xs-12">
                <div className="col-xs-6 loginboxouter">
                    <img src={ccbanner} alt="Login" />
                </div>
                <div className="col col-xs-2 intermediate">

                </div>
                <div className="col-xs-6 col-sm-6 text-center loginboxinner">
                    <div className="card loginbox">
                        <div className="card-body">
                            <img src={resetIcon} alt="Login" />
                            <div
                                id="login_header"
                                className="form-group"
                            ></div>
                            {this.state.resetCode != null && this.state.resetCode.length > 0 ? resetForm : this.state.isResetSuccess ? successMessage : loginFrame}
                            <br />
                            <HelpDocuments message="Having trouble logging in?" />
                        </div>
                        <div className="col pb-3 ssobutton">
                            <div className='login-sso'>
                            </div>
                            <div className="card-footer">
                                <section className="small">
                                    <SubscriberAgreement />
                                    |
                                    <SecurityAndPrivacy />
                                    <br />© Saas It {currentYear}
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>);
    }
}