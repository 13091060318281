import React, { useEffect, useState } from 'react'
import moment from "moment";
import dashboardservice from '../../dashboard/service/dashboard.service';
import '../../../CSS/alarms.css';
import '../../../CSS/CustomTable.css';
import { CC365Charts } from '../../Shared/components/Charts/CC365Charts'
import ccb from "../../../images/img/v-ccb.png";
import NoDataFound from './NoDataFound';

const CloudCoverBackup = (props) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [resultData, setResultData] = useState({})
  const urlParams = new URLSearchParams(window.location.search);
  const orgId = urlParams.get("orgId") ? urlParams.get("orgId") : "";
  const [OrgId, setOrgId] = useState(orgId);
  const [showChart, setShowChart] = useState(false)
  const _service = dashboardservice();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const urlParams = new URLSearchParams(window.location.search);
    const _orgId = urlParams.get("orgId") ? urlParams.get("orgId") : "";
    getcloudcoverBackupDetails(_orgId);
    return () => {
      controller.abort();
    }
  }, [OrgId])


  const getcloudcoverBackupDetails = async (_orgId) => {
    overlayProgress(true);
    let dataccb = await _service.getCloudCoverBackupDetails(_orgId)
      .then(response => {
        return response.data;
      })
      .catch((reason) => {
        console.log("getcloudcoverBackupDetails Org" + reason);
      });

    overlayProgress(false);
    if (dataccb) {
      setResultData(dataccb.result);
    }
    else {
      setResultData(null)
    }
  }

  const calculateUsage = (val) => {
    //9.313225/10000000000
    return (val * 9.313225 / 10000000000).toFixed(0);
  }

  const getSumValues = (key) => {

    var val = resultData.reduce((previousValue, currentValue) =>
      previousValue + currentValue[key], 0);
    return val;
  }
  const customDateFormatter = (v) => {
    return v ? moment(v).format("DD/MM/YYYY hh:mm:ss").toString() : '';
  };
  const overlayProgress = (show) => {
    if (show)
      document.getElementById('main-div-form-elements').style.display = 'block';
    else
      document.getElementById('main-div-form-elements').style.display = 'none';
  }
  const getUsed = (used, quota) => {
    return ((quota * 9.313225 / 10000000000).toFixed(0) / (used * 9.313225 / 10000000000).toFixed(0)) * 100;
  }

  const graphaction = () => {
    setShowChart(!showChart);
  }
  const CloudCoverBackupUI = (<div>
    {resultData && resultData.length > 0 &&
      <div>
        <div>          
          <div className='table'>
          {resultData.map((item, index) => {
              return (<>
          <div className='chart-section table-cell' style={{borderTop:0,border:'none'}}>
                  <CC365Charts chartType={'radialBar'} label={item.cloudRepositoryName.toString().replace('&amp;','&').replace('amp;','')} dataPoints={{ used: calculateUsage(item.usedStorageQuota), quota: calculateUsage(item.storageQuota),unit:'GB' }} usage={getUsed(item.storageQuota, item.usedStorageQuota)} />
                </div>
                </>
              )
            })}
            <div className='table-headers' style={{borderTop:'0 solid currentColor'}}>Storage</div>

            <div className='table-cell cell-header-custom'>
              <h5>Repository</h5>
            </div>
            <div className='table-cell cell-header-custom'><h5>Used</h5></div>
            <div className='table-cell cell-header-custom'><h5>Quota</h5></div>
            {resultData.map((item, index) => {
              return (<>
             
                <div className='table-cell cell-feature'>{item.cloudRepositoryName.toString().replace('amp;','').replace('&amp;','&')}</div>
                <div className='table-cell cell-feature'>{calculateUsage(item.usedStorageQuota)} GB</div>
                <div className='table-cell cell-feature'>{calculateUsage(item.storageQuota)} GB</div>              
              </>
              )
            })}
          </div>

          <div className='table'>
            <div className='table-headers'>Workloads</div>
            <div className='table-cell cell-header-custom'>
              <h5>Servers</h5>
            </div>
            <div className='table-cell cell-header-custom'><h5>Workstations</h5></div>
            <div className='table-cell cell-header-custom'><h5>VMs</h5></div>
            <div className='table-cell cell-feature cell-align-center'>{getSumValues('serverBackups')}</div>
            <div className='table-cell cell-feature cell-align-center'>{getSumValues('workstationBackups')}</div>
            <div className='table-cell cell-feature cell-align-center'>{getSumValues('vmBackups')}</div>
          </div>
        </div>
      </div>
    }

    {
      (resultData == null || resultData.length == 0) &&
      <div>


        <NoDataFound
          header={'Not currently protected by CloudCover Backup'}
          content={` <div style="padding-top: 40px;"><p><span style="letter-spacing:0px;">By utilising <a href="/cloudcover-local">Veeam software</a> through CloudCover services you can enable your customers to take their backups <a class="inlinks" href="https://www.virtualdcs.co.uk/veeam-cloud-connect-replication">off-site</a> and provide protection for their virtual, physical, and Cloud-based workloads without the cost or complexity of building and maintaining your own secondary off-site infrastructure.</span></p><p><span style="letter-spacing:0px;">Provide the perfect opportunity to fulfil the industry-recommended 3-2-1 rule and protect your customers with an insurance policy against internal and external security threats, including <a class="inlinks" href="https://www.virtualdcs.co.uk/blog/how-to-protect-yourself-from-ransomware-as-a-service-raas-insider-attacks">Ransomware attacks</a>, hardware malfunctions and accidental deletion.</span></p><p>Veeam Cloud Connect offers a fully integrated, secure and seamless way to transfer customer backups to the Cloud while retaining full control and visibility of the information.&nbsp;</p><p>Gone are the days of rotating storage devices, such as tapes, and manually moving data to off-site locations. Instead, copies of in-house backups can be automatically sent to off-site <a href="/data-centres">UK-based data centres</a> and quickly retrieved as required.</p><p>Cloud Connect Veeam Backup makes data protection simple, profitable and practical for any customer environment and CloudCover is your ticket into the vast, growing Veeam ecosystem.</p></div>`
          }
          imgUrl={ccb}
        />
      </div>
    }
  </div>);

  return (
    <div>
      <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
      {CloudCoverBackupUI}
    </div>
  );
}
export default CloudCoverBackup;