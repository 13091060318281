import React, { Component } from "react";
import { Modal, Button } from "reactstrap";

export class SecurityAndPrivacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      securityAndPrivacyOpen: false,
    };
    this.openSecurityAndPrivacy = this.openSecurityAndPrivacy.bind(this);
    this.closeSecurityAndPrivacy = this.closeSecurityAndPrivacy.bind(this);
  }

  openSecurityAndPrivacy(event) {
    event.preventDefault();
    this.setState({
      securityAndPrivacyOpen: true,
    });
  }

  closeSecurityAndPrivacy(event) {
    this.setState({
      securityAndPrivacyOpen: false,
    });
  }
  render() {
    return (
      <React.Fragment>
        <Modal
          size="xl"
          isOpen={this.state.securityAndPrivacyOpen}
          onClosed={this.closeSecurityAndPrivacy}
          style={{overflowY: "scroll", maxHeight: "700px", maxWidth: "1200px"}}
        >
          <table
            id="HelpPanel"
            style={{ border: "outset", backgroundColor: "#FFFFFF" }}
          >
            <tbody>
              <tr
                id="PopupHeader"
                style={{ height: "36px", backgroundColor: "lightgray" }}
              > 
                <td colSpan="2">Security &amp; Privacy</td>            
                <td style={{ textAlign: "right" }}>
                  <Button onClick={this.closeSecurityAndPrivacy}>X</Button>
                </td>
              </tr>
              <tr className="PopupBody">
                <td style={{ textAlign: "left", fontWeight: "500" }} >
                  <div 
                    scrolling="auto"
                    style={{width:"100%", border:"hidden"}}>
                    <h1>Privacy Statement</h1>

                    <p>Updated on 1 January 2019.</p>

                    <h2>Overview</h2>

                    <p>
                      Our privacy policy is simple; what is yours, remains
                      yours. We operate our business and services in line with
                      the principle of ‘data minimisation.’ Simply put, we
                      believe that the less we know about you, the better.
                    </p>

                    <h2>Who we are</h2>

                    <p>
                      virtual Data Centre Services Ltd. is a private limited
                      company that is registered in England, with company number
                      07838624, and we conduct our business at The Waterscape,
                      Leeds, LS5 3EG. Our business is to provide you with secure
                      online collaboration and file storage services.
                    </p>

                    <h2>Who you are</h2>

                    <h3>Customer</h3>

                    <p>
                      Unless otherwise noted, we refer to you, the Customer, as
                      an owner or administrator of an Organisation, whether you
                      are a legal or natural person.
                    </p>

                    <h3>Customer’s end-users</h3>

                    <p>
                      If you are not the owner or administrator of an
                      Organisation, your use of CloudCvoer 365 may be subject to
                      your organisation’s privacy policy or practices, if any.
                      End-users of an account transfer some of the rights
                      described here to the account owners.
                    </p>

                    <h2>Information we keep</h2>

                    <p>
                      We store and process three types of Customer Information:
                      Secure Customer Data, Account Data, and Support Data. We
                      store and process this information in order to deliver our
                      services to our customers. We treat each of these data
                      equally, but there are some important technical and usage
                      differences to note.
                    </p>

                    <h3>Customer Data</h3>

                    <p>
                      This data is the content that you upload to CloudCover365.
                      We claim no rights to it beyond those necessary to deliver
                      our services to you. You may add, modify, and delete Data
                      at your discretion.
                    </p>

                    <h3>Account Data</h3>

                    <p>
                      To provide you with our services, we must collect, store,
                      and process limited Account Data. This data includes your
                      full name, email address, telephone number, and billing
                      details. This data is never used for any other purpose.
                    </p>

                    <h3>Support Data</h3>

                    <p>
                      To ensure that you have a trouble-free experience whilst
                      using CloudCover365, we collect, store, and process
                      Support Data. This data includes server logs, client IP
                      addresses, number of items stored in CloudCover365,
                      company name, and Guest email addresses.
                    </p>

                    <p>
                      We retain the right to store and process Support Data to
                      provide our services effectively, troubleshoot problems,
                      analyse the performance and demands on our services.
                    </p>

                    <p>
                      We may, from time to time, ask you to submit other data
                      that is not automatically collected, as part of a support
                      ticket that you raise. You are never obliged to submit
                      this other data, but it will severely hamper our ability
                      to help you if you don’t. This data can include client
                      logs, screenshots, information about your devices and
                      operating environment, and personally-identifying
                      information. We will never ask you for your password.
                    </p>

                    <h2>Data Location and Transfer</h2>

                    <h3>Customer Data</h3>

                    <p>
                      This data is the content that you upload to CloudCover365.
                    </p>

                    <h3>Account Data</h3>

                    <p>
                      This data is stored with, and processed by our internal
                      billing system, as well as our payment provider
                      (GoCardless), and our financial management system (Xero).
                      This data includes full names, email addresses, and
                      payment details.
                    </p>

                    <h3>Support Data</h3>

                    <p>
                      Our ticketing system is hosted in the United States of
                      America, by Kayako. Any information you choose to send us
                      through email and our customer support system may pass
                      through and be stored on a variety of intermediate
                      services, including Amazon Web Services. If you wish, you
                      may encrypt email to us using our PGP public key.
                    </p>

                    <h2>Securing your data</h2>

                    <p>
                      We understand that we have a duty to protect the
                      information that you trust us to store. We have produced a
                      comprehensive guide to how we secure your information,
                      here.
                    </p>

                    <h2>Your rights</h2>

                    <p>
                      You have a right to know what data we hold about you, and
                      to see how that data is collected, stored, and processed.
                      You may ask to receive a screenshot of data that we hold
                      on you in our back-office systems. You may also ask us to
                      update information about you that is incorrect. However,
                      these requests must come from an authenticated email
                      address, as described in the ‘Your responsibilities’
                      section, below.
                    </p>

                    <p>
                      As virtualDCS is merely a custodian of your data, we never
                      delete your information without your consent, or a
                      contractual obligation [sub agreement], such as when you
                      cancel your CloudCvoer365 Subscription.
                    </p>

                    <p>
                      Our disaster recovery and availability arrangements mean
                      that we have a legitimate interest in maintaining
                      immutable backups of certain Customer Information. Erasure
                      requests will leave those backups intact. However, we will
                      remove that data if legally compelled to and if the
                      technical means exist.
                    </p>

                    <h2>Your Responsibilities</h2>

                    <p>
                      Whilst we employ extensive security and process measures
                      to protect your account, it can only ever be as strong as
                      your Password and Backup Key (generated as part of the
                      two-factor authentication setup). You have a
                      responsibility to protect your Password and Backup Key
                      from unauthorised access.
                    </p>

                    <p>
                      Due to the ‘privacy-by-design’ nature of our service, and
                      the sensitivity of the data that you trust us to store, we
                      cannot help you with certain support request, unless you
                      are an account owner, and are contacting us from the email
                      address on your CloudCover365 account. In the event that
                      you change your email address, you must ensure it is
                      updated on your account. We do not accept unauthenticated
                      support requests made via telephone.
                    </p>

                    <h2>Cookies and Tracking</h2>

                    <p>
                      virtualDCS does not engage in any form of cross-service
                      tracking. We do use cookies (small text files placed on
                      your device for a limited time) on domains that we
                      control. The cookies allow us to provide our service
                      effectively. We also use ‘user analytics’ software to
                      track how you interact with our service and marketing
                      website. This software is provided by Google, but doesn’t
                      collect or store any personal information. You may disable
                      cookies in your browser without any impact to your use of
                      our services.
                    </p>
                    <p>To help understand the user interface experience, we use log rocket so we can see the world from your point of view. We apply filters to the screens we capture so none of your personal information is recorded.</p>
                    <p>
                      Some client applications, such as web browsers, may store
                      information about your account (such as form autofill). We
                      recommend that you do not allow your web browser to store
                      this information.
                    </p>

                    <h2>Contacting you</h2>

                    <p>
                      We may use your contact information (email address,
                      telephone number) to communicate with you about your use
                      of the service, to provide support, and to send you other
                      service-related information. You may choose to stop
                      receiving communications from us, except certain important
                      notifications such as billing and account security alerts.
                    </p>

                    <h2>Breach notification</h2>

                    <p>
                      If we discover a breach of Customer Information, we shall
                      inform our regulator (the Information Commissioner) within
                      72 hours of the discovery, and our Customers within 7
                      days. Notification to users may be sent via email, postal
                      mail, or telephone.
                    </p>

                    <h2>Disclosure</h2>

                    <p>
                      We comply with legal requests that are in the letter and
                      spirit of the law, in the jurisdictions where we must. We
                      have written about this, here.
                    </p>

                    <h2>Updates to our Privacy Statement</h2>

                    <p>
                      We may update this Privacy Statement from time-to-time,
                      and publish those changes on this page, along with the
                      date of last revision.
                    </p>

                    <h2>Contacting us</h2>

                    <p>
                      If you feel that your privacy or security has been
                      compromised, let us know, and we’ll work with you to make
                      it right.
                    </p>

                    <p>
                      If for any reason, you feel that we haven’t made it right,
                      you may contact our supervisory authority, the Information
                      Commissioner’s Office.
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal>
        <a
          href="https://www.virtualdcs.co.uk/privacy"
         target='_blank'
          style={{marginLeft: "5px", marginRight: "5px"}}
        >
          Security &amp; Privacy
        </a>
      </React.Fragment>
    );
  }
}
