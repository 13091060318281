let API_ROUTE_STRING;
let LEGACY_SITE_STRING;
let CC365_URL;
if (window.location.href.includes("localhost")) {
    API_ROUTE_STRING = 'https://localhost:7278/api/';
    //API_ROUTE_STRING = 'https://365Api-preview.virtualdcs.co.uk:443/api/';
    //API_ROUTE_STRING = 'https://365Api-Lab.virtualdcs.co.uk:443/api/';
    CC365_URL='https://localhost:44318/';

    LEGACY_SITE_STRING = 'http://localhost:51171/';
} else if (window.location.href.includes("https://preview")) {
    API_ROUTE_STRING = 'https://previewapi.cloudcover.services/api/';
    LEGACY_SITE_STRING = 'https://preview.cloudcover365.co.uk/v4/';
    CC365_URL='https://preview.cloudcover365.co.uk/';
} else if (window.location.href.includes("https://demo")) {
    API_ROUTE_STRING = 'https://demowebapi.cloudcover.services/api/';
    LEGACY_SITE_STRING = 'https://365demo.virtualdcs.co.uk/v4/';
    CC365_URL='https://365demo.virtualdcs.co.uk/';
}else if (window.location.href.includes("365demo")) {
    API_ROUTE_STRING = 'https://365Api-Lab.virtualdcs.co.uk:443/api/';
    LEGACY_SITE_STRING = 'https://365demo.virtualdcs.co.uk/v4/';
    CC365_URL='https://365demo.virtualdcs.co.uk/';
} else {
    API_ROUTE_STRING = 'https://api.cloudcover.services/api/';
    LEGACY_SITE_STRING = 'https://cloudcover365.co.uk/v4/';
    CC365_URL='https://cloudcover365.co.uk/';
}

export const API_ROUTE = API_ROUTE_STRING;
export const LEGACY_SITE = LEGACY_SITE_STRING;
export const CC365_ROUTE=CC365_URL;
//Routes
export const Routes = {
    // Users
    //GET_ORG: 'User/GetOrgID',
    GET_DATA: 'User/GetData/',
    GET_ORG: 'User/GetOrg',
    GET_REPO: 'User/GetRepos',
    GET_EVENTLOGS: 'User/GetEventlogs',
    GET_IS_ADMIN: 'User/CheckIsAdmin',
    GET_PROGRESS: 'User/GetRegistrationProgress',
    GET_MODERN_PROGRESS: 'User/GetModernRegistrationProgress',
    CREATE_APPLICATION: 'User/CreateApplication',
    CREATE_APPLICATION_MFA: 'User/CreateApplicationMFA',
    CREATE_GROUP: 'User/CreateAdminGroup',
    ADD_TO_GROUP: 'User/AddUserToAdminGroup',
    CREATE_VBO_USER: 'User/CreateVBOUser',
    CREATE_VBO_USER_MFA: 'User/CreateVBOUserMFA',
    ADD_WORKER_ACCOUNTS: 'User/AddWorkerAccounts',
    ADD_WORKER_ACCOUNTS_MFA: 'User/AddWorkerAccountsMFA',
    ADD_WORKER_ACCOUNTS_PASSWORD: 'User/ManualAddWorkerAccounts',
    GET_DEVICE_CODE: 'User/GetDeviceCode',
    ADD_TO_VEEAM_DEVICE_CODE: 'User/AddToVeeamDeviceCode',
    CREATE_BUCKET: 'User/CreateBucket',
    LOGIN: 'User/Login',
    REG_CHECK: 'User/RegCheck',
    ROLL_BACK: 'User/RollBack',
    GET_WORKER_ACCOUNT_INFO: 'User/GetWorkerAccountInfo',
    ADD_APPLICATIONS: 'User/AddApplications',
    GET_LICENSED_USERS: 'User/GetLicensedUsers',
    GET_PROTECTION_REPORT: 'User/GetProtectionReport',
    VALIDATE_TOKEN: 'User/ValidateToken',
    VALIDATE_TOKEN_ADMIN: 'User/ValidateAdmin',

    //Bucket Dashboard

    GET_BUCKETS: 'Bucket/GetBuckets',
    // Restore
    GET_RESTORE: 'Restore/GetRestore',
    GET_RESTORE_ITEMS: 'Restore/GetRestoreItems',
    GET_RESTORE_FOLDERS: 'Restore/GetRestoreFolders',
    EXPAND_RESTORE_FOLDER: 'Restore/ExpandRestoreFolder',
    GET_RESTORE_FOLDER_ITEMS: 'Restore/GetItems',
    GET_CHILDREN: 'Restore/GetChildren',
    GET_OBJECTS: 'Restore/GetObjects',
    GET_SUBSITES: 'Restore/GetSubsites',
    GET_RESTORE_DEVICE_CODE: 'Restore/GetDeviceCode',
    CHECK_DEVICE_CODE: 'Restore/DeviceCodeCheck',
    COMPLETE_RESTORE: 'Restore/Restore',
    SEARCH: 'Restore/Search',
    GET_ORG_NAME: 'Restore/GetOrgName',
    GET_NAVBAR_SETTINGS: 'User/GetNavbarSettings',
    GET_RESTORE_DATES: 'Restore/GetRestoreTimes',
    GET_BANK_ACCOUNT: 'GoCardless/GetBankAccount',
    ADD_BANK_ACCOUNT: 'GoCardless/CreateBankAccount',
    DELETE_BANK_ACCOUNT: 'GoCardless/DeleteBankAccount',
    TEST: 'Test/Test',

    // Notifications
    GET_NOTIFICATIONS: "Notification/GetNotifications",



    // New Sign Up Routes
    GET_SET_UP_INFO: 'User/GetSetUpInfo',
    CREATE_VBOUSER: 'User/CreateVboUser',
    CREATE_BACKUP_APPLICATION: 'User/CreateApplication',
    GET_APP_PERMISSION_LINK: 'User/GetAppPermissionLink',
    CREATE_ADMIN_GROUP: 'User/CreateAdminGroup',
    ADD_TO_VEEAM: 'User/AddToVeeam',
    FORGOT_PASSWORD: 'User/ForgotPassword',


    // Logs
    GET_ADMIN_LOGS: 'Log/GetAdminLogs',
    GET_LOGS: 'Log/GetLogs',
    GET_SELECT_ITEMS: 'Log/GetSelectItems',
    GET_IS_DCS_ADMIN: 'User/GetIsDcsAdmin',

    //Main page calls
    SET_JOB: 'User/ManageBackupJob',

    // Repositories
    UPDATE_REPOSITORY: 'Repository/UpdateRepository',

    //Dashboard
    GET_DASHBOARD_DATA:'Dashboard/GetDashboardData',
    GET_COMPANYDASHBOARD_DATA:'Dashboard/GetCompanyDashboardData',
    GET_365DASHBOARD_DATA:'Dashboard/Get365DashboardData',
    GET_365ALARMS_DATA:'Dashboard/GetAlarms365Data',
    GET_WORKLOADSUMMARY_DATA:'Dashboard/GetWorkloadSummary',
    GET_LICSUMMARY_DATA:'Dashboard/GetLicSummary',
    //Authentication
    GET_VAC_LOGIN:'Auth/Authenticate',
    GET_SSO_LOGIN:'Auth/SSO',
    GET_PROFILE_INFO:'Auth/GetProfile',
    //cloudCoverDetails - Dashboard
    GET_CLOUDCOVER_DETAILS:'Dashboard/GetCloudCoverDetails',
    GET_RESELLER_DETAILS:'Details',
    GET_AUTH:'Auth',
    GET_DASHBOARD:'Dashboard'
}

export const Role = {
    User: 'User',
}

export const Urls = {
    SERVER_PATH: "https://127.0.0.1:4443/",
    HELP: "https://cloudcover365.virtualdcs.co.uk/helpme/",
}