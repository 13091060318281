import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavBar } from './NavBar';
import { NavMenu } from './NavMenu';
import SiteHeader from './ThemeObjects/Pages/SiteHeader';
import Navigations from './ThemeObjects/Pages/Navigations';
import { AppContext } from '../util/AppContext';
import IdleMonitor from './Shared/components/TimeOut/IdleMonitor';
import authService from './Shared/Services/AuthService';
import Retry from './Shared/components/TimeOut/Interval';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Layout extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLogin: window.location.href.toLocaleLowerCase().includes('login') || window.location.href.toLocaleLowerCase().includes('reset')||window.location.href.toLocaleLowerCase().includes('signup'),
      profile:null,
      headerName:'CC365',
      clientStyle:{
        height:`${window.innerHeight-70}px`
      }
    };
    this.timer=null;
    this.PerformAsyncAction=this.PerformAsyncAction.bind(this);
  }
  static displayName = Layout.name;

  PerformAsyncAction = async () => {
    if(this.state.isLogin)
      return '';

    var isUsersPage=window.location.href.includes('users');
    var _data = localStorage.getItem('_retry');
    if (_data) {
      var _respData = await authService().GetAsyncStatus().then(response => {
        return response.data;
      })
        .catch((reason) => {
          console.log("postUserData Org" + reason);
        });
      if (_respData == 'success') {
        toast.success(`Password reset successfull`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false ,theme:'colored'});
        localStorage.removeItem('_retry');        
        return '';
      }
      if(_respData =='error'){
        toast.error(`Password reset failed`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false,theme:'colored' });
        localStorage.removeItem('_retry');       
      }
    } else {
      return '';
    }
  }
  componentDidMount() {
    this.timer = setInterval(async () => await this.PerformAsyncAction(), 5000);
  }
  componentWillUnmount(){
    this.timer=null;
  }
  render() {
    const GetHeaderText=()=>{
    const urlParams = new URLSearchParams(window.location.search);
      let urlContent=window.location.href.split('/');
let pageNAme=urlParams.get('pagename');
     
      var urlFinalEle=urlContent[urlContent.length-1].split('?')[0];
      urlFinalEle=pageNAme?pageNAme:urlFinalEle;
      switch (urlFinalEle.toLocaleLowerCase()) {
        case 'dashboard':
          return 'Status Overview';
          break;
          case 'workload':
          return 'Workload Summary';
          break;
          case 'summary':
          return 'Licence Summary';
          break;
          case 'price':
          return 'Settings';
          break;
          case 'partner':
          return 'Partner configuration';
          break;
          case 'customer':
          return 'Add Customer';
          break;
          case 'billing':
          return 'Billing';
          break;
          case 'cloud':
          return 'CloudCover 365';
          break; 
          case 'company':
          return 'Company Information';
          break; 
          case 'ccl':
          return 'CloudCover Local';
          break; 
          case 'ccr':
          return 'Cloudcover Replication';
          break; 
          case 'ccb':
          return 'CloudCover Backup';
          break;
          case 'cck8s':
          return 'CloudCover K8s';
          break;
          case 'alarms':
          return 'Alarms';
          case 'users':
            return 'Users';
          break;
          case 'reports':
            return 'Reports';
            break;
        default:
          break;
      }
    }
    return (
      <div id="Layout" style={{width:'100%'}}>
        <Retry ProcessRetry={()=>this.PerformAsyncAction()}></Retry>
        <NavMenu />
        {!this.state.isLogin && 
          <AppContext.Provider value={[this.state,this.setState()]}>
          {/* <section className="layout">
            <div className='header'>
              <SiteHeader key={2}></SiteHeader>
            </div>
            <div className="main row col-xs-12">              
              <div id='navigationHeader' className='col-xs-2'><Navigations key={3}></Navigations></div>
              <div style={{paddingLeft:'30px'}}>
                <div id='contentPane' className='col-xs-10 pl-content'>
                  <div className='layout-header-text'>{GetHeaderText()}</div>
                 <div className='layout-body-content'>{this.props.children}</div>
              </div>
              </div>
              </div>
              <div className='footer'></div>
          </section> */}
            <div className='layout-grid'>
              <div id="header-grid"><SiteHeader key={2}></SiteHeader></div>
              <div id="sidebar-grid"><Navigations key={3}></Navigations></div>
              <div id="content-grid" className='contentpanes' style={this.state.clientStyle}>
                <div className='layout-header-text'>{GetHeaderText()}</div>
                 <div className='layout-body-content'>{this.props.children}</div></div>
            </div>
            <IdleMonitor />
          </AppContext.Provider>
        }
        {this.state.isLogin && <div>{this.props.children}</div>}
        <ToastContainer limit={4} style={{ fontSize: "small", top: "80px", right: "5px" }} />
      </div>
    );
  }
  updateDimensions=()=>{
    try {
      this.setState({
        clientStyle:{
          height:`${window.innerHeight-70}px !important`
        }
      });
      document.getElementById('content-grid').style.height=`${window.innerHeight-70}px`;
      //main-ul
     document.getElementById('main-ul').style.height=`${window.innerHeight-370}px !important`;     
      document.getElementById('dashboard-grid').style.height=`${window.innerHeight}px`;
    } catch (error) {
      console.log(error)
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

}
