import Chart from "react-apexcharts";
import { Component } from "react";
export class Charts extends Component {
  constructor(props) {
    super(props);
    this.chartType = props.chart.chartType;
    this.state = {
      series: props.chart.values,
      options: {
        chart: {
          width: props.chart.width,
          height: props.chart.width,
          type: props.chart.chartType,
          dropShadow: {
            enabled: false,
            enabledOnSeries: 1,
            top: 0,
            left: 0,
            blur: 2,
            color: '#000',
            opacity: 0.35
          }
        },
        stroke: {
          show: false
        },
        dataLabels: {
          formatter: function (val, opts) {
            return opts.seriesIndex == opts.w.config.series.length - 1 ? '' : opts.w.config.series[opts.seriesIndex]
          },
        },
        colors: props.chart.colors,
        labels: props.chart.lables,
        legend: {
          show: false
        },
        responsive: [{
          breakpoint: 100,
          options: {
            chart: {
              width: props.chart.width,
              height: 80
            }
          }
        }],
        tooltip: {
          enabled: false
        }
      }
    };
  }



  render() {
    return (


      <div id="card">
        <div id="chart">
          <Chart options={this.state.options} series={this.state.series} type={this.chartType} height={150} width={150} />
        </div>
      </div>
    );
  }
}
