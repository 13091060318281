import React, { Component } from 'react';

const LinkCellsRender = (props) => {
    const { context } = props;
    const getData=(f)=>{
        var data= props.data;
        var remarks=data[props.secondaryfield];
        remarks=remarks??'';
        var resp=data[f]==null?data[props.keyId]:data[f] + remarks
        return resp;
    }
    const redirectPage=()=>{
        props.api.actionMethod(getData(props.fieldName),props.data)
    }
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002",       
    };
    return (
        <div>
            <div className='link-grid' onClick={()=>{redirectPage()}}>View</div>
           
        </div>
    );
}
export default LinkCellsRender;