import React, { Component, createContext, Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import '../../../CSS/general.css';

import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import _ from "lodash";

//Components
import CustomTooltips from "../Features/tooltip.cell";
import LinkCellRender from '../Features/linkcell.render';
import CustomFloatingFilter from '../Features/floatingFilter';
import Modal from '../../Shared/components/Modal/Modal';
//import DetailsTab from '../Features/DetailsTab';
import DetailsElement from "./DetailsElement";
import dashboardservice from '../service/dashboard.service';
import loadable from '@loadable/component'
import { AppContext } from "../../../util/AppContext";
import { useContext } from "react";
import Refresh from "./Refresh";
import Pagination from "../../Shared/components/Pagination/Pagination";
import authService from "../../Shared/Services/AuthService";
import { loginAuthProvider } from "../../../util/AuthProvider";
import { AuthenticationState } from "react-aad-msal";

const DetailsTab = loadable(() => import('../Features/DetailsTab'));
export class DashBoard extends Component {
    static _appContext = AppContext;
    constructor(props) {
        super(props);

        this.appContext = AppContext;
        this._service = dashboardservice();
        this._auth = authService();
        localStorage.removeItem('is365Loaded');
        const urlParams = new URLSearchParams(window.location.search);
        const _loginData=loginAuthProvider.authenticationState;
        if (!_loginData === AuthenticationState.Authenticated)
            window.location.href = '/Login';
            
        let orgIdLocal = localStorage.getItem('OrgId');
        if (orgIdLocal == null)
            localStorage.setItem('OrgId', btoa(urlParams.get("orgId")));
        this.state = {
            isfirst:false,
            isPageChanged:false,
            show: false,
            clientStyle: {
                height: `${window.innerHeight - 185}px`,
                borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", maxHeight: '1300px', borderCollapse: "collapse"
            },
            selectedReseller: '',
            resellerName:'',
            selectedCompanyId: '',
            companyDetails: [],
            resellerDetails: [],
            alarmsDetails: [],
            alldetails: [],
            is365Loaded:false,
            orgId: urlParams.get("orgId") ? urlParams.get("orgId") : "",
            orgName: urlParams.get("orgName") ? urlParams.get("orgName") : "",
            token: "",
            refresh: true,
            columnDefs: [
                { field: 'reseller', headerName: 'Reseller Name' ,floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    fieldName:'serviceName',
                    timeOut:3000
                  }},
                {
                    field: 'companyName', headerName: 'Organisation Name', width: 450, cellRenderer: 'link', cellRendererParams: {
                        fieldName: 'companyName',
                        keyId: 'companyId'
                    },floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                        suppressFilterButton: true,
                        fieldName:'serviceName',
                        timeOut:3000
                      }
                },
                { field: 'companyStatus', headerName: 'Status',floatingFilter:true ,floatingFilterComponent:'floating', floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    fieldName:'serviceName',
                    timeOut:3000
                  } },
                {
                    field: 'lastfinished', headerName: 'Oldest Activity', valueFormatter: this.dateFormatter, floatingFilter: true, floatingFilterComponent: 'floating', floatingFilterComponentParams: {
                        suppressFilterButton: true,
                        fieldName: 'serviceName',
                        timeOut: 3000
                    }
                },
                { field: 'managementStatus', headerName: 'Managed', cellRenderer: this.statusCell,filter:false},
                {
                    field: 'generalStaus', headerName: 'General', floatingFilter: false, cellRenderer: this.statusCell, headerClass: 'header-cell-centered', tooltipField: 'generalCount', tooltipComponentParams: {
                        color: "green", fieldName: 'generalCount', groupName: 'general'
                    }, tooltipComponent: 'customTooltip'
                },
                {
                    field: 'cc365Status', headerName: 'CC 365', floatingFilter: false, cellRenderer: this.statusCell, headerClass: 'header-cell-centered', tooltipField: 'cc365Count',
                    tooltipComponentParams: {
                        color: "green", fieldName: 'cc365Count', groupName: 'cC365'
                    }, tooltipComponent: 'customTooltip'
                },
                {
                    field: 'localStatus', headerName: 'Local', floatingFilter: false, cellRenderer: this.statusCell, headerClass: 'header-cell-centered', tooltipField: 'localCount',
                    tooltipComponentParams: {
                        color: "green", fieldName: 'localCount', groupName: 'local'
                    }, tooltipComponent: 'customTooltip'
                },
                {
                    field: 'offSiteStatus', headerName: 'Off-site', floatingFilter: false, cellRenderer: this.statusCell, headerClass: 'header-cell-centered', tooltipField: 'offSiteCount',
                    tooltipComponentParams: {
                        color: "green", fieldName: 'offSiteCount', groupName: 'offSite'
                    }, tooltipComponent: 'customTooltip'
                },
                {
                    field: 'draasStatus', headerName: 'DraaS', floatingFilter: false, cellRenderer: this.statusCell, headerClass: 'header-cell-centered', tooltipField: 'draasCount',
                    tooltipComponentParams: {
                        color: "green", fieldName: 'draasCount', groupName: 'draas'
                    }, tooltipComponent: 'customTooltip'
                },
                {
                    field: 'k8sStatus', headerName: 'K8s', floatingFilter: false, cellRenderer: this.statusCell, headerClass: 'header-cell-centered', tooltipField: 'k8sCount',
                    tooltipComponentParams: {
                        color: "green", fieldName: 'k8sCount', groupName: 'k8s'
                    }, tooltipComponent: 'customTooltip'
                },
            ],
            defaultColDef: {
                editable: false,
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
                floatingFilter: true
                //tooltipComponent: 'customTooltip',
            },
            frameworkComponents: { 'customTooltip': CustomTooltips, 'link': LinkCellRender,'floating':CustomFloatingFilter },
            tooltipShowDelay: 0,
            tooltipHideDelay: 2000,
            style: {
                width: '99%',
                height: '100%'
            },
            rowData: null,
        };
        this.loadData = this.loadData.bind(this);
    }
    updateDimensions = () => {
        console.log(`hieg${window.innerHeight}`)
        let remainHeight = window.innerHeight - 185;
        console.log(`first${remainHeight}`)
        this.setState({
            clientStyle: {
                height: `${remainHeight}px`,
                borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", maxHeight: '1300px', borderCollapse: "collapse"
            }
        });
        setTimeout(() => {
            this.setState({
                clientStyle: {
                    height: `${remainHeight}px`,
                    borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", maxHeight: '1300px', borderCollapse: "collapse"
                }
            });
        }, 10);
        // document.getElementById('dashboard-grid').style.height = `${remainHeight}px`;      
        // document.getElementsByClassName('ag-theme-alpine')[0].style.height = `${remainHeight}px`;    
      
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            const value = _.get(x, key)
                (rv[value] = rv[value] || []).push(x);
            return rv;
        }, {});
    };

    findHigherValue = (module, p) => {
        var flag = false;

        if (module == 'k8s') {
            flag = true;
        }
        var arr = [];
        arr.push(0)
        arr.push(0)
        arr.push(0)
        arr.push(p.data[`${module}Success`])
        arr.push(p.data[`${module}Warning`])
        if (flag) {
            arr.push(p.data[`${module}SError`])
        } else {
            arr.push(p.data[`${module}Error`])
        }

        const max = Math.max(...arr);
        const index = arr.indexOf(max);
        return index;
    }

    getStatusByPriority = (module, p) => {
        var flag = false;

        if (module == 'k8s') {
            flag = true;
        }

        let sucessVal = p.data[`${module}Success`];
        let warningVal = p.data[`${module}Warning`];
        let errorVal = 0;
        if (flag) {
            errorVal = p.data[`${module}SError`];
        } else {
            errorVal = p.data[`${module}Error`];
        }
        return errorVal > 0 ? 5 : (warningVal > 0 ? 4 : (sucessVal > 0 ? 3 : 0));
    }

    getHigherValue = (p) => {
        let colVal = p.column.colId;
        if (colVal.includes('local')) {
            return this.getStatusByPriority('local', p);
        }
        if (colVal.includes('general')) {
            return this.getStatusByPriority('general', p);
        }
        if (colVal.includes('cc365S')) {
            return this.getStatusByPriority('cC365', p);
        }
        if (colVal.includes('offSite')) {
            return this.getStatusByPriority('offSite', p);
        }
        if (colVal.includes('draas')) {
            return this.getStatusByPriority('draas', p);
        }
        if (colVal.includes('k8s')) {
            // console.log(p.data)
            return this.getStatusByPriority('k8s', p);
        }
        return 1;
    }
    onPaginationChanged=(e)=>{
        this.setState({isPageChanged:!this.state.isPageChanged});
    }
    statusCell = (p) => {
        let val = p.value;
        var colId = p.column.colId;
        var is365Loaded=this.state.is365Loaded;//document.getElementById('check365Load').value;
       if (colId == 'cc365Status' && !is365Loaded ) {
           //console.log(colId)
           return `<div class="alignSpan-center">
           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background: rgb(241 242 243 / 0%); display: block;" width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="translate(50 50)">
 <g ng-attr-transform="scale(0.8)">
   <g transform="translate(-50 -50)">
     <path fill="#1e5d9d" stroke="#1e5d9d" stroke-width="0" d="M50,14c19.85,0,36,16.15,36,36S69.85,86,50,86S14,69.85,14,50S30.15,14,50,14 M50,10c-22.091,0-40,17.909-40,40 s17.909,40,40,40s40-17.909,40-40S72.091,10,50,10L50,10z"></path>
     <path fill="#1e5d9d" d="M52.78,42.506c-0.247-0.092-0.415-0.329-0.428-0.603L52.269,40l-0.931-21.225C51.304,18.06,50.716,17.5,50,17.5 s-1.303,0.56-1.338,1.277L47.731,40l-0.083,1.901c-0.013,0.276-0.181,0.513-0.428,0.604c-0.075,0.028-0.146,0.063-0.22,0.093V44h6 v-1.392C52.925,42.577,52.857,42.535,52.78,42.506z">
       <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="0.4166666666666667s"></animateTransform>
     </path>
     <path fill="#1e5d9d" d="M58.001,48.362c-0.634-3.244-3.251-5.812-6.514-6.391c-3.846-0.681-7.565,1.35-9.034,4.941 c-0.176,0.432-0.564,0.717-1.013,0.744l-15.149,0.97c-0.72,0.043-1.285,0.642-1.285,1.383c0,0.722,0.564,1.321,1.283,1.363 l15.153,0.971c0.447,0.027,0.834,0.312,1.011,0.744c1.261,3.081,4.223,5.073,7.547,5.073c2.447,0,4.744-1.084,6.301-2.975 C57.858,53.296,58.478,50.808,58.001,48.362z M50,53.06c-1.688,0-3.06-1.373-3.06-3.06s1.373-3.06,3.06-3.06s3.06,1.373,3.06,3.06 S51.688,53.06,50,53.06z">
       <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.6666666666666667s"></animateTransform>
     </path>
   </g>
 </g>
</g>
</svg>
           </div>`;
       }
        if (val < 3) {
            val = this.getHigherValue(p);
        }
        val = val < 1 ? 0 : val;
        let result = '<div class="alignSpan-center"><div class="square none "><i class="fa fa-sharp fa-solid fa-circle-ellipis"></i></div></div>'
        switch (val) {
            case 3:
                result = '<div class="alignSpan-center"><div class="success-icon wh-20"><i class="fa fa-sharp fa-solid fa-check-circle"></i></div></div>';
                break;
            case 4:
                result = '<div class="alignSpan-center"><div class="warning-icon wh-20"><i class="fa fa-sharp fa-solid fa-exclamation-circle"></i></div></div>';
                break;
            case 5:
                result = '<div class="alignSpan-center"><div class="error-icon wh-20"><i class="fa fa-sharp fa-solid fa-times-circle"></i></div></div>';
                break;
            case 0:
                result = '<div class="alignSpan-center"><div class="square na"><i class="fa-sharp fa-solid fa-circle-ellipis"></i></div></div>';
                break;
            case 2:
                result = '<div class="alignSpan-center"><div class="square running"><i class="fa-sharp fa-solid fa-circle-info"></i></div></div>';
                break;
            case 1:
                result = '<div class="alignSpan-center"><div class="square none"><i class="fa-sharp fa-solid fa-circle-half-stop"></i></div></div>';
                break;
            default:
                break;
        }
        return result;
    }
    openPopen = (v) => {
        // this.collateItems(v);
        // this.setState({
        //     show: true,
        //     selectedCompanyId: v
        // });

    }
    refeshGrid=async(p)=>{
        document.getElementById('check365Load').value='0';
        this.setState({is365Loaded:false});
        await this.loadData(p);
    }
    loadData = async (p) => {
        this.overlayProgress(true);
       var a=await this.loadReseller();
        let api;
        try {
            api = p.api;
            api.sizeColumnsToFit();
        } catch (error) {
            api = p;
        }
        api.showLoadingOverlay();
        let data = await this._service.getDashboardData(this.state.orgId)
            // Axios.get(`${API_ROUTE}${Routes.GET_DASHBOARD_DATA}?orgId=${this.state.orgId}`)
            .then(response => {
                if ((typeof response == "string" || response instanceof String) && response.includes('ServerPath')) {
                    return null;
                }
                
               // console.log(response.data);
                this.setState({
                    companyDetails: response.data.companyDetails,
                    resellerDetails: response.data.resellerDetails,
                    alarmsDetails: response.data.results
                })
                let selectItems = response.data.dashboardGroupdata;
                return selectItems;
            })
            .catch((reason) => {
                try {
                   // document.getElementById('check365Load').value='1';
                    console.log("catch Org");
                    if (reason.includes('400') || reason.includes('500')) {
                        window.location.href = '/Login';
                    }
                    if (reason.toString().includes('GetServerPath') || reason.toString().includes('GetServerPath') || reason.toString().includes('temporarily')) {
                        this.loadData(p);
                    }
                    if (reason.toString().indexOf('temporarily') > -1 || reason.toString().indexOf('ServerPath')) {
                        this.loadData(p);
                    }
                } catch (error) {
                    this.loadData(p);
                }                
                // window.location.href='/VACLogin';
            });
            this.overlayProgress(false);
        if (data) {          
            //cont headerName=data[0]['reseller'];
            // console.log(this.context)
            // console.log(data[0]['reseller']);
           // this.setState({ selectedReseller: data[0]['reseller'] });            
            //this.appContext.Provider.propTypes.value={profile:null,headerName:data[0]['reseller']}
            this.setState({ rowData: data });
            this.load365Data(p);
            localStorage.setItem('cc365.dashboard',btoa(JSON.stringify(data)))
            api.sizeColumnsToFit();
            api.hideOverlay();
            if (api?.getDisplayedRowCount() == 0)
                api.showNoRowsOverlay();
            else
                api.hideOverlay();
        } else {
            this.setState({is365Loaded:true});
            await this.loadData(p);
        }        
    }
    loadReseller =async()=>{
        //resellerName
        let data = await this._auth.GetProfileInformation()        
        .then(response => {
            if ((typeof response == "string" || response instanceof String) && response.includes('ServerPath')) {
                return null;
            }           
            var dt=JSON.parse(response.data);
            this.setState({ resellerName: dt.ResellerName });
            localStorage.setItem('rsName',btoa(dt.ResellerName))
            
            return dt;
        })
        .catch((reason) => {
            try {
               // document.getElementById('check365Load').value='1';
                console.log("Error occured during fetch reseller");                
            } catch (error) {
                console.log("Error occured during fetch reseller reason");  
            }                
            // window.location.href='/VACLogin';
        });
      
    }
    load365Data = async (p) => {
        let api;
        try {
            api = p.api;
            api.sizeColumnsToFit();
        } catch (error) {
            api = p;
        }
        let data = await this._service.get365DashboardData(this.state.orgId)
            .then(response => {
                document.getElementById('check365Load').value='1';
                if ((typeof response == "string" || response instanceof String) && response.includes('ServerPath')) {
                    return null;
                }
                //console.log(response.data);
                let selectItems = response.data.dashboardGroupdata;
                return selectItems;
            })
            .catch(async(reason) => {
                console.log("Error occured during getch data")
              // this.load365Data(api);
            });
        if (data && data.length > 0) {
            let newRowData = this.state.rowData.map((row, index) => {
                var _filteredData = data.filter(x => {
                    return x.companyId === row.companyId
                })
                if (_filteredData.length > 0) {
                    row.cC365Success=_filteredData[0].cC365Success;
                    row.cC365Warning=_filteredData[0].cC365Warning;
                    row.cC365Error=_filteredData[0].cC365Error;
                    row.cC365NA=_filteredData[0].cC365NA;
                    row.lastfinished=_filteredData[0].lastfinished;
                    return { ...row, cc365Status: _filteredData[0].cc365Status}
                }
                return row;
            });
            this.setState({is365Loaded:true});
            document.getElementById('check365Load').value='1';
            this.setState({rowData:newRowData});
            localStorage.setItem('cc365.dashboard',btoa(JSON.stringify(newRowData)))
            localStorage.setItem('is365Loaded','1');
            api.setRowData(newRowData);
            api.refreshCells({
                force: true,
            });
        }
        this.setState({is365Loaded:true});
        //document.getElementById('check365Load').value='1';
        setTimeout(() => {
            api.refreshCells({
                force: true,
            });
        }, 2000);
       
    }

    collateItems = (cmpId) => {
        var resellerId = '';
        let filtered = this.state.companyDetails.filter(x => {
            return x.instanceUid === cmpId;
        });
        if (filtered && filtered.length > 0) {
            resellerId = filtered[0].resellerUid;
        }
        var allItems = [];
        allItems.push({
            key: 'company',
            tabName: 'Company Information',
            selectedValue: cmpId,
            selectedKey: 'instanceUid',
            details: this.state.companyDetails
        })
        allItems.push({
            key: 'reseller',
            tabName: 'Reseller',
            selectedValue: resellerId,
            selectedKey: 'instanceUid',
            details: this.state.resellerDetails
        })
        allItems.push({
            key: 'users',
            tabName: 'Users',
            selectedValue: resellerId,
            details: this.state.resellerDetails
        })
        allItems.push({
            key: 'alarms',
            tabName: 'Alarms',
            selectedValue: cmpId,
            details: this.state.alarmsDetails
        })
        allItems.push({
            key: 'cloud',
            tabName: 'Cloud Cover 365',
            selectedValue: cmpId,
            details: []
        })
        this.setState({
            alldetails: allItems
        });
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadData(params)

    };
    dateFormatter = (params) => {
       // console.log(params.value);
        let datValue = params.value && params.value != '' ? params.value.replace('-', '/') : '';
        datValue = datValue && datValue != '' ? datValue.replace('-', '/') : '';       
        return datValue;//datValue && datValue !='' ? moment.parse(datValue).format("DD/MM/YYYY").toString() : '';
    };

    overlayProgress = (show) => {
        if (show)
            document.getElementById('main-div-form-elements').style.display = 'block';
        else
            document.getElementById('main-div-form-elements').style.display = 'none';
    }

    render() {
        const rowHeight = 25;
        const headerHeight = 30;
        const getRowStyle = params => {
            return { fontSize: "Small" };
        };
        const modalPopup = import('../../Shared/components/Modal/Modal');

        function onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        }
        const closeModal = (p) => {
            this.setState({ show: p })
        }

        let dashBoardItems = (
            <div><span>{ }</span>
            <div id='main-div-form-elements' className='overlay'><div className="loader"></div></div>
                <div className="row ">
                    <div className="col-xs-12">
                        {/* <button className="btn btn-outline-primary" onClick={() => this.refeshGrid(this.gridApi)}>Refresh</button> */}
                        <Refresh resellerId={this.state.resellerName}></Refresh>
                        <div className="ag-theme-alpine" id="dashboard-grid" style={this.state.clientStyle} >
                            <AgGridReact style={this.state.style}
                                rowData={this.state.rowData}
                                animateRows={true}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                context={{
                                    callBackParent: (v) => {
                                        this.openPopen(v);
                                    }
                                }}
                                getRowStyle={getRowStyle}
                                onGridReady={this.onGridReady}
                                onFirstDataRendered={onFirstDataRendered}
                                tooltipShowDelay={this.state.tooltipShowDelay}
                                tooltipHideDelay={this.state.tooltipHideDelay}
                                frameworkComponents={this.state.frameworkComponents}
                                pagination={false}
                                // suppressPaginationPanel={true}
                                // paginationAutoPageSize={true}
                                // paginationPageSize={5}
                                // onPaginationChanged={this.onPaginationChanged}
                                rowHeight={rowHeight}
                                headerHeight={headerHeight}
                                overlayLoadingTemplate={
                                    '<span className="ag-overlay-loading-center"></span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span className="ag-overlay-loading-center">No error records to show</span>'
                                }
                                suppressScrollOnNewData={true} >
                            </AgGridReact>
                            {/* <Pagination gridApi={this.gridApi} refresh={this.refeshGrid} pageChanged={this.state.isPageChanged}></Pagination> */}
                        </div>
                    </div>

                </div>

            </div>

        )
        return (
            <React.Fragment>
                {dashBoardItems}
                {/* <input type="hidden" name="check365Load" id="check365Load" value="1" /> */}
                <div id="check365Load" style={{display:'none'}}>
1
                </div>
                <script>
                {
                    setTimeout(()=>{
                        try {
                            document.getElementById('check365Load').value='0'    
                        } catch (error) {
                            
                        }
                        
                    },3000)
                
                }
                </script>                
                <Modal show={this.state.show} onClose={() => closeModal(false)}>
                    <div className='content'>
                        <DetailsTab compId={this.state.selectedCompanyId} metaData={this.state.alldetails} detailsData={this.state.companyDetails} />
                    </div>
                </Modal>
            </React.Fragment>

        );
    }
}
