import React, { useEffect, useState } from "react";
import { FormGroup, Spinner, Input } from "reactstrap";
// import "../../CSS/login.css";
import detailsService from "../details/service/details.service";
import authService from "../Shared/Services/AuthService";
import ActionButtons from "./ActionButton";
import Axios from "axios";

const Three = (props) => {
    const [info3, setInfo3] = useState({});
    const [error, setError] = useState("");
    const [error1, setError1] = useState("");
    const [email, setEmail] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [endUserChecked, setEndUserChecked] = useState(false);
    const [dpaChecked, setDPAChecked] = useState(false);
    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );
    const _service = detailsService();

    const handleLastStep = async () => {
        setInProgress(true);
        if (!endUserChecked || !dpaChecked) {
            setInProgress(false);
            setError1("Please acknowledge both EULA and DPA to proceed");
            return;
        }
        else if (!email) {
            setInProgress(false);
            setError("Email address is mandatory");
            return;
        } else if (!validEmail.test(email)) {
            setInProgress(false);
            setError("Email address provided does not meet the required format or criteria");
            return;
        }
        else {

            setError("");
            setError1("");
            var _groupData = await getUserGroupDetails();
            var result = await UpdateUserGroup(_groupData);
            var wait = await sendEmail();            
        }

    };

    const getUserGroupDetails = async () => {
        let _grpData = await _service.getOrgDetails(props.user.profile.companyId)
            .then(response => {
                return response.data;
            })
            .catch((reason) => {
                setInProgress(false);
                console.log("getcloudcoverBackupDetails Org" + reason);
            });
        return _grpData;
    }
    const UpdateUserGroup = async (e) => {
        const config = {
            headers: {}
        };

        var Ip = await Axios.get('https://geolocation-db.com/json/', config)
            .then(response => {
                const publicIp = response.data.IPv4;
                return publicIp;
            })
            .catch(error => {
                console.error('Error retrieving public IP address:', error);
            });

        var _postData = {
            "Profile": {
                "MicrosoftTenantID": "",
                "ManagementGroups": {
                    "MasterAdmin": "",
                    "BackupAdmin": "",
                    "ViewOnly": ""
                }
            },
            "Prices": {
                "PartnerPrice": 0,
                "EndUserPrice": 0
            },
            Agreement: {
                DateSigned: new Date().toLocaleDateString(),
                IPAddress: Ip,
                EmailAddress: email
            }
        };

        if (e.additionalNotes.Profile !== null) {
            _postData.Profile.MicrosoftTenantID = e.additionalNotes.Profile.MicrosoftTenantID;
            _postData.Profile.ManagementGroups.MasterAdmin = e.additionalNotes.Profile.ManagementGroups.MasterAdmin;
            _postData.Profile.ManagementGroups.BackupAdmin = e.additionalNotes.Profile.ManagementGroups.BackupAdmin;
            _postData.Profile.ManagementGroups.ViewOnly = e.additionalNotes.Profile.ManagementGroups.ViewOnly;
        }

        if (e.additionalNotes.Prices !== null) {
            _postData.Prices.PartnerPrice = e.additionalNotes.Prices.PartnerPrice;
            _postData.Prices.EndUserPrice = e.additionalNotes.Prices.EndUserPrice;
        } 


        var _reqData = { value: btoa(JSON.stringify(_postData)), path: '', op: props.user.profile.companyId };
        var _response = await _service.postUserData(_reqData, props.user.profile.companyId)
            .then(response => {               
                return response.data;
            })
            .catch((reason) => {
                setInProgress(false);
                console.log("postUserData Org" + reason);
            });
    }

    const sendEmail = async () => {
        var _response = await _service.sendEmail(email)
            .then(response => {
                setInProgress(false);
                props.lastStep();
                props.completeCallback();
                return response.data;
            })
            .catch((reason) => {
                setInProgress(false);
                console.log("Send Email" + reason);
            });
    }

    const onChecked = (event) => {
        setError1("");
        const targetValue = event.target.name;
        const checked = event.target.checked;
        if (targetValue === "ackEULA") {
            setEndUserChecked(checked);
        }
        else if (targetValue === "ackDPA") {
            setDPAChecked(checked);
        }
    }

    const onEmailChanged = (event) => {
        setError("");
        const targetName = event.target.name;
        const targetValue = event.target.value;
        //console.log(props);
        setEmail(targetValue);
        setInfo3((info3) => ({
            ...info3,
            [targetName]: targetValue
        }));
    };

    useEffect(() => {
        if (props.user.userDetails?.profile?.email !== null && props.user.userDetails?.profile?.email !== "undefined") {
            setEmail(props.user.userDetails?.profile?.email);
            setInfo3((info3) => ({
                ...info3,
                email: props.user.userDetails?.profile?.email
            }));
            return;
        }
        setEmail("");
        setInfo3((info3) => ({
            ...info3,
            email: ""
        }));

    }, [props.user.userDetails]);

    return (
        <div style={{ width: "800px" }}>
            <FormGroup>
                <p align="center">As part of your data protection strategy, you can access your services through our this CloudCover portal. CloudCover services are delivered
                    to you by Virtual Data Centre Service Limited (virtualDCS), directly or on behalf of partners. By using the CloudCover you are entering into
                    an End User Service Agreement between yourselves and virtualDCS. Please ensure you are happy with this agreement before you proceed.

                </p>
                <span style={{ color: "red" }}>{error1}</span>
                <table>
                    <tr>
                        <td>
                            <input
                                type="checkbox"
                                name="ackEULA"
                                onChange={onChecked}
                            />
                        </td>
                        <td>
                            &nbsp; I acknowledge and accept the <a href="https://www.virtualdcs.co.uk/download/subscriber-agreement.pdf" target="_blank" style={{ marginRight: "5px" }} rel="noreferrer">EULA (End User License Agreement)</a>
                        </td>
                    </tr>
                    <tr />
                    <tr>
                        <td>
                            <input
                                type="checkbox"
                                name="ackDPA"
                                onChange={onChecked}
                            />
                        </td>
                        <td>
                            &nbsp; I acknowledge and acccept the virtualDCS <a href="https://www.virtualdcs.co.uk/download/Data-processing-agreement.pdf" target="_blank" style={{ marginRight: "5px" }} rel="noreferrer">Data Processing Agreement</a>
                        </td>
                    </tr>
                </table>
                <br />
                We will send you a copy of these documents for your records.
                <div>
                    <span style={{ color: "red" }}>{error}</span>
                </div>
                <table width="500px">
                    <tr>
                        <td>
                            <label>Email Address </label>
                        </td>
                        <td>
                            <Input
                                style={{ width: "100%" }}
                                type="text"
                                name="email"
                                value={email}
                                placeholder="Enter email address"
                                onChange={onEmailChanged}
                            />

                        </td>
                    </tr>
                </table>
                <br />
            </FormGroup>
            <div>
                <table cellSpacing="5" cellPadding="5" align="right">
                    <tbody>
                        <tr>
                            <td >
                                {
                                    inProgress &&
                                    <Spinner color="primary" style={{ position: "absolute", left: "45%" }} />

                                }
                                <ActionButtons {...props} lastStep={handleLastStep} disabled={inProgress} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/*<ActionButtons {...props} lastStep={handleLastStep} />*/}
        </div>
    );
};

export default Three;